import React from 'react';
import logo from '../../assets/images/c4e.png';
import { Typography, Paper } from '@mui/material';

const HardWare = () => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
            Default Template
            </Typography>
            <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                <div style={styles.container}>
                    <header style={styles.header}>
                        <img src={logo} alt="Company Logo" style={styles.logo} />
                        <div style={styles.companyDetails}>
                            <h3>Care4Edu Solutions Pvt Ltd</h3>
                            <p>+91 7259785184</p>
                            <p>info@care4edu.com</p>
                            <p>www.care4edu.com</p>
                        </div>
                    </header>

                    <section style={styles.section}>
                        <h2 style={styles.sectionTitle}>
                            Acknowledgement of Receipt of Company Property and Financial Obligation Form
                        </h2>
                        <p style={styles.sectionName}><strong>Employee Name: Adhithyan P A</strong></p>
                        <p>
                            I hereby acknowledge receipt and assignment of the following company property. I agree to
                            maintain the equipment in good condition and promise to report any loss or damage
                            immediately. I agree to assume all financial responsibility for damages other than
                            malfunctions beyond my control. I further agree to use said property only for work-related
                            purposes during work hours and ensure equipment is kept in a locked cabinet when not in
                            use.
                        </p>

                        <ul style={styles.equipmentList}>
                            <li>Office/Building Key (No _________________________)</li>
                            <li>Security Access Code (No _________________________)</li>
                            <li>Cell Phone (Serial # ___________________ Phone # ____________________)</li>
                            <li>Desktop (Serial # _____________________ Phone # ____________________)</li>
                            <li>Desktop (Serial # ________________________________)</li>
                            <li>Other: _________________________________________</li>
                            <li>Other: _________________________________________</li>
                            <li>Other: _________________________________________</li>
                        </ul>

                        <p>
                            Return of Property: In the event of my termination from employment, I will return all
                            company property (specified above or on attached sheet), upon my last day of work <strong>20/08/2024</strong>.
                            If any property is not returned, I authorize a reasonable value for such items to be
                            deducted from my final paycheck.
                        </p>

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <div style={styles.signatureSection}>
                                            <p>(Adhithyan P A)</p>
                                            <p>Signature</p>
                                            <p>Issuing Manager Signature_____________</p>
                                        </div>
                                        <div style={styles.signatureSection1}>
                                            <p>Date: 20/08/2024</p>
                                        </div>
                                    </div>

                    </section>
                </div>
            </Paper >

        </>
    );
};

const styles = {
    container: {
        fontFamily: "'Poppins', sans-serif",
        maxWidth: '700px',
        margin: 'auto',
        padding: '20px',
        border: '1px solid #000',
        backgroundColor: '#fff',
    },
    header: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },

    companyDetails: {
        textAlign: 'left',
        marginTop: '10px',
        fontSize: '14px',

    },
    sectionTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    sectionName: {
        marginTop: '20px',
        fontSize: '14px',
        marginBottom: '20px',

    },
    equipmentList: {
        marginTop: '20px',
        listStyleType: 'none',
        paddingLeft: '0',
        marginBottom: '20px',
        fontSize: '14px',
        maxWidth: '100%',  
        wordWrap: 'break-word',  
        lineHeight: '1.5',  
    },
    
    signatureSection: {
        marginTop: '40px',
        fontSize: '14px',
    },
    signatureSection1: {
        marginTop: '80px',
        fontSize: '14px',
    }
};

export default HardWare;
