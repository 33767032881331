import React, { useEffect, useState,useRef,useCallback } from "react";
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
  Card,
  CardContent
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import Swal from "sweetalert2";
import { org } from "authentication/APIData";
import { APIData } from "authentication/APIData";
import IdCard1 from 'layouts/idCards/idCard1';
import IdCard2 from 'layouts/idCards/idCard2';
import IdCard3 from 'layouts/idCards/idCard3';
import IdCard4 from 'layouts/idCards/idCard4';
import IdCard5 from 'layouts/idCards/idCard5';
import SoftButton from "components/SoftButton";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Table as MuiTable,
  CardHeader
} from '@mui/material';

// Utility function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

// Detailed Information Display Component
const InformationDisplay = ({ data, onEdit }) => {
  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '16px',
      padding: '16px'
    }}>
      {Object.entries(data).map(([key, value]) => (
        <div key={key} style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
          borderBottom: '1px solid #e0e0e0',
          width: "100%"
        }}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontWeight: 600,
              marginRight: '16px',
              minWidth: '120px'
            }}
          >
            {key}
          </Typography>
          <Typography
            variant="body2"
            style={{
              flex: 1,
              textAlign: 'right'
            }}
          >
            {value || 'N/A'}
          </Typography>
        </div>
      ))}
      {onEdit && (
        <div style={{
          gridColumn: '1 / -1',
          textAlign: 'center',
          marginTop: '16px'
        }}>
          <SoftButton
            variant="gradient"
            color="info"
            startIcon={<EditIcon />}
            onClick={onEdit}
          >
            Edit Profile
          </SoftButton>
        </div>
      )}
    </div>
  );
};

InformationDisplay.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func
};

// Compact Card Component
const CompactInfoCard = ({ title, icon, onExpand }) => {
  return (
    <Card
      style={{
        height: '160px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)'
        }
      }}
      onClick={onExpand}
    >
      <CardContent style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}>
        {icon}
        <Typography
          variant="h6"
          style={{
            marginTop: '12px',
            color: '#344767',
            fontWeight: 600
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: '#7b809a',
            marginTop: '8px'
          }}
        >
          Click to view details
        </Typography>
      </CardContent>
    </Card>
  );
};

CompactInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onExpand: PropTypes.func.isRequired
};

// Detailed Information Dialog
const DetailDialog = ({ open, onClose, title, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '12px'
        }
      }}
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

DetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};


const EmployeeIdCard = ({ orgDetails, profileInfo }) => {
  const badgeRef = useRef(); // Reference for the ID card

  // Function to render the appropriate ID card template
  const renderIdCard = () => {
    const props = {
      name: profileInfo.name || 'Employee Name',
      dob: profileInfo.dob,
      id: profileInfo.employee_id,
      photo: profileInfo.photoUrl,
      role: profileInfo.access_profiles,
      orgName: orgDetails.orgName,
      orgAddress: orgDetails.orgAddress,
      logoUrl: orgDetails.logoUrl
    };

    switch (orgDetails.idCardTemplate) {
      case "1": return <IdCard1 {...props} />;
      case "2": return <IdCard2 {...props} />;
      case "3": return <IdCard3 {...props} />;
      case "4": return <IdCard4 {...props} />;
      case "5": return <IdCard5 {...props} />;
      default: return <IdCard1 {...props} />;
    }
  };

  // Function to handle download of the ID card
  const downloadBadge = useCallback(async () => {
    if (badgeRef.current) {
      try {
        // Generate canvas from the ID card content
        const canvas = await html2canvas(badgeRef.current, { scale: 2, useCORS: true });
        const dataUrl = canvas.toDataURL('image/png');
        
        // Create a download link and trigger it
        const link = document.createElement('a');
        link.download = `badge_${profileInfo.employee_id}.png`;
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error('Error generating badge:', error);
      }
    }
  }, [profileInfo.employee_id]);

  return (
    <SoftBox mb={4} display="flex" flexDirection="column" alignItems="center">
  <div
    ref={badgeRef}
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 0 16px 0',
    }}
  >
    {renderIdCard()}
  </div>
  
  {/* Move the button below the ID card */}
  <SoftButton
    variant="gradient"
    color="secondary"
    onClick={downloadBadge}
    style={{ width: '200px', marginTop: '20px' }} // Added marginTop for spacing
  >
    Download ID Card
  </SoftButton>
</SoftBox>

  );
};

EmployeeIdCard.propTypes = {
  orgDetails: PropTypes.object.isRequired,
  profileInfo: PropTypes.object.isRequired
};

const PayslipContent = ({ profileInfo }) => {
  const [payslip, setPayslip] = useState(null);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [empdata, setEmpdata] = useState([]);

  const monthOptions = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" }
  ];

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 5 }, (_, i) => ({
    value: currentYear - i,
    label: currentYear - i
  }));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${APIData.api}employee/details?org=${org}`;
        const response = await fetch(url, { headers: APIData.headers });
        const data = await response.json();
        setEmpdata(data.filter(emp => emp.payslip !== null));
      } catch (err) {
        console.error(err);
      }
    };
    const fetchIcon = async () => {
      try {
        const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers });
        if (response.status === 200) {
          const { orgName, orgAddress } = response.data;
          setOrgName(orgName);
          setOrgAddress(orgAddress);
          console.log(orgName, orgAddress);
          
        } else {
          console.error("No data found.");
        }
      } catch (error) {
        console.error("Error fetching icon:", error);
      }
    };
    fetchData();
    fetchIcon();
  }, []);

  const fetchPayslip = async () => {
    if (!month || !year) {
      Swal.fire({
        icon: 'warning',
        title: 'Selection Required',
        text: 'Please select both month and year first',
      });
      return;
    }

    setLoading(true);
    try {
      const employeeId = profileInfo.employee_id;
      const url = `${APIData.api}payslips/employee/${employeeId}?month=${month}&year=${year}`;
      const response = await fetch(url, {
        headers: {
          ...APIData.headers,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const payslipData = Array.isArray(data) ? data[0] : data;
      setPayslip(payslipData);
    } catch (error) {
      console.error('Error fetching payslip:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch payslip data',
      });
    }
    setLoading(false);
  };

  const handleDownloadPDF = () => {
    if (!payslip) return;

    const doc = new jsPDF();
    const employee = empdata.find(emp => emp.employee_id === payslip.employeeId);

    doc.setFontSize(16);
    doc.text(orgName, 105, 20, { align: 'center' });
    doc.setFontSize(12);
    doc.text(orgAddress, 105, 30, { align: 'center' });
    doc.text(`Pay Slip for ${month} ${year}`, 105, 40, { align: 'center' });

    const basicInfoData = [
      ['Name of the Employee', payslip.employeeName, 'UAN', payslip.uan || 'Not Applicable'],
      ['Employee ID', payslip.employeeId, 'ESI No', payslip.esi || 'Not Applicable'],
      ['Designation', employee.designation, 'Bank Name', payslip.bankAccount],
      ['Department', employee.dept, 'Bank A/C No', payslip.bankAccount],
      ['DOJ', employee.year_of_appointment, 'Total Working Days', payslip.totalWorkindDays],
      ['Paid Days', payslip.daysPresent, 'LOP days', payslip.daysAbsent]
    ];

    const earningsData = [
      ['Basic Wage', payslip.totalSalary, 'EPF', payslip.pfAmount || 'Not Applicable'],
      ['HRA', payslip.hra || 'Not Applicable', 'Professional Tax', payslip.professionalTax || 'Not Applicable'],
      ['Conveyance Allowances', payslip.conveyanceAllowance || 'Not Applicable', 'TDS', payslip.tds || 'Not Applicable'],
      ['Medical Allowances', payslip.medicalAllowance || 'Not Applicable', 'ESI/Health Insurance', payslip.esi || 'Not Applicable'],
      ['Other Allowances', payslip.otherAllowance || 'Not Applicable', 'LOP', payslip.lop],
      ['Total Earnings', payslip.totalSalary, 'Total Deductions', payslip.totalDeductions || '0']
    ];

    doc.autoTable({
      startY: 50,
      body: basicInfoData,
      theme: 'grid'
    });

    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 10,
      body: earningsData,
      theme: 'grid'
    });

    // Add Net Salary without header
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 10,
      body: [['Net Salary', payslip.payableAmount]],
      theme: 'grid'
    });

    // Add footer note
    doc.setFontSize(10);
    doc.text('**This is a computer-generated Pay slip and does not need any signature.',
      105, doc.previousAutoTable.finalY + 20, { align: 'center' });

    doc.save(`Payslip_${payslip.employeeName}_${month}_${year}.pdf`);
  };

  return (
    <div>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomSelect
              options={monthOptions}
              placeholder="Select Month"
              value={month}
              onChange={(selectedOption) => setMonth(selectedOption.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSelect
              options={yearOptions}
              placeholder="Select Year"
              value={year}
              onChange={(selectedOption) => setYear(selectedOption.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={fetchPayslip}
              fullWidth
            >
              View Payslip
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>

      {loading ? (
        <CircularProgress />
      ) : payslip ? (
        <Card>
          <CardHeader
            title={orgName}
            titleTypographyProps={{ variant: 'h4', align: 'center' }}
          />
          <CardHeader
            title={orgAddress}
            titleTypographyProps={{ variant: 'h5', align: 'center' }}
          />
          <CardHeader
            title={`Pay Slip for ${month} ${year}`}
            titleTypographyProps={{ variant: 'h5', align: 'center' }}
          />
          <CardContent>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <MuiTable>
                <TableRow>
                  <TableCell>Name of the Employee</TableCell>
                  <TableCell>{payslip.employeeName}</TableCell>
                  <TableCell>UAN</TableCell>
                  <TableCell>{payslip.uan || "Not Applicable"}</TableCell>
                </TableRow>
                <TableBody>
                  <TableRow>
                    <TableCell>Employee ID</TableCell>
                    <TableCell>{payslip.employeeId}</TableCell>
                    <TableCell>ESI No</TableCell>
                    <TableCell>{payslip.esi || "Not Applicable"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Designation</TableCell>
                    <TableCell>{empdata.find(emp => emp.employee_id === payslip.employeeId).designation}</TableCell>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>{payslip.bankAccount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Department</TableCell>
                    <TableCell>{empdata.find(emp => emp.employee_id === payslip.employeeId).dept}</TableCell>
                    <TableCell>Bank A/C No</TableCell>
                    <TableCell>{payslip.bankAccount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} />
                  </TableRow>
                  <TableRow>
                    <TableCell>DOJ</TableCell>
                    <TableCell>{empdata.find(emp => emp.employee_id === payslip.employeeId).year_of_appointment}</TableCell>
                    <TableCell>Total Working Days</TableCell>
                    <TableCell>{payslip.totalWorkindDays}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Paid Days</TableCell>
                    <TableCell>{payslip.daysPresent}</TableCell>
                    <TableCell>LOP days</TableCell>
                    <TableCell>{payslip.daysAbsent}</TableCell>
                  </TableRow>
                </TableBody>
                <TableRow>
                  <TableCell colSpan={4} />
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <b>Earnings</b>
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    <b>Deductions</b>
                  </TableCell>
                </TableRow>

                <TableBody>
                  <TableRow>
                    <TableCell>Basic Wage</TableCell>
                    <TableCell>{payslip.totalSalary}</TableCell>
                    <TableCell>EPF</TableCell>
                    <TableCell>{payslip.pfAmount || "Not Applicable"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>HRA</TableCell>
                    <TableCell>{payslip.hra || "Not Applicable"}</TableCell>
                    <TableCell>Professional Tax</TableCell>
                    <TableCell>{payslip.professionalTax || "Not Applicable"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Conveyance Allowances</TableCell>
                    <TableCell>{payslip.conveyanceAllowance || "Not Applicable"}</TableCell>
                    <TableCell>TDS</TableCell>
                    <TableCell>{payslip.tds || "Not Applicable"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Medical Allowances</TableCell>
                    <TableCell>{payslip.medicalAllowance || "Not Applicable"}</TableCell>
                    <TableCell>ESI/Health Insurance</TableCell>
                    <TableCell>{payslip.esi || "Not Applicable"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Other Allowances</TableCell>
                    <TableCell>{payslip.otherAllowance || "Not Applicable"}</TableCell>
                    <TableCell>LOP</TableCell>
                    <TableCell>{payslip.lop}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Earnings</TableCell>
                    <TableCell>{payslip.totalSalary}</TableCell>
                    <TableCell>Total Deductions</TableCell>
                    <TableCell>{payslip.totalDeductions || "0"}</TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} align="center">Net Salary</TableCell>
                    <TableCell>{payslip.payableAmount}</TableCell>
                  </TableRow>
                </TableBody>
              </MuiTable>
            </TableContainer>
          </CardContent>
          <SoftBox mt={2} display="flex" justifyContent="center" gap={2}>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={handleDownloadPDF}
              disabled={!payslip}
            >
              Download PDF
            </SoftButton>
          </SoftBox>
        </Card>
      ) : (
        <Typography variant="body2" align="center">
          Select month and year to view payslip
        </Typography>
      )}
    </div>
  );
};

PayslipContent.propTypes = {
  profileInfo: PropTypes.object.isRequired
};

function Overview() {
  const navigate = useNavigate();
  const [profileInfo, setProfileData] = useState({});
  const [userdata, setUserData] = useState();
  const [orgDetails, setOrgDetails] = useState({
    orgName: '',
    orgAddress: '',
    logoUrl: '',
    idCardTemplate: '1'
  });
  const [attendanceData, setAttendanceData] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [workType, setWorkType] = useState('');

  // State for managing dialogs
  const [openDialogs, setOpenDialogs] = useState({
    personalInfo: false,
    departmentInfo: false,
    idCard: false,
    qualificationInfo: false,
    payslipInfo: false,
    attedenceInfo: false
  });

  useEffect(() => {
    const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
    setUserData(userData_Local);
  }, []);

  useEffect(() => {
    if (userdata && profileInfo.officeEmail) {
      const fetchEmployeeDetails = async () => {
        try {
          const response = await fetch(`${APIData.api}employee/detail?emailId=${encodeURIComponent(profileInfo.officeEmail)}`, {
            headers: APIData.headers
          });

          if (!response.ok) {
            throw new Error('Failed to fetch employee details');
          }

          const data = await response.json();

          // Explicitly set work type
          if (data.workType) {
            setWorkType(data.workType);
          }

          setEmployeeDetails(data);
        } catch (error) {
          console.error("Error fetching employee details:", error);
        }
      };

      fetchEmployeeDetails();
    }
  }, [userdata, profileInfo.officeEmail]);

  useEffect(() => {
    if (userdata) {
      const fetchProfileData = async () => {
        try {
          let url = `${APIData.api}employee/${userdata.userName}`;
          const response = await fetch(url, { headers: APIData.headers });
          const data = await response.json();
          setProfileData(data);
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: `Failed to fetch "${org}" details`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      };

      const fetchOrgData = async () => {
        try {
          const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
            headers: APIData.headers
          });
          const orgData = await response.json();
          setOrgDetails({
            orgName: orgData.orgName || '',
            orgAddress: orgData.orgAddress || '',
            logoUrl: orgData.orgLogo || '',
            idCardTemplate: orgData.idCardTemplate || '1'
          });
        } catch (error) {
          console.error("Error fetching organization data:", error);
        }
      };

      const getCurrentMonth = () => {
        const date = new Date();
        return date.toLocaleString('default', { month: 'long' }).toUpperCase();
      };

      // Function to fetch attendance data
      const fetchAttendanceData = async () => {
        const currentMonth = getCurrentMonth(); // Get the dynamic month
        try {
          const response = await fetch(
            `${APIData.api}attendance/org/month/emp?org=${org}&month=${currentMonth}&empId=${userdata.userName}`,
            {
              headers: APIData.headers,
            }
          );
          const data = await response.json();
          setAttendanceData(data);
        } catch (error) {
          console.error("Error fetching attendance data:", error);
        }
      };

      fetchProfileData();
      fetchOrgData();
      fetchAttendanceData();
    }
  }, [userdata]);

  const handlePunchIn = async () => {
    try {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

      const response = await fetch(`${APIData.tpapi}notifications/login-out?to=${profileInfo.phone_number}&textBody= punched attendance IN on ${formattedDate} at Avinya Acadmy/Care 4 Edu Solutions Pvt ltd &name=${profileInfo.name}`, {
        method: 'GET',
        headers: APIData.tpheaders
      });

      if (response.ok) {
        Swal.fire({
          title: "Success!",
          text: "Punched IN successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        throw new Error('Punch IN failed');
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to punch IN",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePunchOut = async () => {
    try {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

      const response = await fetch(`${APIData.tpapi}notifications/login-out?to=${profileInfo.phone_number}&textBody= punched attendance OUT on ${formattedDate} at Avinya Acadmy/Care 4 Edu Solutions Pvt ltd &name=${profileInfo.name}`, {
        method: 'GET',
        headers: APIData.tpheaders
      });

      if (response.ok) {
        Swal.fire({
          title: "Success!",
          text: "Punched OUT successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        throw new Error('Punch OUT failed');
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to punch OUT",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // Function to toggle dialogs
  const toggleDialog = (dialogName) => {
    setOpenDialogs(prev => ({
      ...prev,
      [dialogName]: !prev[dialogName]
    }));
  };

  const displayData = attendanceData && attendanceData.length > 0 ? attendanceData[0] : {};

  // Handle Edit Profile Navigation
  const handleEditProfile = () => {
    navigate(`/edit-profile/${profileInfo.employee_id}`);
  };

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          {/* Personal Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Personal Information"
              icon={<AccountCircleIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('personalInfo')}
            />
            <DetailDialog
              open={openDialogs.personalInfo}
              onClose={() => toggleDialog('personalInfo')}
              title="Personal Information"
            >
              <InformationDisplay
                data={{
                  'Full Name': `${profileInfo.name ? profileInfo.name.charAt(0).toUpperCase() + profileInfo.name.slice(1).toLowerCase() : "Name"}`,
                  'Mobile': profileInfo.phone_number,
                  'Email': profileInfo.officeEmail || profileInfo.email,
                  'Location': profileInfo.address,
                  'Date of Birth': formatDate(profileInfo.dob),
                  'Joined Date': formatDate(profileInfo.year_of_appointment),
                  'Gender': profileInfo.gender,
                }}
                onEdit={handleEditProfile}
              />
            </DetailDialog>
          </Grid>

          {/* Department Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Department Information"
              icon={<WorkIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('departmentInfo')}
            />
            <DetailDialog
              open={openDialogs.departmentInfo}
              onClose={() => toggleDialog('departmentInfo')}
              title="Department Information"
            >
              <InformationDisplay
                data={{
                  'Department': profileInfo.dept ? profileInfo.dept[0] : 'N/A',
                  'Designation': profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].designation : 'N/A',
                  'Role': profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].role : 'N/A',
                  'Supervisor': profileInfo.supervisor ? profileInfo.supervisor[0] : 'N/A',
                  'Branch': profileInfo.branch || 'N/A'
                }}
              />
            </DetailDialog>
          </Grid>

          {/* ID Card Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="ID Card"
              icon={<CreditCardIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('idCard')}
            />
            <DetailDialog
              open={openDialogs.idCard}
              onClose={() => toggleDialog('idCard')}
              title="Employee ID Card"
            >
              <EmployeeIdCard
                orgDetails={orgDetails}
                profileInfo={profileInfo}
              />
            </DetailDialog>
          </Grid>

          {/* Qualification Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Qualification Information"
              icon={<SchoolIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('qualificationInfo')}
            />
            <DetailDialog
              open={openDialogs.qualificationInfo}
              onClose={() => toggleDialog('qualificationInfo')}
              title="Qualification Information"
            >
              <InformationDisplay
                data={{
                  'Highest Qualification': profileInfo.qualification || 'N/A',
                  'Educational Institution': profileInfo.educationalInstitution || 'N/A',
                  'Graduation Year': profileInfo.graduationYear || 'N/A',
                  'Additional Certifications': profileInfo.certifications || 'N/A'
                }}
              />
            </DetailDialog>
          </Grid>

          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Payslip Information"
              icon={<ReceiptLongIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('payslipInfo')}
            />
            <DetailDialog
              open={openDialogs.payslipInfo}
              onClose={() => toggleDialog('payslipInfo')}
              title="Payslip Information"
            >
              <PayslipContent profileInfo={profileInfo} />
            </DetailDialog>
          </Grid>
          {/* Document Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Attendance Information"
              icon={<SchoolIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('attedenceInfo')}
            />
            <DetailDialog
              open={openDialogs.attedenceInfo}
              onClose={() => toggleDialog('attedenceInfo')}
              title="Attendance Information"
            >
              <InformationDisplay
                data={{
                  'Days Present': displayData.daysPresent || 'N/A',
                  'Days Absent': displayData.daysAbsent || 'N/A',
                  'Login Time': displayData.login || 'N/A',
                  'Logout Time': displayData.logout || 'N/A',
                  'Month': displayData.month || 'N/A',
                }}
              />
              {workType && workType.toUpperCase() === 'WORK_FROM_HOME' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    marginTop: '16px',
                  }}
                >
                  <SoftButton
                    variant="gradient"
                    color="success"
                    onClick={handlePunchIn}
                    disabled={!!displayData.login} // Disable if login time exists
                  >
                    Punch IN
                  </SoftButton>
                  <SoftButton
                    variant="contained"
                    color="secondary"
                    onClick={handlePunchOut}
                    disabled={!!displayData.logout} // Disable if logout time exists
                  >
                    Punch OUT
                  </SoftButton>
                </div>
              )}

            </DetailDialog>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;