// import {Button,Container, Group,Text,TextInput, Select, Textarea,} from "@mantine/core";
// import { useForm } from "@mantine/form";
// import toast from "react-hot-toast";
// import { postMcqRequest } from "../../Query/McqQuery";
// import { org } from "authentication/APIData";
// import Swal from "sweetalert2";

// export default function QuestionAdd() {
//   const form = useForm({
//     initialValues: {
//       subject: "",
//       question: "",
//       answer: "",
//       mcq1: "",
//       mcq2: "",
//       mcq3: "",
//       mcq4: "",
//       options: "",
//       grade: "",
//       board: "",
//       org: org,
//     },
//   });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (
//       ![
//         form.values.mcq1,
//         form.values.mcq2,
//         form.values.mcq3,
//         form.values.mcq4,
//       ].includes(form.values.answer)
//     ) {
//       Swal.fire({
//         icon: 'warning',
//         title: 'Invalid Answer',
//         text: 'Answer must be one of the provided MCQ options.',
//         confirmButtonText: 'OK'
//     });
//       return;
//     }
//     const formData = {
//       id: 0,
//       subject: form.values.subject,
//       question: form.values.question,
//       answer: form.values.answer,
//       board: form.values.board,
//       options: [
//         form.values.mcq1,
//         form.values.mcq2,
//         form.values.mcq3,
//         form.values.mcq4,
//       ],
//       grade: form.values.grade,
//       org: org,
//     };
//     // console.log(formData);
//     const response = await postMcqRequest(formData)
//       .then((resp) => {
//         Swal.fire({
//           icon: "success",
//           title: "Added Successfully",
//           showConfirmButton: false,
//           timer: 1500
//       });
//         form.reset();
//         form.resetTouched()
//       })
//       .catch((err) => {
//         toast.error("");
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: 'Unable to add the question',
//       });
//       });
//   };

//   var boards = ['State', 'CBSE', 'ICSE'];


//   return (
//     <div style={{ position: "relative" }}>
//       <form
//         onSubmit={handleSubmit}
//         style={{ maxWidth: "600px", margin: "auto" }}
//       >
//         <Container
//           style={{
//             // backgroundColor: " #C4DFFF",
//             boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
//             padding: "20px",
//             borderRadius: "5px",
//           }}
//         >
//           <Text size="1.5rem" fw={"600"} style={{ textAlign: "center" }}>
//             Add question
//           </Text>
//           <div>
//             <Textarea
//               label="Question"
//               placeholder="Question"
//               maxRows={6}
//               minRows={2}
//               required
//               style={{ width: "100%" }}
//               autosize
//               name="question"
//               // variant="filled"
//               {...form.getInputProps("question")}
//             />
//             <TextInput
//               label="Answer"
//               placeholder="Answer "
//               name="answer"
//               required
//               style={{ width: "100%" }}
//               // variant="filled"

//               {...form.getInputProps("answer")}
//             />
//             <TextInput
//               label="Mcq1"
//               placeholder="Option1"
//               name="mcq1"
//               required
//               style={{ width: "100%" }}
//               // variant="filled"
//               {...form.getInputProps("mcq1")}
//             />
//             <TextInput
//               label="Mcq2"
//               placeholder="Option2"
//               name="mcq2"
//               required
//               style={{ width: "100%" }}
//               // variant="filled"
//               {...form.getInputProps("mcq2")}
//             />
//             <TextInput
//               label="Mcq3"
//               placeholder="Option3"
//               name="mcq3"
//               required
//               style={{ width: "100%" }}
//               // variant="filled"
//               {...form.getInputProps("mcq3")}
//             />
//             <TextInput
//               label="Mcq4"
//               placeholder="Option4"
//               name="mcq4"
//               required
//               style={{ width: "100%" }}
//               // variant="filled"
//               {...form.getInputProps("mcq4")}
//             />
//             <TextInput
//               label="Subject"
//               placeholder="Subject"
//               name="subject"
//               required
//               style={{ width: "100%" }}
//               // variant="filled"
//               {...form.getInputProps("subject")}
//             />
//             <TextInput
//               label="Grade"
//               placeholder="Grade 9th or 11th"
//               name="grade"
//               required
//               style={{ width: "100%" }}
//               // variant="filled"
//               {...form.getInputProps("grade")}
//             />
//             <Select
//               required
//               mt="sm"
//               label="Select Board"
//               placeholder="Select Board"
//               data={boards}
//               {...form.getInputProps("board")}
//             />
//             <br />
//           </div>
//           <Group justify="center" mt="">
//             {/* <Button type="submit" size="md">
//               Add Question
//             </Button> */}
//             <Button
//               type="submit"
//               mt="sm"
//               w={{ base: "100%" }}
//               variant="gradient"
//             >
//               Add Question
//             </Button>
//           </Group>
//         </Container>
//       </form>
//     </div>
//   );
// }

import {
  Button,
  Container,
  Group,
  Text,
  TextInput,
  Select,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import toast from "react-hot-toast";
import { postMcqRequest } from "../../Query/McqQuery"; // Assuming this is your API request function
import { org } from "authentication/APIData";
import Swal from "sweetalert2";

export default function QuestionAdd() {
  const form = useForm({
    initialValues: {
      subject: "",
      question: "",
      answer: "",
      mcq1: "",
      mcq2: "",
      mcq3: "",
      mcq4: "",
      grade: "",
      board: "",
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that the answer matches one of the MCQ options
    if (
      ![
        form.values.mcq1.trim(),
        form.values.mcq2.trim(),
        form.values.mcq3.trim(),
        form.values.mcq4.trim(),
      ].includes(form.values.answer.trim())
    ) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Answer",
        text: "Answer must match one of the provided MCQ options.",
        confirmButtonText: "OK",
      });
      return;
    }

    // Prepare request payload
    const formData = {
      answer: form.values.answer.trim(),
      board: form.values.board.trim(),
      grade: parseInt(form.values.grade.trim(), 10), // Ensure grade is an integer
      id: 0, // Set to 0 for new entries
      options: [
        form.values.mcq1.trim(),
        form.values.mcq2.trim(),
        form.values.mcq3.trim(),
        form.values.mcq4.trim(),
      ],
      org: org, // Organization ID (from your `authentication/APIData`)
      question: form.values.question.trim(),
      subject: form.values.subject.trim(),
    };

    

    try {
      const response = await postMcqRequest(formData);

      // Log the response for debugging

      // Check if response contains an id field (indicating success)
      if (response && response.id) {
       

        Swal.fire({
          icon: "success",
          title: "Question Added Successfully!",
          text: "The question has been added.",
          confirmButtonText: "OK",
        }).then(() => {
          form.reset(); // Reset the form after popup is confirmed
        });
      } else {
        
        Swal.fire({
          icon: "error",
          title: "Unexpected Response",
          text: "The response does not contain the expected fields.",
        });
      }
    } catch (error) {
     
      Swal.fire({
        icon: "error",
        title: "Error Adding Question",
        text: error.response?.data?.message || error.message || "An error occurred. Please try again.",
      });
      toast.error(error.response?.data?.message || error.message || "Something went wrong.");
    }
  };

  const boards = ["State", "CBSE", "ICSE"];

  return (
    <div style={{ position: "relative" }}>
      <form onSubmit={handleSubmit} style={{ maxWidth: "600px", margin: "auto" }}>
        <Container
          style={{
            boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Text size="1.5rem" fw="600" style={{ textAlign: "center" }}>
            Add Question
          </Text>
          <div>
            <Textarea
              label="Question"
              placeholder="Enter the question"
              maxRows={6}
              minRows={2}
              required
              style={{ width: "100%" }}
              autosize
              {...form.getInputProps("question")}
            />
            <TextInput
              label="Answer"
              placeholder="Enter the correct answer"
              required
              style={{ width: "100%" }}
              {...form.getInputProps("answer")}
            />
            <TextInput
              label="Option 1"
              placeholder="Enter Option 1"
              required
              style={{ width: "100%" }}
              {...form.getInputProps("mcq1")}
            />
            <TextInput
              label="Option 2"
              placeholder="Enter Option 2"
              required
              style={{ width: "100%" }}
              {...form.getInputProps("mcq2")}
            />
            <TextInput
              label="Option 3"
              placeholder="Enter Option 3"
              required
              style={{ width: "100%" }}
              {...form.getInputProps("mcq3")}
            />
            <TextInput
              label="Option 4"
              placeholder="Enter Option 4"
              required
              style={{ width: "100%" }}
              {...form.getInputProps("mcq4")}
            />
            <TextInput
              label="Subject"
              placeholder="Enter the subject"
              required
              style={{ width: "100%" }}
              {...form.getInputProps("subject")}
            />
            <TextInput
              label="Grade"
              placeholder="Enter the grade (e.g., 9th or 11th)"
              required
              style={{ width: "100%" }}
              {...form.getInputProps("grade")}
            />
            <Select
              required
              mt="sm"
              label="Select Board"
              placeholder="Select Board"
              data={boards}
              {...form.getInputProps("board")}
            />
          </div>
          <Group position="center" mt="sm">
            <Button
              type="submit"
              variant="gradient"
              gradient={{ from: "teal", to: "blue" }}
            >
              Add Question
            </Button>
          </Group>
        </Container>
      </form>
    </div>
  );
}
