import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Grid, Typography, Container, Paper, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import { Close as CloseIcon, Download as DownloadIcon } from '@mui/icons-material';
import axios from 'axios';
import { APIData } from 'authentication/APIData';
import OfferLetter from './OfferLetter';
import { org } from 'authentication/APIData';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const containerStyle = {
  color: '#333',
  maxWidth: '700px',
  margin: 'auto',
  padding: '20px',
  backgroundColor: '#fff',
};

const headerStyle = {
  textAlign: 'left',
  marginBottom: '20px',
};

const logoStyle = {
  width: '80px',
  height: '80px',
  marginLeft: '20px',
};

const headerContentStyle = {
  marginLeft: '0px',
};

const titleTextStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  margin: '10px 0',
};

const paragraphStyle = {
  margin: '5px 0',
};

const paragraphStyle1 = {
  margin: '5px 0',
  marginBottom: '20px',
};

const contentStyle = {
  lineHeight: '1.6',
  marginBottom: '20px',
};

const nocTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  margin: '10px 0',
};

const signatureStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '40px',
};

const signatureBlockStyle = {
  textAlign: 'center',
};

const titleStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '10px 0',
};

const subtitleStyle = {
  fontSize: '16px',
  margin: '3px 0',
};

const sectionTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  margin: '20px 0 10px 0',
};

const textStyle = {
  margin: '10px 0',
};

const signOffStyle = {
  fontWeight: 'bold',
};



const initialState = {

  header1: "",
  header10: "",
  header11: "",
  header12: "",
  header13: "",
  header14: "",
  header15: '',
  header16: "",
  header17: "",
  header18: "",
  header19: "",
  header2: "",
  header20: "",
  header21: "",
  header3: "",
  header4: "",
  header5: "",
  header6: "",
  header7: "",
  header8: "",
  header9: "",
  id: 0,
  org: org,
  para1: "",
  para10: "",
  para11: "",
  para12: "",
  para13: "",
  para14: "",
  para15: "",
  para16: "",
  para17: "",
  para18: "",
  para19: "",
  para2: "",
  para20: "",
  para21: "",
  para22: "",
  para3: "",
  para4: "",
  para5: "",
  para6: "",
  para7: "",
  para8: "",
  para9: "",
  temp_name: "EMPOFFER",
  title: "Offer Letter"

}

function OfferEdit() {
  const [formData, setFormData] = useState(initialState);
  const [orgdetails, setOrgdetails] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const templateRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(accessLevel);
  }, []);

  const handleBack = () => {
    if (location.state?.returnToStepper) {
      navigate(location.state.returnPath);
    } else {
      navigate(-1);
    }
  };


  useEffect(() => {
    fetchOrgDetails();
  },);

  const fetchOrgDetails = () => {
    axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
      .then((response) => setOrgdetails(response.data))
      .catch((error) => console.error(error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Updated",
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Update Error",
          text: error.response.data
        });

      })
    console.log('Form Data Submitted:', formData);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadPDF = () => {
    if (!templateRef.current) {
        Swal.fire({
            icon: 'error',
            title: 'PDF Generation Error',
            text: 'Template content not found.'
        });
        return;
    }

    html2canvas(templateRef.current, {
        scale: 2,
        useCORS: true,
        logging: false,
        scrollX: 0,
        scrollY: -window.scrollY,
        width: templateRef.current.scrollWidth,
        height: templateRef.current.scrollHeight
    }).then(canvas => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pageWidth - 20;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const totalPages = Math.ceil(imgHeight / pageHeight);

        for (let i = 0; i < totalPages; i++) {
            if (i > 0) {
                pdf.addPage();
            }

            const yOffset = -i * pageHeight;

            pdf.addImage(
                imgData, 
                'PNG', 
                10, 
                10 + yOffset, 
                imgWidth, 
                imgHeight
            );
        }

        const filename = `NOC_Template_${formData.title || 'Untitled'}_${new Date().toISOString().split('T')[0]}.pdf`;

        pdf.save(filename);
    }).catch(error => {
        console.error('Error generating PDF:', error);
        Swal.fire({
            icon: 'error',
            title: 'PDF Generation Error',
            text: 'Could not generate PDF. Please try again.'
        });
    });
};

  return (
    <Container>
      <PageLayout>

        <SoftButton onClick={handleBack}>
          Back
        </SoftButton>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" gutterBottom>
              Enrollment Template Form
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 1</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header1'}
                    value={formData.header1}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 2</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header2'}
                    value={formData.header2}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 3</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header3'}
                    value={formData.header3}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph </SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para1'}
                    multiline={true}
                    rows={3}
                    value={formData.para1}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph </SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para8'}
                    multiline={true}
                    rows={3}
                    value={formData.para8}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 5</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header5'}
                    value={formData.header5}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para9'}
                    multiline={true}
                    rows={3}
                    value={formData.para9}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para2'}
                    multiline={true}
                    rows={3}
                    value={formData.para2}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 8</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header8'}
                    value={formData.header8}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para10'}
                    multiline={true}
                    rows={3}
                    value={formData.para10}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 11</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header11'}
                    value={formData.header11}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para13'}
                    multiline={true}
                    rows={3}
                    value={formData.para13}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 12</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header12'}
                    multiline={true}
                    rows={3}
                    value={formData.header12}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph </SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para14'}
                    multiline={true}
                    rows={3}
                    value={formData.para14}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 13</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header13'}

                    value={formData.header1}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph </SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para15'}
                    multiline={true}
                    rows={3}
                    value={formData.para15}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 14</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header14'}
                    value={formData.header14}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para16'}
                    multiline={true}
                    rows={3}
                    value={formData.para16}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 15</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header15'}

                    value={formData.header15}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para17'}
                    multiline={true}
                    rows={3}
                    value={formData.para17}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 16</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header16'}
                    value={formData.header16}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>  <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para18'}
                    multiline={true}
                    rows={3}
                    value={formData.para18}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 9</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header9'}
                    value={formData.header9}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para11'}
                    multiline={true}
                    rows={3}
                    value={formData.para11}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>   <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 10</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header10'}

                    value={formData.header10}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para12'}
                    multiline={true}
                    rows={3}
                    value={formData.para12}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para3'}
                    multiline={true}
                    rows={3}
                    value={formData.para3}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Paragraph</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'para4'}
                    multiline={true}
                    rows={3}
                    value={formData.para4}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 4</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header4'}
                    value={formData.header4}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header 6</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header6'}
                    value={formData.header6}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Header7</SoftTypography>
                  <SoftInput
                    fullWidth
                    name={'header7'}

                    value={formData.header7}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    style={{ marginBottom: '16px', width: "100%" }}
                    onClick={handleClickOpen}
                  >
                    View Template in Full Screen and Download
                  </Button>
                  <Button type="submit" variant="contained" color="primary" style={{ width: '100%' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h5" gutterBottom>
              Adaptive Template
            </Typography>
            <Paper elevation={3} style={{ padding: '16px' }}>
              {orgdetails ? (
                <div style={containerStyle}>
                  <img src={orgdetails.orgLogo} style={{ width: 40, height: 40 }} />
                  <div style={headerStyle}>
                    <div style={titleStyle}>{orgdetails.orgName}</div>
                    <div style={subtitleStyle}>+91 {orgdetails.orgMob}| {orgdetails.orgEmailId}| {orgdetails.orgURL}</div>
                  </div>
                  <div style={{
                    textAlign: "center", fontSize: '16px',
                    fontWeight: 'bold',
                  }} >{formData.header1}</div>

                  <div style={textStyle}>{formData.header2} 03/08/2024</div>
                  <div style={textStyle}>{formData.header3} AMULYA,</div>

                  <div style={textStyle}>
                    {formData.para1} <strong>React Developer Intern</strong> {formData.para8} {formData.header5}{formData.para9}
                  </div>
                  <div style={textStyle}>
                    {formData.para2} <strong>React Developer Intern</strong>
                  </div>
                  <div style={sectionTitleStyle}> {formData.header8}</div>
                  <div style={textStyle}>
                    {formData.para10}
                  </div>

                  <div style={sectionTitleStyle}>{formData.header11}</div>
                  <div style={textStyle}>
                    {formData.para13}
                  </div>

                  <div style={sectionTitleStyle}>{formData.header12}</div>
                  <div style={textStyle}>{formData.para14}
                  </div>

                  <div style={sectionTitleStyle}>{formData.header13}</div>
                  <div style={textStyle}>
                    {formData.para15}  </div>

                  <div style={sectionTitleStyle}>{formData.header14}</div>
                  <div style={textStyle}>
                    {formData.para16} </div>

                  <div style={sectionTitleStyle}>{formData.header15}</div>
                  <div style={textStyle}>
                    {formData.para17}</div>

                  <div style={sectionTitleStyle}>{formData.header16}</div>
                  <div style={textStyle}>
                    {formData.para18}  </div>

                  <div style={sectionTitleStyle}>{formData.header9}</div>
                  <div style={textStyle}>
                    {formData.para11} </div>

                  <div style={sectionTitleStyle}>{formData.header10}</div>
                  <div style={textStyle}>
                    {formData.para12} </div>

                  <div style={textStyle}>
                    {formData.para3}’Note Date will be automated’{formData.para4}
                  </div>
                  <div style={textStyle}>
                    {formData.header4}
                  </div>
                  <div style={signatureStyle}>
                    <div>
                      <div>{formData.header6}</div>
                      <div style={signOffStyle}>Care4Edu Solutions Pvt Ltd</div>
                    </div>
                    <div>
                      <div>{formData.header7}</div>
                      <div style={signOffStyle}>Employee Name Automated</div>
                    </div>
                  </div>

                </div>
              ) : (
                <Typography variant="body1">Loading organization details...</Typography>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <OfferLetter />
          </Grid>
        </Grid>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>
            Full Screen View
            {(permission === 1111 || isSuperAdmin) && (
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleDownloadPDF}
                aria-label="download"
                style={{ position: 'absolute', right: 56, top: 16 }}
              >
                <DownloadIcon />
              </IconButton>
            )}
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ position: 'absolute', right: 16, top: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {orgdetails ? (
              <div
                ref={templateRef}
                style={{
                  ...containerStyle,
                  backgroundColor: 'white',
                  padding: '20px',
                  margin: 'auto'
                }}
              >
                <div style={containerStyle}>
                  <img src={orgdetails.orgLogo} style={{ width: 40, height: 40 }} />
                  <div style={headerStyle}>
                    <div style={titleStyle}>{orgdetails.orgName}</div>
                    <div style={subtitleStyle}>+91 {orgdetails.orgMob}| {orgdetails.orgEmailId}| {orgdetails.orgURL}</div>
                  </div>
                  <div style={{
                    textAlign: "center", fontSize: '16px',
                    fontWeight: 'bold',
                  }} >{formData.header1}</div>

                  <div style={textStyle}>{formData.header2} 03/08/2024</div>
                  <div style={textStyle}>{formData.header3} AMULYA,</div>

                  <div style={textStyle}>
                    {formData.para1} <strong>React Developer Intern</strong> {formData.para8} {formData.header5}{formData.para9}
                  </div>
                  <div style={textStyle}>
                    {formData.para2} <strong>React Developer Intern</strong>
                  </div>
                  <div style={sectionTitleStyle}> {formData.header8}</div>
                  <div style={textStyle}>
                    {formData.para10}
                  </div>

                  <div style={sectionTitleStyle}>{formData.header11}</div>
                  <div style={textStyle}>
                    {formData.para13}
                  </div>

                  <div style={sectionTitleStyle}>{formData.header12}</div>
                  <div style={textStyle}>{formData.para14}
                  </div>

                  <div style={sectionTitleStyle}>{formData.header13}</div>
                  <div style={textStyle}>
                    {formData.para15}  </div>

                  <div style={sectionTitleStyle}>{formData.header14}</div>
                  <div style={textStyle}>
                    {formData.para16} </div>

                  <div style={sectionTitleStyle}>{formData.header15}</div>
                  <div style={textStyle}>
                    {formData.para17}</div>

                  <div style={sectionTitleStyle}>{formData.header16}</div>
                  <div style={textStyle}>
                    {formData.para18}  </div>

                  <div style={sectionTitleStyle}>{formData.header9}</div>
                  <div style={textStyle}>
                    {formData.para11} </div>

                  <div style={sectionTitleStyle}>{formData.header10}</div>
                  <div style={textStyle}>
                    {formData.para12} </div>

                  <div style={textStyle}>
                    {formData.para3}’Note Date will be automated’{formData.para4}
                  </div>
                  <div style={textStyle}>
                    {formData.header4}
                  </div>
                  <div style={signatureStyle}>
                    <div>
                      <div>{formData.header6}</div>
                      <div style={signOffStyle}>Care4Edu Solutions Pvt Ltd</div>
                    </div>
                    <div>
                      <div>{formData.header7}</div>
                      <div style={signOffStyle}>Employee Name Automated</div>
                    </div>
                  </div>

                </div>
              </div>
            ) : (
              <Typography variant="body1">Loading organization details...</Typography>
            )}
          </DialogContent>
        </Dialog>
      </PageLayout>
    </Container>
  );
}

export default OfferEdit;

