// import React, { useState } from "react";
// import { Card, Grid, FormControl, FormHelperText, Tooltip } from "@mui/material";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftInput from "components/SoftInput";
// import SoftButton from "components/SoftButton";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import * as AiIcons from "react-icons/ai";
// import { APIData, org } from 'authentication/APIData';

// function AddNewFestival() {
//     const [festivalName, setFestivalName] = useState("");
//     const [festivalDate, setFestivalDate] = useState("");
//     const [description, setDescription] = useState("");
//     const [image, setImage] = useState(null);
//     const [imagePreview, setImagePreview] = useState("");
//     const [errors, setErrors] = useState({});
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     const handleImageChange = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             if (file.size > 1000000) {
//                 Swal.fire({
//                     icon: "error",
//                     title: "Oops...",
//                     text: "File too large. Max file size is 1MB.",
//                 });
//                 setImage(null);
//                 setImagePreview("");
//                 return;
//             }
//             setImage(file);
//             setImagePreview(URL.createObjectURL(file));
//             setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
//         }
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         setLoading(true);

//         const newErrors = {};

//         // Validate required fields
//         if (!festivalName) newErrors.festivalName = "Festival Name is required.";
//         if (!festivalDate) newErrors.festivalDate = "Festival Date is required.";
//         if (!description) newErrors.description = "Description is required.";
//         if (!image) newErrors.image = "Image is required.";

//         // Check if the festival name contains numbers
//         if (/\d/.test(festivalName)) {
//             newErrors.festivalName = "Festival Name cannot contain numbers.";
//         }

//         // Check if the selected festival date is in the past
//         const today = new Date();
//         const selectedDate = new Date(festivalDate);
//         if (festivalDate && selectedDate < today) {
//             newErrors.festivalDate = "Festival Date cannot be in the past.";
//         }

//         // Check if description exceeds 250 characters
//         if (description.length > 250) {
//             newErrors.description = "Description should be below 250 characters.";
//         }

//         setErrors(newErrors);

//         // If there are errors, stop the submission
//         if (Object.keys(newErrors).length > 0) {
//             setLoading(false);
//             return;
//         }

//         const festivaldata = new FormData();
//         festivaldata.append("image", image);
//         festivaldata.append("fest_name", festivalName);
//         festivaldata.append("fest_date", festivalDate);
//         festivaldata.append("des", description);
//         festivaldata.append("org", org);

//         festivaldata.forEach((value, key) => {
//             console.log(`${key}: ${value}`);
//         });

//         try {
//             const url = `${APIData.api}festivals/`;
//             const response = await axios.post(url, festivaldata, { headers: APIData.headers });

//             Swal.fire({
//                 icon: "success",
//                 title: "Festival Added Successfully",
//                 showConfirmButton: false,
//                 timer: 1500,
//             });

//             setLoading(false);
//             setTimeout(() => {
//                 navigate("/festival-list");
//             }, 1500);
//         } catch (error) {
//             console.error(error);
//             setLoading(false);
//             Swal.fire({
//                 icon: "error",
//                 title: "Oops...",
//                 text: "Failed to add festival",
//                 footer: error.message,
//             });
//         }
//     };


//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
//                 <Grid item xs={10} sm={10} md={8} lg={6}>
//                     <Card className="w-full">
//                         <SoftBox p={3} mb={1} display="flex" alignItems="center">
//                             <Tooltip title="Close" arrow>
//                                 <Link to="/festival-list">
//                                     <AiIcons.AiOutlineCloseCircle />
//                                 </Link>
//                             </Tooltip>
//                             <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
//                                 Add New Festival
//                             </SoftTypography>
//                         </SoftBox>
//                         <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
//                             <SoftBox mb={2}>
//                                 <SoftTypography variant="h6">Festival Name *</SoftTypography>
//                                 <FormControl fullWidth error={!!errors.festivalName}>
//                                     <SoftInput
//                                         placeholder="Festival Name"
//                                         value={festivalName}
//                                         onChange={(e) => setFestivalName(e.target.value)}
//                                     />
//                                     {errors.festivalName && (
//                                         <FormHelperText>{errors.festivalName}</FormHelperText>
//                                     )}
//                                 </FormControl>
//                             </SoftBox>
//                             <SoftBox mb={2}>
//                                 <SoftTypography variant="h6">Festival Date *</SoftTypography>
//                                 <FormControl fullWidth error={!!errors.festivalDate}>
//                                     <SoftInput
//                                         type="date"
//                                         value={festivalDate}
//                                         onChange={(e) => setFestivalDate(e.target.value)}
//                                     />
//                                     {errors.festivalDate && (
//                                         <FormHelperText>{errors.festivalDate}</FormHelperText>
//                                     )}
//                                 </FormControl>
//                             </SoftBox>
//                             <SoftBox mb={2}>
//                                 <SoftTypography variant="h6">Description *</SoftTypography>
//                                 <FormControl fullWidth error={!!errors.description}>
//                                     <SoftInput
//                                         placeholder="Description"
//                                         value={description}
//                                         onChange={(e) => setDescription(e.target.value)}
//                                     />
//                                     {errors.description && (
//                                         <FormHelperText>{errors.description}</FormHelperText>
//                                     )}
//                                 </FormControl>
//                             </SoftBox>
//                             <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
//                                 <input
//                                     accept="image/*"
//                                     style={{ display: 'none' }}
//                                     id="upload-image"
//                                     type="file"
//                                     onChange={handleImageChange}
//                                 />
//                                 <label htmlFor="upload-image">
//                                     <SoftButton variant="contained" component="span">
//                                         Upload Image
//                                     </SoftButton>
//                                 </label>
//                                 {imagePreview && (
//                                     <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
//                                         <img src={imagePreview} alt="Preview" style={{ width: '200px', height: 'auto', objectFit: 'contain' }} />
//                                     </SoftBox>
//                                 )}
//                                 {errors.image && (
//                                     <FormHelperText error>{errors.image}</FormHelperText>
//                                 )}
//                             </SoftBox>
//                             <SoftBox mt={4} mb={1}>
//                                 <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
//                                     {loading ? "Submitting..." : "Submit"}
//                                 </SoftButton>
//                             </SoftBox>
//                         </SoftBox>
//                     </Card>
//                 </Grid>
//             </Grid>
//         </DashboardLayout>
//     );
// }

// export default AddNewFestival;

import React, { useState, useEffect } from 'react';
import { Card, Grid, FormControl, Tooltip, Backdrop, CircularProgress } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import { APIData, org } from 'authentication/APIData';

function AddNewFestival() {
    const [festivals, setFestivals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submissionProgress, setSubmissionProgress] = useState({
        total: 0,
        submitted: 0,
        failed: 0
    });
    const [showForm, setShowForm] = useState(false);
    const [values, setValues] = useState({
        fest_name: "",
        fest_date: "",
        des: "",
        image: null,
        imagePreview: ""
    });
    const navigate = useNavigate();

    const CALENDARIFIC_API_KEY = 'l3XbdezOtHpY9suuAv6W6J39Pc7e4C9h';
    const CALENDARIFIC_API_URL = 'https://calendarific.com/api/v2/holidays';

    const fetchIndiaFestivals = async () => {
        setLoading(true);
        try {
            if (!CALENDARIFIC_API_KEY) {
                throw new Error('Calendarific API key is not configured');
            }

            const currentYear = new Date().getFullYear();
            const today = new Date();

            // Fetch festivals for current year and next year to ensure we get upcoming festivals
            const responses = await Promise.all([
                axios.get(CALENDARIFIC_API_URL, {
                    params: {
                        api_key: CALENDARIFIC_API_KEY,
                        country: 'IN',
                        year: currentYear,
                        type: 'religious,national'
                    }
                }),
            ]);

            let allHolidays = [];
            responses.forEach(response => {
                if (response.data.response && response.data.response.holidays) {
                    allHolidays = [...allHolidays, ...response.data.response.holidays];
                }
            });

            if (allHolidays.length === 0) {
                throw new Error('No festivals found or unexpected response format');
            }

            const formattedFestivals = allHolidays
                .filter(holiday =>
                (holiday.type.includes('Hindu') ||
                    holiday.type.includes('Festival') ||
                    holiday.type.includes('National holiday'))
                )
                .map(festival => ({
                    fest_name: festival.name,
                    fest_date: festival.date.iso,
                    des: festival.description || `${festival.name} - ${festival.type.join(', ')}`,
                    org: org
                }));

            // Filter future dates more accurately
            const filteredFestivals = formattedFestivals.filter(festival => {
                const festDate = new Date(festival.fest_date);
                // Set times to midnight for accurate date comparison
                festDate.setHours(0, 0, 0, 0);
                today.setHours(0, 0, 0, 0);
                return festDate >= today;
            });

            // Remove duplicates
            const uniqueFestivals = filteredFestivals.reduce((acc, current) => {
                const isDuplicate = acc.find(item =>
                    item.fest_date === current.fest_date &&
                    item.fest_name === current.fest_name
                );
                if (!isDuplicate) {
                    acc.push(current);
                }
                return acc;
            }, []);

            // Sort by date
            uniqueFestivals.sort((a, b) => new Date(a.fest_date) - new Date(b.fest_date));

            const url = `${APIData.api}festivals/all-Festival?org=${org}`;
            const existingFestivalsResponse = await axios.get(url, { headers: APIData.headers });
            const existingFestivals = existingFestivalsResponse.data;

            const newFestivals = uniqueFestivals.filter(festival =>
                !existingFestivals.some(existing =>
                    existing.festival_date === festival.fest_date &&
                    existing.festival_name.toLowerCase() === festival.fest_name.toLowerCase()
                )
            );

            setFestivals(newFestivals);
            setSubmissionProgress({
                total: newFestivals.length,
                submitted: 0,
                failed: 0
            });

            if (newFestivals.length === 0) {
                Swal.fire({
                    icon: "info",
                    title: "No New Festivals",
                    text: "All upcoming festivals are already in the database.",
                });
            }

        } catch (error) {
            console.error('Error fetching festivals:', error);
            let errorMessage = 'Failed to fetch festivals';
            if (error.response) {
                errorMessage = error.response.data.meta?.error_detail ||
                    error.response.data.meta?.error_type ||
                    'Authentication failed';
            }
            Swal.fire({
                icon: 'error',
                title: 'Festival Fetch Error',
                text: errorMessage
            });
        } finally {
            setLoading(false);
        }
    };

    const constructFestivalUrl = (festName, festDate, description) => {
        const baseUrl = `${APIData.api}festivals/`;
        const params = new URLSearchParams();

        // Properly encode each parameter
        params.append("fest_name", festName.trim());
        params.append("fest_date", festDate);
        params.append("des", description.trim());
        params.append("org", org);

        return `${baseUrl}?${params.toString()}`;
    };

    const generateImageFromText = async (festName) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // Set canvas dimensions
        canvas.width = 400;
        canvas.height = 300;
        
        // Create gradient background
        const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
        gradient.addColorStop(0, '#FF9933');  // Saffron color
        gradient.addColorStop(0.5, '#FFFFFF'); // White
        gradient.addColorStop(1, '#138808');   // Green
        
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        // Add festival name
        ctx.font = 'bold 32px Arial';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        
        // Word wrap for long festival names
        const words = festName.split(' ');
        let line = '';
        let lines = [];
        let y = canvas.height / 2;
        
        for (let word of words) {
            const testLine = line + word + ' ';
            if (ctx.measureText(testLine).width > canvas.width - 40) {
                lines.push(line);
                line = word + ' ';
            } else {
                line = testLine;
            }
        }
        lines.push(line);
        
        // Draw each line
        lines.forEach((line, i) => {
            ctx.fillText(line.trim(), canvas.width / 2, y - ((lines.length - 1) * 20) + (i * 40));
        });
        
        // Add decorative elements
        ctx.strokeStyle = '#FFC107';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.arc(canvas.width / 2, 50, 30, 0, 2 * Math.PI);
        ctx.stroke();
        
        // Convert to blob
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 0.95);
        });
    };

    const createValidJPEG = async (festName) => {
        try {
            return await generateImageFromText(festName);
        } catch (error) {
            console.error('Error creating festival image:', error);
            throw new Error('Failed to generate festival image');
        }
    };

    const addFestivalsToDatabase = async () => {
        setLoading(true);
        const submissionResults = {
            total: festivals.length,
            submitted: 0,
            failed: 0
        };

        try {
            for (const festival of festivals) {
                try {
                    // Validate required fields
                    if (!festival.fest_name?.trim() || !festival.fest_date?.trim() || !festival.des?.trim()) {
                        throw new Error('Missing required fields');
                    }

                    // Create a valid JPEG blob
                    const imageBlob = await createValidJPEG(festival.fest_name);

                    // Create FormData
                    const formData = new FormData();

                    // Add image with proper filename
                    const filename = `${festival.fest_name.toLowerCase().replace(/[^a-z0-9]/g, '-')}.jpg`;
                    formData.append('image', imageBlob, filename);

                    // Construct URL with encoded parameters
                    const url = constructFestivalUrl(
                        festival.fest_name,
                        festival.fest_date,
                        festival.des
                    );

                    // Submit to API
                    const response = await axios.post(
                        url,
                        formData,
                        {
                            headers: {
                                ...APIData.headers,
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );

                    submissionResults.submitted++;
                } catch (err) {
                    console.error(`Failed to submit festival: ${festival.fest_name}`, err);
                    submissionResults.failed++;
                }

                setSubmissionProgress(submissionResults);
            }

            if (submissionResults.submitted > 0) {
                Swal.fire({
                    icon: submissionResults.failed > 0 ? "warning" : "success",
                    title: "Festival Submission Complete",
                    text: `Successfully added: ${submissionResults.submitted}, Failed: ${submissionResults.failed}`,
                    timer: 2000,
                    showConfirmButton: false
                });

                setTimeout(() => {
                    navigate("/festival-list");
                }, 2000);
            } else {
                throw new Error("No festivals could be submitted");
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Submission Failed",
                text: error.message
            });
        } finally {
            setLoading(false);
        }
    };

    const handleManualSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            // Validate required fields
            if (!values.fest_name?.trim()) {
                throw new Error("Festival name is required");
            }
            if (!values.fest_date?.trim()) {
                throw new Error("Festival date is required");
            }
            if (!values.des?.trim()) {
                throw new Error("Description is required");
            }

            let imageBlob;
            if (values.image) {
                imageBlob = values.image;
            } else {
                // Generate valid JPEG if no image is uploaded
                imageBlob = await createValidJPEG(values.fest_name);
            }

            // Create FormData
            const formData = new FormData();

            // Add image with proper filename
            const filename = `${values.fest_name.toLowerCase().replace(/[^a-z0-9]/g, '-')}.jpg`;
            formData.append('image', imageBlob, filename);

            // Construct URL with encoded parameters
            const url = constructFestivalUrl(
                values.fest_name,
                values.fest_date,
                values.des
            );

            // Submit to API
            await axios.post(
                url,
                formData,
                {
                    headers: {
                        ...APIData.headers,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Festival added successfully",
                timer: 2000,
                showConfirmButton: false
            });

            // Reset form
            setValues({
                fest_name: "",
                fest_date: "",
                des: "",
                image: null,
                imagePreview: ""
            });

            setTimeout(() => {
                navigate("/festival-list");
            }, 2000);

        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || error.message || "Failed to add festival"
            });
        } finally {
            setLoading(false);
        }
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "File too large. Max file size is 1MB.",
                });
                setValues({ ...values, image: null, imagePreview: "" });
                return;
            }
            setValues({
                ...values,
                image: file,
                imagePreview: URL.createObjectURL(file)
            });
        }
    };

    useEffect(() => {
        fetchIndiaFestivals();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={3} justifyContent="center" alignItems="center" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card>
                        <SoftBox p={3} display="flex" alignItems="center" justifyContent="space-between">
                            <Tooltip title="Close" arrow>
                                <Link to="/festival-list">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium">
                                {new Date().getFullYear()} Festivals
                            </SoftTypography>
                            <SoftButton
                                variant="gradient"
                                color="info"
                                onClick={addFestivalsToDatabase}
                                disabled={loading || festivals.length === 0}
                            >
                                {loading ? "Processing..." : "Add All Festivals"}
                            </SoftButton>
                        </SoftBox>

                        {festivals.length > 0 && (
                            <SoftBox px={3} pb={3}>
                                <SoftTypography variant="body2" color="text" mb={2}>
                                    Festivals to be added: {submissionProgress.total} |
                                    Submitted: {submissionProgress.submitted} |
                                    Failed: {submissionProgress.failed}
                                </SoftTypography>

                                <Grid container spacing={2}>
                                    {festivals.map((festival, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Card variant="outlined">
                                                <SoftBox p={2}>
                                                    <SoftTypography variant="h6">
                                                        {festival.fest_name}
                                                    </SoftTypography>
                                                    <SoftTypography variant="body2" color="text">
                                                        Date: {festival.fest_date}
                                                    </SoftTypography>
                                                    <SoftTypography variant="body2" color="text">
                                                        {festival.des}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>


                            </SoftBox>
                        )}
                        <SoftBox display="flex" justifyContent="center" mt={3}>
                            <SoftButton
                                variant="gradient"
                                color="info"
                                onClick={() => setShowForm(!showForm)}
                            >
                                {showForm ? 'Hide Festival Form' : 'Add A Festival Manually'}
                            </SoftButton>
                        </SoftBox>

                        {showForm && (
                            <SoftBox component="form" role="form" onSubmit={handleManualSubmit} px={3} pb={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6">Festival Name</SoftTypography>
                                            <FormControl fullWidth>
                                                <SoftInput
                                                    placeholder="Festival Name"
                                                    value={values.fest_name}
                                                    onChange={(e) => setValues({ ...values, fest_name: e.target.value })}
                                                />
                                            </FormControl>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6">Festival Date</SoftTypography>
                                            <FormControl fullWidth>
                                                <SoftInput
                                                    type="date"
                                                    value={values.fest_date}
                                                    onChange={(e) => setValues({ ...values, fest_date: e.target.value })}
                                                />
                                            </FormControl>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6">Description</SoftTypography>
                                            <FormControl fullWidth>
                                                <SoftInput
                                                    placeholder="Description"
                                                    value={values.des}
                                                    onChange={(e) => setValues({ ...values, des: e.target.value })}
                                                    multiline
                                                    rows={4}
                                                />
                                            </FormControl>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox display="flex" flexDirection="column" alignItems="center">
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="upload-image"
                                                type="file"
                                                onChange={handleImageChange}
                                            />
                                            <label htmlFor="upload-image">
                                                <SoftButton variant="contained" component="span">
                                                    Upload Image
                                                </SoftButton>
                                            </label>
                                            {values.imagePreview && (
                                                <SoftBox mt={2}>
                                                    <img
                                                        src={values.imagePreview}
                                                        alt="Preview"
                                                        style={{ width: '200px', height: 'auto', objectFit: 'contain' }}
                                                    />
                                                </SoftBox>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} display="flex" justifyContent="center">
                                        <SoftButton
                                            type="submit"
                                            variant="gradient"
                                            color="info"
                                            disabled={loading}
                                        >
                                            {loading ? "Submitting..." : "Submit Festival"}
                                        </SoftButton>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddNewFestival;