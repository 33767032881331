import React, { useState, useEffect, useRef } from 'react';
import { APIData, org } from '../authentication/APIData';
import {
    Grid,
    Card,
    Backdrop,
    CircularProgress,
    Box,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    TextField,
    InputAdornment,
    Button,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Paper } from "@mui/material";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import * as AiIcons from "react-icons/ai";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Swal from 'sweetalert2';

const StudentRegistration = () => {
    const syllabusSubjects = ["PCMB", "PCMC", "PCME", "CEBA", "HEBA", "MEBA", "SEBA", "NA"];
    // const studentClass = ["CLASS 1", "CLASS 2", "CLASS 3", "CLASS 4", "CLASS 5", "CLASS 6", "CLASS 7", "CLASS 8", "CLASS 9", "CLASS 10", "CLASS 11", "CLASS 12"];
    const states = [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat",
        "Haryana", "Himachal Pradesh", "Jharkhand", "Jammu and Kashmir", "Karnataka", "Kerala",
        "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha",
        "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh",
        "Uttarakhand", "West Bengal"
    ];

    const [loading, setLoading] = useState(false);
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const [logoUrl, setLogoUrl] = useState('');
    const navigate = useNavigate();
    const [fileName, setFileName] = useState("");
    const [showCamera, setShowCamera] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);
    const captureInputRef = useRef(null);
    const [studentsList, setStudentsList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await axios.get(
                    `${APIData.api}enrollments/accepted-students?org=${org}`,
                    { headers: APIData.headers }
                );
                if (response.data) {
                    // Transform the data for the dropdown
                    const studentsData = Array.isArray(response.data) ? response.data : [response.data];
                    setStudentsList(studentsData.map(student => ({
                        value: student.user_name,
                        label: student.user_name,
                        data: student
                    })));
                }
            } catch (error) {
                console.error('Error fetching students:', error);
                toast.error('Failed to fetch students list');
            }
        };

        fetchStudents();
    }, []);

    const handleStudentSelect = async (selectedOption) => {
        const student = selectedOption?.data;
        if (student) {
            setSelectedStudent(student);
            // Auto-fill the form with available data
            setFormData(prevData => ({
                ...prevData,
                name: student.user_name || '',
                emailId: student.user_email || '',
                mobileNo: student.user_phone_number || '',
                course: student.course_id || '',
                mentor: student.deptSupervisor || '',
            }));

            // Fetch course details if course_id is available
            if (student.course_id) {
                try {
                    const response = await axios.get(
                        `${APIData.api}courses/${student.course_id}`,
                        { headers: APIData.headers }
                    );

                    if (response.status === 200) {
                        const courseDetails = response.data;
                        console.log('Fetched Course Details:', courseDetails);

                        // You can update the form with additional course details if needed
                        setFormData(prevData => ({
                            ...prevData,
                            studentClass: courseDetails.courseName,
                        }));
                    }
                } catch (error) {
                    console.error('Error fetching course details:', error);
                    toast.error('Failed to fetch course details');
                }
            }
        }
    };

    const [formData, setFormData] = useState({
        academicYear: "",
        address: "",
        adharNo: "",
        bloodGroup: "",
        city: "",
        coremarksObtained: "",
        coremaxMarks: "",
        coreminMarks: "",
        course: "",
        dateOfBirth: "",
        emailId: "",
        fatherName: "",
        gender: "",
        id: 0,
        image_url: "",
        languagemarksObtained: "",
        languagemaxMarks: "",
        languageminMarks: "",
        mobileNo: "",
        motherName: "",
        name: "",
        org: org,
        pincode: "",
        schoolOrCollegeName: "",
        state: "",
        studentClass: "",
        syllabusType: "",
        syllabus_subjects: "",
        mentor: "",
    });



    const [errors, setErrors] = useState({}); // State to hold validation errors

    const handlePDFDownload = () => {
        const input = document.getElementById('student-registration-form');
        const margin = 20;
        const submitButton = document.querySelector("button[type='submit']");

        if (submitButton) {
            submitButton.style.display = 'none';
        }

        // Generate a higher scale for better quality
        html2canvas(input, { scale: 3 }) // Increase scale for better quality
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2;
                const pdfHeight = pdf.internal.pageSize.getHeight() - margin * 2;

                // Adjust image dimensions to fit PDF without loss of quality
                const imgWidth = pdfWidth;
                const imgHeight = (canvas.height * pdfWidth) / canvas.width;

                let heightLeft = imgHeight;
                let positionY = margin;

                // Add pages if the content overflows
                pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, Math.min(heightLeft, pdfHeight));
                heightLeft -= pdfHeight;

                while (heightLeft > 0) {
                    positionY = heightLeft - imgHeight;  // Calculate the position for next page
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, Math.min(heightLeft, pdfHeight));
                    heightLeft -= pdfHeight;
                }

                pdf.save("student-registration.pdf");
            })
            .catch((error) => {
                console.error("Error generating PDF", error);
                toast.error("Error generating PDF");
            })
            .finally(() => {
                if (submitButton) {
                    submitButton.style.display = '';
                }
            });
    };

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const resp = await axios.get(
                    `${APIData.api}org-mdm/org-id?orgId=${org}`,
                    { headers: APIData.headers }
                );
                if (resp.status === 200) {
                    const firstItem = resp.data.orgLogo;
                    setLogoUrl(firstItem);
                } else {
                    console.error("No data found.");
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchLogo();
    }, []);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors({}); // Reset errors

        // Validation logic
        const newErrors = {};

        // Validate academic year format
        const academicYearPattern = /^\d{4}-\d{2}$/;
        if (!academicYearPattern.test(formData.academicYear)) {
            newErrors.academicYear = "Please enter a valid academic year in the format YYYY-YY";
        }

        // Validate email format
        if (!/\S+@\S+\.\S+/.test(formData.emailId)) {
            newErrors.emailId = "Please enter a valid email address";
        }

        // Validate Aadhar number format
        if (!/^\d{12}$/.test(formData.adharNo)) {
            newErrors.adharNo = "Please enter a valid 12-digit Aadhar Number";
        }

        // Validate mobile number format
        if (!/^\d{10}$/.test(formData.mobileNo)) {
            newErrors.mobileNo = "Please enter a valid 10-digit Mobile Number";
        }

        // Validate pincode format
        if (!/^\d{6}$/.test(formData.pincode)) {
            newErrors.pincode = "Please enter a valid 6-digit Pincode";
        }

        // Validate numeric values for marks
        if (!/^\d*$/.test(formData.coremarksObtained) || !/^\d*$/.test(formData.coremaxMarks) || !/^\d*$/.test(formData.coreminMarks)) {
            newErrors.coremarksObtained = "Please enter valid numeric values for marks";
        }

        if (!/^\d*$/.test(formData.languagemarksObtained) || !/^\d*$/.test(formData.languagemaxMarks) || !/^\d*$/.test(formData.languageminMarks)) {
            newErrors.languagemarksObtained = "Please enter valid numeric values for marks";
        }

        // Validate name, father name, mother name, city, and school/college name formats
        const namePattern = /^[a-zA-Z\s]+$/;
        if (!namePattern.test(formData.name)) {
            newErrors.name = "Name field should contain only alphabets";
        }

        if (!namePattern.test(formData.fatherName)) {
            newErrors.fatherName = "Father Name field should contain only alphabets";
        }

        if (!namePattern.test(formData.motherName)) {
            newErrors.motherName = "Mother Name field should contain only alphabets";
        }

        if (!namePattern.test(formData.city)) {
            newErrors.city = "City field should contain only alphabets";
        }

        if (!namePattern.test(formData.schoolOrCollegeName)) {
            newErrors.schoolOrCollegeName = "School/College Name field should contain only alphabets";
        }

        // Set errors if any
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        try {
            const url = APIData.api + `studentadmissiondetails/`;
            const response = await axios.post(url, formData, { headers: APIData.headers });

            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Successfully Registered",
                    showConfirmButton: false,
                    timer: 1500
                });
                handlePDFDownload();
                setTimeout(() => {
                    navigate("/registerationDetails");
                }, 1500);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Unable to Register.",
                    footer: response.data || response.message
                });
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Unable to Register.",
                footer: error.response?.data || error.message
            });
        }
    };

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            let newValue = value;

            // Validate numeric fields
            if (name === 'adharNo' || name === 'mobileNo' || name === 'pincode' || name === 'coremaxMarks' || name === 'coreminMarks' || name === 'coremarksObtained' || name === 'languagemaxMarks' || name === 'languageminMarks' || name === 'languagemarksObtained') {
                newValue = value.replace(/\D/g, '');
            }

            // Validate text fields
            if (name === 'name' || name === 'fatherName' || name === 'motherName' || name === 'city' || name === 'schoolOrCollegeName') {
                newValue = value.replace(/\d/g, '');
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: newValue,
            }));

            // Clear the error for the field being changed
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        } else {
            const { name, value } = e;
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => {
            return { ...prevFormData, syllabusType: checked ? name : "" }
        })
    };

    const handleSyllabus_SubjectChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => {
            return { ...prevFormData, syllabus_subjects: checked ? name : "" }
        })
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 1024 * 1024) { // 1MB limit
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64string = reader.result.split(",")[1];
                setFormData({
                    ...formData,
                    image_url: base64string,
                });
                setFileName(file.name);
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("Image size should be less than 1MB");
        }
    };

    const openCamera = () => {
        setShowCamera(true);
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
            })
            .catch((err) => console.error("Error accessing camera:", err));
    };

    const captureImage = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
            if (blob.size <= 1024 * 1024) { // 1MB limit
                const file = new File([blob], "captured-image.png", { type: "image/png" });
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64string = reader.result.split(",")[1];
                    setFormData({
                        ...formData,
                        image_url: base64string,
                    });
                    setFileName(file.name);
                };
                reader.readAsDataURL(file);
            } else {
                toast.error("Captured image size should be less than 1MB");
            }
            video.srcObject.getTracks().forEach((track) => track.stop());
            setShowCamera(false);
        });
    };

    return (
        <DashboardLayout>
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/registerationDetails" style={{ textDecoration: 'none' }}>
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Student Registration Form
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox pt={2} pb={3} px={3}>
                            <SoftBox component="form" role="form" id="student-registration-form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SoftTypography variant='body1' style={{ marginTop: "1rem" }}>
                                            Student Details
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SoftTypography variant="h6" component="label">Select Student</SoftTypography>
                                        <CustomSelect
                                            options={studentsList}
                                            placeholder="Select a student"
                                            onChange={handleStudentSelect}
                                            value={selectedStudent}
                                            isClearable
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="span" style={{ marginRight: 16 }}>Class:</SoftTypography>
                                        <CustomSelect
                                            options={studentClass.map(studentClass => ({ value: studentClass, label: studentClass }))}
                                            placeholder="Select Class"
                                            name='studentClass'
                                            onChange={(selectedOption) => handleChange({ name: 'studentClass', value: selectedOption.value })}
                                            value={formData.studentClass}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="Course">Class :</SoftTypography>
                                        <SoftInput
                                            variant="outlined"
                                            name="course"
                                            label="Course"
                                            size="small"
                                            fullWidth
                                            value={formData.studentClass}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="academicYear">Academic Year</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="academicYear"
                                            name='academicYear'
                                            placeholder="Academic Year (YYYY-YY)"
                                            type='text'
                                            value={formData.academicYear}
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 7 }}
                                        />
                                        {errors.academicYear && <SoftTypography variant="body2" color="error">{errors.academicYear}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" flexWrap="nowrap" width="100%">
                                            <SoftTypography
                                                variant="body1"
                                                component="span"
                                                style={{ marginRight: 16, fontFamily: "Georgia, serif" }}
                                            >
                                                Syllabus Type:
                                            </SoftTypography>

                                            <FormControl
                                                component="fieldset"
                                                required
                                                error={formData.syllabusType.length === 0}
                                                style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                                            >
                                                <FormGroup row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.syllabusType.includes("CBSE")}
                                                                onChange={handleCheckboxChange}
                                                                name="CBSE"
                                                            />
                                                        }
                                                        label="CBSE"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.syllabusType.includes("STATE")}
                                                                onChange={handleCheckboxChange}
                                                                name="STATE"
                                                            />
                                                        }
                                                        label="STATE"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.syllabusType.includes("ICSE")}
                                                                onChange={handleCheckboxChange}
                                                                name="ICSE"
                                                            />
                                                        }
                                                        label="ICSE"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.syllabusType.includes("NA")}
                                                                onChange={handleCheckboxChange}
                                                                name="NA"
                                                            />
                                                        }
                                                        label="NA"
                                                    />
                                                </FormGroup>
                                                {formData.syllabusType.length === 0 && (
                                                    <FormHelperText style={{ marginTop: 4 }}>Please select at least one option</FormHelperText>
                                                )}
                                            </FormControl>

                                            <SoftTypography variant="body1" component="span" style={{ margin: '0 16px', fontSize: "1.5rem" }}>
                                                /
                                            </SoftTypography>
                                            <Grid item xs={12} sm={6}>
                                                <SoftTypography variant="h6" component="label" htmlFor="Course">Course</SoftTypography>
                                                <SoftInput
                                                    variant="outlined"
                                                    name="course"
                                                    label="Course"
                                                    size="small"
                                                    fullWidth
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                />
                                                {errors.course && <SoftTypography variant="body2" color="error">{errors.course}</SoftTypography>}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="dateOfBirth">DOB</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="dateOfBirth"
                                            name='dateOfBirth'
                                            type='date'
                                            value={formData.dateOfBirth}
                                            onChange={handleChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {errors.dateOfBirth && <SoftTypography variant="body2" color="error">{errors.dateOfBirth}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="span" style={{ marginRight: 16 }}>Gender:</SoftTypography>
                                        <CustomSelect
                                            options={[
                                                { value: 'Male', label: 'Male' },
                                                { value: 'Female', label: 'Female' },
                                                { value: 'Others', label: 'Others' },
                                            ]}
                                            placeholder="Select Gender"
                                            name="gender"
                                            onChange={(selectedOption) => handleChange({ name: 'gender', value: selectedOption.value })}
                                            value={formData.gender}
                                        />
                                        {errors.course && <SoftTypography variant="body2" color="error">{errors.course}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="span" style={{ marginRight: 16 }}>Blood Group:</SoftTypography>
                                        <CustomSelect
                                            options={[
                                                { value: 'A+', label: 'A+' },
                                                { value: 'A-', label: 'A-' },
                                                { value: 'B+', label: 'B+' },
                                                { value: 'B-', label: 'B-' },
                                                { value: 'AB+', label: 'AB+' },
                                                { value: 'Ab-', label: 'AB-' },
                                                { value: 'O+', label: 'O+' },
                                                { value: 'O-', label: 'O-' },
                                            ]}
                                            placeholder="Select Blood Group"
                                            name="bloodGroup"
                                            onChange={(selectedOption) => handleChange({ name: 'bloodGroup', value: selectedOption.value })}
                                            value={formData.bloodGroup}
                                        />
                                        {errors.bloodGroup && <SoftTypography variant="body2" color="error">{errors.bloodGroup}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="mobileNo">Mobile Number</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="mobileNo"
                                            name='mobileNo'
                                            placeholder="Enter Mobile Number"
                                            value={formData.mobileNo}
                                            onChange={handleChange}
                                        />
                                        {errors.mobileNo && <SoftTypography variant="body2" color="error">{errors.mobileNo}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="emailId">Email ID</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="emailId"
                                            name='emailId'
                                            placeholder="Enter Email ID"
                                            type='email'
                                            value={formData.emailId}
                                            onChange={handleChange}
                                        />
                                        {errors.emailId && <SoftTypography variant="body2" color="error">{errors.emailId}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="adharNo">Aadhar Number</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="adharNo"
                                            name='adharNo'
                                            placeholder="Enter Aadhar Number"
                                            value={formData.adharNo}
                                            onChange={handleChange}
                                        />
                                        {errors.adharNo && <SoftTypography variant="body2" color="error">{errors.adharNo}</SoftTypography>}
                                    </Grid>

                                    <Grid container spacing={2} item xs={12} alignItems="center">
                                        <Grid item xs={5}>
                                            <SoftTypography variant="h6" component="label" htmlFor="image">
                                                Upload Image
                                            </SoftTypography>
                                            <SoftInput
                                                variant="outlined"
                                                fullWidth
                                                type="file"
                                                id="image"
                                                size="small"
                                                name="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                inputRef={fileInputRef}
                                            />
                                        </Grid>

                                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                                            <SoftTypography variant="h6">or</SoftTypography>
                                        </Grid>

                                        <Grid item xs={5}>
                                            <FormControl
                                                component="fieldset"
                                                error={formData.syllabusType.length === 0}
                                            >
                                                <SoftTypography variant="h6" component="label" htmlFor="camera" mt={4}>
                                                    Take Picture
                                                </SoftTypography>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    readOnly
                                                    value={fileName}
                                                    inputRef={captureInputRef}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Button onClick={openCamera}>
                                                                    Take Picture
                                                                </Button>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={handleImageChange}
                                                />
                                                {formData.image_url.length === 0 && (
                                                    <FormHelperText style={{ marginTop: 4 }}>Please upload profile image or take a picture</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        {showCamera && (
                                            <Grid item xs={12}>
                                                <div style={{
                                                    width: '100%',
                                                    maxWidth: '800px',
                                                    margin: '20px auto',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '8px',
                                                    overflow: 'hidden',
                                                    backgroundColor: '#000'
                                                }}>
                                                    <video
                                                        ref={videoRef}
                                                        autoPlay
                                                        playsInline
                                                        muted
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            display: 'block'
                                                        }}
                                                    />
                                                </div>
                                                <SoftButton
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={captureImage}
                                                    style={{ marginTop: "10px" }}
                                                >
                                                    Capture
                                                </SoftButton>
                                            </Grid>
                                        )}

                                        <canvas ref={canvasRef} style={{ display: "none" }} />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <SoftTypography variant='body1' style={{ marginTop: "1rem" }}>
                                            Parent/Guardian Details
                                        </SoftTypography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="fatherName">Father Name</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="fatherName"
                                            name='fatherName'
                                            placeholder="Enter Father's Name"
                                            value={formData.fatherName}
                                            onChange={handleChange}
                                        />
                                        {errors.fatherName && <SoftTypography variant="body2" color="error">{errors.fatherName}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="motherName">Mother Name</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="motherName"
                                            name='motherName'
                                            placeholder="Enter Mother's Name"
                                            value={formData.motherName}
                                            onChange={handleChange}
                                        />
                                        {errors.motherName && <SoftTypography variant="body2" color="error">{errors.motherName}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="address">Address</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            placeholder="Enter Permanent Address"
                                            id="address"
                                            name='address'
                                            multiline
                                            rows={2}
                                            value={formData.address}
                                            onChange={handleChange}
                                        />
                                        {errors.address && <SoftTypography variant="body2" color="error">{errors.address}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="city">City</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="city"
                                            placeholder="Enter Your city name"
                                            name='city'
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                        {errors.city && <SoftTypography variant="body2" color="error">{errors.city}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="span" style={{ marginRight: 16 }}>State:</SoftTypography>
                                        <CustomSelect
                                            options={states.map(state => ({ value: state, label: state }))}
                                            placeholder="Select State"
                                            name='state'
                                            onChange={(selectedOption) => handleChange({ name: 'state', value: selectedOption.value })}
                                            value={formData.state}
                                        />
                                        {errors.state && <SoftTypography variant="body2" color="error">{errors.state}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="pincode">Pincode</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            id="pincode"
                                            name='pincode'
                                            placeholder="Enter Pincode"
                                            value={formData.pincode}
                                            onChange={handleChange}
                                        />
                                        {errors.pincode && <SoftTypography variant="body2" color="error">{errors.pincode}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="schoolOrCollegeName">School/College Name</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            placeholder="Enter Your school or collage name with Address"
                                            id="schoolOrCollegeName"
                                            name='schoolOrCollegeName'
                                            value={formData.schoolOrCollegeName}
                                            onChange={handleChange}
                                        />
                                        {errors.schoolOrCollegeName && <SoftTypography variant="body2" color="error">{errors.schoolOrCollegeName}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftTypography variant="h6" component="label" htmlFor="schoolOrCollegeName">Mentor</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            size='small'
                                            placeholder="Enter Your school or collage name with Address"
                                            id="schoolOrCollegeName"
                                            name='schoolOrCollegeName'
                                            value={formData.mentor}
                                            onChange={handleChange}
                                        />
                                        {errors.schoolOrCollegeName && <SoftTypography variant="body2" color="error">{errors.schoolOrCollegeName}</SoftTypography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SoftTypography variant='body1' style={{ marginTop: "1rem" }}>
                                            Previous grade Marks scored in Core Subject: Mathematics, Science and Social / PUC
                                        </SoftTypography>
                                        <Box display="flex" alignItems="center" flexDirection="row">
                                            <FormControl error={formData.syllabus_subjects.length === 0} component="fieldset" style={{ marginLeft: "12px", display: "flex", flexDirection: "auto" }}>

                                                <FormGroup row>
                                                    {syllabusSubjects.map((subject) => (
                                                        <FormControlLabel
                                                            key={subject}
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.syllabus_subjects.includes(subject)}
                                                                    onChange={handleSyllabus_SubjectChange}
                                                                    name={subject}
                                                                />
                                                            }
                                                            label={subject}
                                                        />
                                                    ))}
                                                </FormGroup>
                                                {formData.syllabus_subjects.length === 0 && (
                                                    <FormHelperText style={{ marginTop: 4 }}>Please select at least one option</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <SoftTypography variant="h6" component="label" htmlFor="MaxMarks">Max Marks</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            placeholder="Max marks of selected course"
                                            name='coremaxMarks'
                                            size='small'
                                            value={formData.coremaxMarks}
                                            onChange={handleChange}
                                        />
                                        {errors.coremaxMarks && <SoftTypography variant="body2" color="error">{errors.coremaxMarks}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <SoftTypography variant="h6" component="label" htmlFor="MinMarks">Min Marks</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            placeholder="Min marks of selected course"
                                            size='small'
                                            name='coreminMarks'
                                            value={formData.coreminMarks}
                                            onChange={handleChange}
                                        />
                                        {errors.coreminMarks && <SoftTypography variant="body2" color="error">{errors.coreminMarks}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <SoftTypography variant="h6" component="label" htmlFor="MarksObtained">Marks Obtained</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            placeholder="Obtained marks of selected course"
                                            size='small'
                                            name='coremarksObtained'
                                            value={formData.coremarksObtained}
                                            onChange={handleChange}
                                        />
                                        {errors.coremarksObtained && <SoftTypography variant="body2" color="error">{errors.coremarksObtained}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftTypography variant='body1' style={{ fontFamily: "Georgia, serif", marginTop: "0.5rem" }}>
                                            Previous grade Marks scored in languages: Kannada, English / Hindi
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <SoftTypography variant="h6" component="label" htmlFor="MaxMarks">Max Marks</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            placeholder="Max marks of Above Languges"
                                            required
                                            name='languagemaxMarks'
                                            value={formData.languagemaxMarks}
                                            onChange={handleChange}
                                        />
                                        {errors.languagemaxMarks && <SoftTypography variant="body2" color="error">{errors.languagemaxMarks}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <SoftTypography variant="h6" component="label" htmlFor="MinMarks">Min Marks</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            placeholder="Min marks of Above Languges"
                                            size='small'
                                            name='languageminMarks'
                                            value={formData.languageminMarks}
                                            onChange={handleChange}
                                        />
                                        {errors.languageminMarks && <SoftTypography variant="body2" color="error">{errors.languageminMarks}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <SoftTypography variant="h6" component="label" htmlFor="MarksObtained">Marks Obtained</SoftTypography>
                                        <SoftInput
                                            variant='outlined'
                                            fullWidth
                                            required
                                            placeholder="Obtained marks of Above Languges"
                                            size='small'
                                            name='languagemarksObtained'
                                            value={formData.languagemarksObtained}
                                            onChange={handleChange}
                                        />
                                        {errors.languagemarksObtained && <SoftTypography variant="body2" color="error">{errors.languagemarksObtained}</SoftTypography>}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mt={4} mb={1}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                type="submit"
                                                disabled={loading}
                                            >
                                                {loading ? "Registering..." : "Register Student"}
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>

    );
}

export default StudentRegistration;