import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Backdrop, IconButton } from '@mui/material';
import Swal from 'sweetalert2';
import { Plus, X } from 'lucide-react'; // Added for icons
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Card } from "@mui/material";
import axios from 'axios';
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import PropTypes from 'prop-types';

const DetailUpdate = ({ onSubmit, submitRef }) => {
    const [usersData, setUserData] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [orgImagePreview, setOrgImagePreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedOrgImage, setSelectedOrgImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        org_id: '',
        org_name: '',
        org_short_name: '',
        org_emp_code: '',
        org_email_id: '',
        org_mob: '',
        org_spoc_name: '',
        org_spoc_email_id: '',
        org_spoc_mob: '',
        org_status: 'ACTIVE',
        org_address: '',
        orgurl: '',
        org_account_number: '',
        orggst: '',
        orgShortAddress: '',
        orgSpocPosition: '',
        ifsc: '',
        about: [''], // Initialize with one empty string
        // org_image: null
    });

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
        fetchOrgData();
        window.scrollTo(0, 0);
    }, []);

    const fetchOrgData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
                headers: APIData.headers
            });

            if (response.data) {
                const mappedData = {
                    ...formData,
                    org_id: response.data.orgId,
                    org_name: response.data.orgName,
                    org_short_name: response.data.orgShortName,
                    org_email_id: response.data.orgEmailId,
                    org_mob: response.data.orgMob,
                    org_spoc_name: response.data.orgSpocName,
                    org_spoc_email_id: response.data.orgSpocEmailId,
                    org_spoc_mob: response.data.orgSpocMob,
                    org_status: response.data.orgStatus,
                    org_address: response.data.orgAddress,
                    orgurl: response.data.orgURL,
                    org_account_number: response.data.orgAccountNuber,
                    orggst: response.data.orgGST,
                    orgShortAddress: response.data.orgShortAddress,
                    orgSpocPosition: response.data.orgSpocPosition,
                    ifsc: response.data.ifsc,
                    about: response.data.about || [''], // Initialize with existing data or empty array
                };
                setFormData(mappedData);
                if (response.data.orgURL) {
                    setImagePreview(response.data.orgURL);
                }
                if (response.data.org_image) {
                    setOrgImagePreview(response.data.org_image);
                }
            }
        } catch (error) {
            console.error('Error fetching organization data:', error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch organization data"
            }).then(() => {
                window.location.href = '/orgmdmdata';
            });
        } finally {
            setLoading(false);
        }
    };

    // Handle about field changes
    const handleAboutChange = (index, value) => {
        const newAbout = [...formData.about];
        newAbout[index] = value;
        setFormData(prev => ({
            ...prev,
            about: newAbout
        }));
    };

    // Add new about field
    const addAboutField = () => {
        setFormData(prev => ({
            ...prev,
            about: [...prev.about, '']
        }));
    };

    // Remove about field
    const removeAboutField = (index) => {
        if (formData.about.length > 1) {
            const newAbout = formData.about.filter((_, i) => i !== index);
            setFormData(prev => ({
                ...prev,
                about: newAbout
            }));
        }
    };

    // Handle org_image file change
    const handleOrgImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedOrgImage(file);
        setOrgImagePreview(URL.createObjectURL(file));
    };

    const statusOptions = [
        { value: 'ACTIVE', label: 'ACTIVE' },
        { value: 'INACTIVE', label: 'INACTIVE' },
        { value: 'REVOKE', label: 'REVOKED' }
    ];

    const positionOptions = [
        { value: 'CEO', label: 'CEO' },
        { value: 'CFO', label: 'CFO' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Senior Manager', label: 'Senior Manager' },
        { value: 'Admin', label: 'Admin' }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;

        if ((name === 'org_mob' || name === 'org_spoc_mob') && !/^\d*$/.test(value)) {
            return;
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        const error = validateField(name, value);
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const name = actionMeta.name;
        const value = selectedOption ? selectedOption.value : '';

        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));

        const error = validateField(name, value);
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const validateField = (name, value) => {
        let error = '';

        const validations = {
            org_id: () => !value.trim() && "Organization ID is required",
            org_name: () => !value.trim() && "Organization Name is required",
            org_short_name: () => !value.trim() && "Organization Short Name is required",
            org_email_id: () => {
                if (!value.trim()) return "Organization Email is required";
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return "Invalid email format";
            },
            org_mob: () => {
                if (!value.trim()) return "Organization Mobile is required";
                if (!/^\d{10}$/.test(value)) return "Mobile number must be 10 digits";
            },
            org_spoc_name: () => !value.trim() && "SPOC Name is required",
            org_spoc_email_id: () => {
                if (!value.trim()) return "SPOC Email is required";
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return "Invalid email format";
            },
            org_spoc_mob: () => {
                if (!value.trim()) return "SPOC Mobile is required";
                if (!/^\d{10}$/.test(value)) return "Mobile number must be 10 digits";
            },
            orgSpocPosition: () => !value && "SPOC Position is required",
            org_status: () => !value && "Organization Status is required",
            org_address: () => !value.trim() && "Organization Address is required",
            orgShortAddress: () => !value.trim() && "Short Address is required",
            orgurl: () => {
                if (!value.trim()) return "Organization URL is required";
                if (!/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)) return "Invalid URL format";
            },
            org_account_number: () => {
                if (!value.trim()) return "Account Number is required";
                if (!/^\d{9,18}$/.test(value)) return "Invalid account number format";
            },
            orggst: () => !value.trim() && "Organization GST is required",
            ifsc: () => !value.trim() && "Organization IFSC Code is required",
            about: () => {
                if (!value || value.length === 0) return "At least one about organization entry is required";
                if (value.some(item => !item.trim())) return "About us entries cannot be empty";
                return '';
            },
        };

        if (validations[name]) {
            error = validations[name]();
        }

        return error;
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach(field => {
            const error = validateField(field, formData[field]);
            if (error) {
                newErrors[field] = error;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formIsValid = validateForm();
        if (!formIsValid) {
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill all required fields correctly",
                showConfirmButton: true
            });
            return;
        }

        setIsSubmitting(true);
        setLoading(true);

        try {
            const submitFormData = new FormData();
            Object.keys(formData).forEach(key => {
                if (key === 'about') {
                    submitFormData.append('about', JSON.stringify(formData.about));
                } else {
                    submitFormData.append(key, formData[key]);
                }
            });

            if (selectedFile) {
                submitFormData.append('image', selectedFile);
            }
            if (selectedOrgImage) {
                submitFormData.append('org_image', selectedOrgImage);
            }
            submitFormData.append('updated_by', usersData?.userName || '');

            const response = await axios.put(
                `${APIData.api}org-mdm/`,
                submitFormData,
                {
                    headers: { ...APIData.headers, 'Content-Type': 'multipart/form-data' }
                }
            );

            Swal.fire({
                icon: "success",
                title: "Successfully Updated",
                showConfirmButton: false,
                timer: 1500
            });
            onSubmit(true)


        } catch (error) {
            console.error('API Error:', error);
            Swal.fire({
                icon: "error",
                title: "Update Error",
                text: error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Error updating organization data"
            });
        } finally {
            setLoading(false);
            setIsSubmitting(false);
        }
    };

    return (

        <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item xs={12} sm={10}>
                <Card sx={{ height: 'auto' }}>
                    <SoftBox pt={1} pb={3} px={3}>
                        <SoftBox component="form" onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Id *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization Id"
                                            name="org_id"
                                            size="small"
                                            value={formData.org_id}
                                            onChange={handleChange}
                                            error={!!errors.org_id}
                                            required
                                            disabled
                                        />
                                        {errors.org_id && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_id}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Name *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization Name"
                                            name="org_name"
                                            size="small"
                                            value={formData.org_name}
                                            onChange={handleChange}
                                            error={!!errors.org_name}
                                            required
                                        />
                                        {errors.org_name && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_name}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Short Name *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization Short Name"
                                            name="org_short_name"
                                            size="small"
                                            value={formData.org_short_name}
                                            onChange={handleChange}
                                            error={!!errors.org_short_name}
                                            required
                                        />
                                        {errors.org_short_name && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_short_name}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Email *</SoftTypography>
                                        <SoftInput
                                            type="email"
                                            size="small"
                                            placeholder="Organization Email"
                                            name="org_email_id"
                                            value={formData.org_email_id}
                                            onChange={handleChange}
                                            error={!!errors.org_email_id}
                                            required
                                            disabled
                                        />
                                        {errors.org_email_id && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_email_id}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Mobile *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization Mobile"
                                            name="org_mob"
                                            size="small"
                                            value={formData.org_mob}
                                            onChange={handleChange}
                                            error={!!errors.org_mob}
                                            required
                                            inputProps={{
                                                maxLength: 10,
                                                pattern: "[0-9]*",
                                                inputMode: "numeric"
                                            }}
                                        />
                                        {errors.org_mob && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_mob}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">SPOC Name *</SoftTypography>
                                        <SoftInput
                                            placeholder="SPOC Name"
                                            name="org_spoc_name"
                                            size="small"
                                            value={formData.org_spoc_name}
                                            onChange={handleChange}
                                            error={!!errors.org_spoc_name}
                                            required

                                        />
                                        {errors.org_spoc_name && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_spoc_name}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">SPOC Position *</SoftTypography>
                                        <CustomSelect
                                            options={positionOptions}
                                            placeholder="SPOC Position"
                                            name="orgSpocPosition"
                                            onChange={handleSelectChange}
                                            value={formData.orgSpocPosition}
                                            error={!!errors.orgSpocPosition}
                                            required

                                        />
                                        {errors.orgSpocPosition && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.orgSpocPosition}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">SPOC Email *</SoftTypography>
                                        <SoftInput
                                            type="email"
                                            placeholder="SPOC Email"
                                            size="small"
                                            name="org_spoc_email_id"
                                            value={formData.org_spoc_email_id}
                                            onChange={handleChange}
                                            error={!!errors.org_spoc_email_id}
                                            required

                                        />
                                        {errors.org_spoc_email_id && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_spoc_email_id}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">SPOC Mobile *</SoftTypography>
                                        <SoftInput
                                            placeholder="SPOC Mobile"
                                            name="org_spoc_mob"
                                            size="small"
                                            value={formData.org_spoc_mob}
                                            onChange={handleChange}
                                            error={!!errors.org_spoc_mob}
                                            inputProps={{
                                                maxLength: 10,
                                                pattern: "[0-9]*",
                                                inputMode: "numeric"
                                            }}
                                            required
                                        />
                                        {errors.org_spoc_mob && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_spoc_mob}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Short Address *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization Short Address"
                                            name="orgShortAddress"
                                            size="small"
                                            value={formData.orgShortAddress}
                                            onChange={handleChange}
                                            error={!!errors.orgShortAddress}
                                            required
                                        />
                                        {errors.orgShortAddress && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.orgShortAddress}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Status *</SoftTypography>
                                        <CustomSelect
                                            options={statusOptions}
                                            placeholder="Organization Status"
                                            name="org_status"
                                            size="small"
                                            onChange={handleSelectChange}
                                            value={formData.org_status}
                                            error={!!errors.org_status}
                                            required
                                        />
                                        {errors.org_status && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_status}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Address *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization Address"
                                            name="org_address"
                                            size="small"
                                            value={formData.org_address}
                                            onChange={handleChange}
                                            error={!!errors.org_address}
                                            required
                                        />
                                        {errors.org_address && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_address}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization URL *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization URL"
                                            name="orgurl"
                                            size="small"
                                            value={formData.orgurl}
                                            onChange={handleChange}
                                            error={!!errors.orgurl}
                                            required
                                        />
                                        {errors.orgurl && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.orgurl}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Bank Account Number *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization Bank Account Number"
                                            name="org_account_number"
                                            size="small"
                                            value={formData.org_account_number}
                                            onChange={handleChange}
                                            error={!!errors.org_account_number}
                                            required
                                        />
                                        {errors.org_account_number && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.org_account_number}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization GST *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization GST"
                                            name="orggst"
                                            size="small"
                                            value={formData.orggst}
                                            onChange={handleChange}
                                            error={!!errors.orggst}
                                            required
                                        />
                                        {errors.orggst && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.orggst}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization IFSC Code *</SoftTypography>
                                        <SoftInput
                                            placeholder="Organization IFSC Code"
                                            name="ifsc"
                                            size="small"
                                            value={formData.ifsc}
                                            onChange={handleChange}
                                            error={!!errors.ifsc}
                                            required
                                        />
                                        {errors.ifsc && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.ifsc}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">About Organization</SoftTypography>
                                        {formData.about.map((item, index) => (
                                            <div key={index} className="flex items-center gap-2 mb-2">
                                                <SoftInput
                                                    placeholder={`About Entry ${index + 1}`}
                                                    value={item}
                                                    onChange={(e) => handleAboutChange(index, e.target.value)}
                                                    error={!!errors.about}
                                                    multiline
                                                    rows={2}
                                                    fullWidth
                                                />
                                                <IconButton
                                                    onClick={() => removeAboutField(index)}
                                                    disabled={formData.about.length === 1}
                                                >
                                                    <X size={20} />
                                                </IconButton>
                                            </div>
                                        ))}
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            onClick={addAboutField}
                                            startIcon={<Plus size={20} />}
                                        >
                                            Add More
                                        </SoftButton>
                                        {errors.about && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.about}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Organization Image</SoftTypography>
                                        <input
                                            type="file"
                                            onChange={handleOrgImageChange}
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                border: '1px solid #d2d6da',
                                                borderRadius: '8px'
                                            }}
                                        />
                                        {orgImagePreview && (
                                            <img
                                                src={orgImagePreview}
                                                alt="Organization"
                                                style={{
                                                    width: '20%',
                                                    marginTop: '10px'
                                                }}
                                            />
                                        )}
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Upload Image</SoftTypography>
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                border: '1px solid #d2d6da',
                                                borderRadius: '8px'
                                            }}
                                        />
                                        {imagePreview && (
                                            <img
                                                src={imagePreview}

                                                style={{
                                                    width: '20%',
                                                    marginTop: '10px'
                                                }}
                                            />
                                        )}
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <SoftBox mt={4} mb={1}>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        {/* <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Updating...' : 'Update'}
                                    </SoftButton> */}
                                        <button
                                            type="submit"
                                            ref={submitRef}
                                            style={{ display: 'none' }}
                                        />
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>

    );
};
// DetailUpdate.propTypes = {
//     onSubmit: PropTypes.func.isRequired, 
// };
DetailUpdate.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitRef: PropTypes.object
};

export default DetailUpdate;