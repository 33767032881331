import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import Swal from 'sweetalert2';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Card } from "@mui/material";
import { Tooltip, IconButton } from '@mui/material';
import { HelpCenterRounded, Add, Remove } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import * as AiIcons from "react-icons/ai";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';

export const EditCourses = () => {
    const { courseId } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const name = sessiondetails.userName;
    const [subjects, setSubjects] = useState(['']);

    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        admin_id: name,
        course_description: "",
        course_duration: "",
        course_fees: "",
        course_id: "",
        courseName: "",
        course_sub_type: "",
        course_type: "",
        image_url: "",
        org: org,
        syallabus: "",
        gradeSubjects: [],
        createdBy: "",
        created_date_time: "",
        updatedBy: name,
        updated_date_time: new Date()
    });
    const [selectedImage, setSelectedImage] = useState(null);

    const courseTypeOptions = [
        { value: 'PROFESSIONAL', label: 'PROFESSIONAL' },
        { value: 'ACADEMICS', label: 'ACADEMICS' }
    ];

    const courseSubTypeOptions = [
        { value: 'STATE', label: 'STATE' },
        { value: 'ICSE', label: 'ICSE' },
        { value: 'CBSE', label: 'CBSE' },
        { value: 'PROFESSIONAL', label: 'PROFESSIONAL' }
    ];

    // State for select values
    const [selectedCourseType, setSelectedCourseType] = useState(null);
    const [selectedCourseSubType, setSelectedCourseSubType] = useState(null);

    // Handle subject field changes
    const handleSubjectChange = (index, value) => {
        const newSubjects = [...subjects];
        newSubjects[index] = value;
        setSubjects(newSubjects);
        
        setFormData(prev => ({
            ...prev,
            gradeSubjects: newSubjects.filter(subject => subject.trim() !== '')
        }));

        if (value.trim() !== '') {
            setErrors(prev => ({ ...prev, gradeSubjects: '' }));
        }
    };

    const addSubjectField = () => {
        setSubjects([...subjects, '']);
    };

    const removeSubjectField = (index) => {
        if (subjects.length > 1) {
            const newSubjects = subjects.filter((_, i) => i !== index);
            setSubjects(newSubjects);
            setFormData(prev => ({
                ...prev,
                gradeSubjects: newSubjects.filter(subject => subject.trim() !== '')
            }));
        }
    };

    // Fetch existing course data
    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const url = `${APIData.api}courses/${courseId}`;
                const response = await axios.get(url, { headers: APIData.headers });
                const courseData = response.data;

                setFormData(prev => ({
                    ...courseData,
                    updatedBy: name,
                    updated_date_time: new Date().toISOString()
                }));

                // Set initial values for select fields
                setSelectedCourseType(courseTypeOptions.find(
                    option => option.value === courseData.course_type
                ));
                setSelectedCourseSubType(courseSubTypeOptions.find(
                    option => option.value === courseData.course_sub_type
                ));

                // Initialize subjects if they exist
                if (courseData.gradeSubjects && courseData.gradeSubjects.length > 0) {
                    setSubjects(courseData.gradeSubjects);
                }

                if (courseData.image_url) {
                    setSelectedImage(courseData.image_url);
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch course details"
                });
                navigate("/courses-details");
            } finally {
                setLoading(false);
            }
        };

        fetchCourseData();
    }, [courseId, name, navigate]);

    const getErrorHelperText = (fieldName) => {
        return errors[fieldName] ? (
            <SoftTypography variant="caption" color="error" style={{ marginTop: '4px', display: 'block' }}>
                {errors[fieldName]}
            </SoftTypography>
        ) : null;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const name = actionMeta.name;
        const value = selectedOption ? selectedOption.value : '';

        if (name === 'course_type') {
            setSelectedCourseType(selectedOption);
            // Clear gradeSubjects when switching away from ACADEMICS
            if (value !== 'ACADEMICS') {
                setSubjects(['']);
                setFormData(prev => ({
                    ...prev,
                    [name]: value,
                    gradeSubjects: []
                }));
            } else {
                setFormData(prev => ({
                    ...prev,
                    [name]: value
                }));
            }
        } else if (name === 'course_sub_type') {
            setSelectedCourseSubType(selectedOption);
            setFormData(prev => ({
                ...prev,
                [name]: value,
            }));
        }

        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };
    // State for select values

    // Fetch existing course data
    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const url = `${APIData.api}courses/${courseId}`;
                const response = await axios.get(url, { headers: APIData.headers });
                const courseData = response.data;

                setFormData(prev => ({
                    ...courseData,
                    updatedBy: name,
                    updated_date_time: new Date().toISOString()
                }));

                // Set initial values for select fields
                setSelectedCourseType(courseTypeOptions.find(
                    option => option.value === courseData.course_type
                ));
                setSelectedCourseSubType(courseSubTypeOptions.find(
                    option => option.value === courseData.course_sub_type
                ));

                if (courseData.image_url) {
                    setSelectedImage(courseData.image_url);
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch course details"
                });
                navigate("/courses-details");
            } finally {
                setLoading(false);
            }
        };

        fetchCourseData();
    }, [courseId, name, navigate]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                setErrors(prev => ({
                    ...prev,
                    image_url: "File too large. Max file size is 1MB."
                }));
                setSelectedImage(null);
                return;
            }
    
            // Set the selected image for preview (optional)
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // This is for preview only
            };
            reader.readAsDataURL(file);
    
            // Append the file directly to the form data
            setFormData(prev => ({
                ...prev,
                image_url: file // Save the file object directly
            }));
    
            // Clear any existing errors
            setErrors(prev => ({ ...prev, image_url: '' }));
        }
    };
    const validateForm = () => {
        const newErrors = {};
    
        if (!formData.courseName || !formData.courseName.trim()) {
            newErrors.courseName = "Course name is required";
        } 
        if (!formData.course_description || !formData.course_description.trim()) {
            newErrors.course_description = "Course description is required";
        } else if (formData.course_description.trim().length < 5) {
            newErrors.course_description = "Description must be at least 10 characters";
        }
        if (!formData.course_duration || !formData.course_duration.trim()) {
            newErrors.course_duration = "Course duration is required";
        } else {
            const durationPattern = /^.*?(month|year)s?$/i;
            if (!durationPattern.test(formData.course_duration)) {
                newErrors.course_duration = "Duration must include 'months' or 'years'";
            }
        }
        if (!formData.course_fees) {
            newErrors.course_fees = "Course fees is required";
        } else {
            const fees = Number(formData.course_fees);
            if (isNaN(fees) || fees < 0) {
                newErrors.course_fees = "Course fees must be a positive number";
            }
        }
        if (!formData.course_type) {
            newErrors.course_type = "Course type is required";
        }
        if (!formData.course_sub_type) {
            newErrors.course_sub_type = "Course sub type is required";
        }
        if (!formData.syallabus || !formData.syallabus.trim()) {
            newErrors.syallabus = "Syllabus is required";
        } else if (!formData.syallabus.includes(',')) {
            newErrors.syallabus = "Enter multiple topics separated by commas";
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill in all required fields correctly",
            });
            return;
        }
    
        setLoading(true);
    
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('image', formData.image_url); 
            formDataToSend.append('course_id', formData.course_id);
            formDataToSend.append('course_type', formData.course_type);
            formDataToSend.append('grade_subjects', formData.gradeSubjects);
            formDataToSend.append('course_sub_type', formData.course_sub_type);
            formDataToSend.append('course_fees', formData.course_fees);
            formDataToSend.append('course_duration', formData.course_duration);
            formDataToSend.append('admin_id', formData.admin_id);
            formDataToSend.append('course_description', formData.course_description);
            formDataToSend.append('org', formData.org);
            formDataToSend.append('updated_date_time', formData.updated_date_time);
            formDataToSend.append('updatedBy', formData.updatedBy);
            formDataToSend.append('syllabus', formData.syallabus);
    
            for (const [key, value] of formDataToSend.entries()) {
                console.log(`${key}: ${value instanceof File ? value.name : value}`);
            }
            const url = `${APIData.api}courses/`;
            const headers = {
                ...APIData.headers,
                'Content-Type': 'multipart/form-data'
            };
    
            const response = await axios.put(url, formDataToSend, { headers });
            Swal.fire({
                icon: "success",
                title: "Successfully Updated",
                showConfirmButton: false,
                timer: 1500
            });

            navigate("/courses-details");

        } catch (error) {
            console.error("Update error:", error);
            Swal.fire({
                icon: "error",
                title: "Update Error",
                text: error.response?.data?.message || "Unable to update the course details"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/courses-details">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Edit Course
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Course Name *
                                        <Tooltip title="Students below 12th class, Enter in given format = Grade-01 to Grade-12">
                                            <IconButton edge="end" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Course Name"
                                        name="courseName"
                                        value={formData.courseName}
                                        onChange={handleChange}
                                        error={!!errors.courseName}
                                        
                                    />
                                    {getErrorHelperText('courseName')}
                                </SoftBox>

                                {formData.course_type === 'ACADEMICS' && (
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">
                                            Subjects
                                            <Tooltip title="Add subjects for this grade">
                                                <IconButton edge="start" size="small">
                                                    <HelpCenterRounded />
                                                </IconButton>
                                            </Tooltip>
                                        </SoftTypography>
                                        {subjects.map((subject, index) => (
                                            <SoftBox key={index} mb={1} display="flex" alignItems="center" gap={1}>
                                                <SoftInput
                                                    fullWidth
                                                    placeholder={`Enter subject ${index + 1}`}
                                                    value={subject}
                                                    onChange={(e) => handleSubjectChange(index, e.target.value)}
                                                    error={!!errors.gradeSubjects}
                                                />
                                                <IconButton 
                                                    onClick={() => removeSubjectField(index)}
                                                    disabled={subjects.length === 1}
                                                >
                                                    <Remove />
                                                </IconButton>
                                                {index === subjects.length - 1 && (
                                                    <IconButton onClick={addSubjectField}>
                                                        <Add />
                                                    </IconButton>
                                                )}
                                            </SoftBox>
                                        ))}
                                        {getErrorHelperText('gradeSubjects')}
                                    </SoftBox>
                                )}

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Course Description
                                        <Tooltip title="Course Name or description about Course">
                                            <IconButton edge="end" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="course_description"
                                        placeholder="Enter Course Description"
                                        value={formData.course_description}
                                        onChange={handleChange}
                                        error={!!errors.course_description}
                                    />
                                    {getErrorHelperText('course_description')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Course Duration
                                        <Tooltip title="Duration of Courses">
                                            <IconButton edge="start" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="course_duration"
                                        placeholder="Example: 3 months or 1 Year"
                                        value={formData.course_duration}
                                        onChange={handleChange}
                                        error={!!errors.course_duration}
                                    />
                                    {getErrorHelperText('course_duration')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Fees</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="course_fees"
                                        placeholder="Enter Course Fees"
                                        value={formData.course_fees}
                                        onChange={handleChange}
                                        type="number"
                                        error={!!errors.course_fees}
                                    />
                                    {getErrorHelperText('course_fees')}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Type</SoftTypography>
                                    <CustomSelect
                                        options={courseTypeOptions}
                                        placeholder="Select Course Type"
                                        name="course_type"
                                        onChange={handleSelectChange}
                                        value={selectedCourseType}
                                        error={!!errors.course_type}
                                    />
                                    {getErrorHelperText('course_type')}
                                </SoftBox>

                                {/* Update the Course Sub Type select */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Sub Type</SoftTypography>
                                    <CustomSelect
                                        options={courseSubTypeOptions}
                                        placeholder="Select Course Sub Type"
                                        name="course_sub_type"
                                        onChange={handleSelectChange}
                                        value={selectedCourseSubType}
                                        error={!!errors.course_sub_type}
                                    />
                                    {getErrorHelperText('course_sub_type')}
                                </SoftBox>


                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Syllabus
                                        <Tooltip title="Add syllabus Topics with comma separated">
                                            <IconButton edge="start" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="syallabus"
                                        placeholder="Enter Syllabus Topics (comma separated)"
                                        value={formData.syallabus}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        error={!!errors.syallabus}
                                    />
                                    {getErrorHelperText('syallabus')}
                                </SoftBox>

                                <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="upload-image"
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="upload-image">
                                        <SoftButton variant="contained" component="span">
                                            Upload New Image
                                        </SoftButton>
                                    </label>
                                    {selectedImage && (
                                        <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                            <img
                                                src={selectedImage}
                                                alt="Selected Preview"
                                                style={{ width: '200px', height: 'auto', objectFit: 'contain' }}
                                            />
                                        </SoftBox>
                                    )}
                                    {getErrorHelperText('image_url')}
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Update Course
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default EditCourses;
