// import React, { useEffect, useState } from 'react';
// import { APIData, org } from 'authentication/APIData';
// import axios from 'axios';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';

// import { Container, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
// import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
// import Footer from 'examples/Footer';

// const Members = () => {
//     const [data, setData] = useState([]);
//     const [statusFilter, setStatusFilter] = useState("ALL");

//     const handleStatusChange = (event, newStatus) => {
//         if (newStatus) {
//             setStatusFilter(newStatus);
//         }
//     };

//     const fetchData = (filter) => {
//         const url = filter === 'ALL' 
//             ? `employee/details?org=${org}` 
//             : `employee/empdetailsByDepartment?department=${filter}&org=${org}`;
//         axios
//             .get(APIData.api + url, { headers: APIData.headers })
//             .then((resp) => {
//                 setData(resp.data);
//             })
//             .catch((error) => {
//                 console.error('Error fetching data:', error);
//             });
//     };

//     useEffect(() => {
//         fetchData(statusFilter);
//     }, [statusFilter]);

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <SoftBox pt={2} px={2}>
//                 <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                     <SoftTypography variant="h5">Members</SoftTypography>
//                 </SoftBox>
//             </SoftBox>
//             <Container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
//                 <ToggleButtonGroup value={statusFilter} exclusive onChange={handleStatusChange}>
//                     <ToggleButton value="ALL" sx={{ border: '1px solid #007a99' }}>
//                         ALL
//                     </ToggleButton>
//                     <ToggleButton value="HR" sx={{ border: '1px solid #007a99' }}>
//                         HR
//                     </ToggleButton>
//                     <ToggleButton value="TECHNICAL" sx={{ border: '1px solid #007a99' }}>
//                         TECHNICAL
//                     </ToggleButton>
//                     <ToggleButton value="FINANCE" sx={{ border: '1px solid #007a99' }}>
//                         FINANCE
//                     </ToggleButton>
//                     <ToggleButton value="SALES" sx={{ border: '1px solid #007a99' }}>
//                         SALES
//                     </ToggleButton>
//                     <ToggleButton value="ACADEMY" sx={{ border: '1px solid #007a99' }}>
//                         ACADEMY
//                     </ToggleButton>
//                 </ToggleButtonGroup>
//             </Container>
//             <SoftBox mt={5} mb={3}>
//                 {data.length === 0 ? (
//                     <Typography variant="h6" align="center">
//                         No members found for this department. Please select another department or &quot;ALL&quot; to view all members.
//                     </Typography>
//                 ) : (
//                     <Grid container spacing={3}>
//                         {data.map((employee) => (
//                             <Grid item xs={12} md={6} xl={4} key={employee.id}>
//                                 <ProfileInfoCard
//                                     info={{
//                                         fullName: `${employee.name}`,
//                                         mobile: `${employee.phone_number}`,
//                                         email: `${employee.email}`,
//                                         location: `${employee.address}`,
//                                         DOB: `${employee.dob}`,
//                                         joinedDate: `${employee.year_of_appointment}`,
//                                         Qualification: `${employee.qualification}`,
//                                         gender: `${employee.gender}`,
//                                         WorkType: `${employee.workType}`,
//                                     }}
//                                     action={{}}
//                                     showAction={false}
//                                 />
//                             </Grid>
//                         ))}
//                     </Grid>
//                 )}
//             </SoftBox>
//             <Footer/>
//         </DashboardLayout>
//     );
// };

// export default Members;


import React, { useEffect, useState } from 'react';
import { APIData, org } from 'authentication/APIData';
import axios from 'axios';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    Switch,
    Box,
    Chip,
    CircularProgress
} from '@mui/material';
import Footer from 'examples/Footer';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Swal from 'sweetalert2';
import SoftAvatar from 'components/SoftAvatar';

const Members = () => {
    const [data, setData] = useState([]);
    const [statusFilter, setStatusFilter] = useState("ALL");
    const [loadingEmployees, setLoadingEmployees] = useState({});
    const [visiblePhones, setVisiblePhones] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);


    const togglePhoneVisibility = (employeeId) => {
        setVisiblePhones((prev) => ({
            ...prev,
            [employeeId]: !prev[employeeId],
        }));
    };

    const handleStatusChange = (event, newStatus) => {
        if (newStatus) {
            setStatusFilter(newStatus);
        }
    };

    const fetchData = (filter) => {
        const url = filter === 'ALL'
            ? `employee/details?org=${org}`
            : `employee/empdetailsByDepartment?department=${filter}&org=${org}`;
        axios
            .get(APIData.api + url, { headers: APIData.headers })
            .then((resp) => {
                setData(resp.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const updateWorkType = (employeeId, workType) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to change the work type to ${workType} for employee ID: ${employeeId}.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoadingEmployees((prev) => ({ ...prev, [employeeId]: true }));
                const url = `${APIData.api}employee/${employeeId}/worktype`;

                axios
                    .put(url, null, {
                        params: {
                            workType: workType,
                        },
                        headers: {
                            ...APIData.headers,
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    })
                    .then((response) => {
                        setData((prevData) =>
                            prevData.map((employee) =>
                                employee.employee_id === employeeId
                                    ? { ...employee, workType: workType }
                                    : employee
                            )
                        );
                        Swal.fire({
                            title: 'Success!',
                            text: 'Work type has been updated successfully.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        });
                    })
                    .catch((error) => {
                        console.error('Error updating workType:', error);
                        Swal.fire({
                            title: 'Error!',
                            text: `Failed to update work type: ${error.message}`,
                            icon: 'error',
                            confirmButtonText: 'OK',
                        });
                    })
                    .finally(() => {
                        setLoadingEmployees((prev) => {
                            const newState = { ...prev };
                            delete newState[employeeId];
                            return newState;
                        });
                    });
            }
        });
    };

    const handleWorkTypeToggle = (employee) => {
        const newWorkType = employee.workType === 'WORK_FROM_OFFICE'
            ? 'WORK_FROM_HOME'
            : 'WORK_FROM_OFFICE';
        updateWorkType(employee.employee_id, newWorkType);
    };

    useEffect(() => {
        fetchData(statusFilter);
    }, [statusFilter]);

    const generateAvatarStyle = (name) => {
        const colors = [
            { bg: '#007a99', text: '#ffffff' },
            { bg: '#5a67d8', text: '#ffffff' },
            { bg: '#38a169', text: '#ffffff' },
            { bg: '#d53f8c', text: '#ffffff' },
            { bg: '#e53e3e', text: '#ffffff' }
        ];
        return colors[name.charCodeAt(0) % colors.length];
    };

    const departmentColors = {
        'HR': 'primary',
        'TECHNICAL': 'secondary',
        'FINANCE': 'success',
        'SALES': 'warning',
        'ACADEMY': 'info'
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3} px={3}>
                <SoftBox mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SoftTypography variant="h3" fontWeight="bold">Team Members</SoftTypography>
                </SoftBox>
            </SoftBox>
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1.5rem',
                '& .MuiToggleButtonGroup-root': {
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    borderRadius: '12px',
                }
            }}>
                <ToggleButtonGroup
                    value={statusFilter}
                    exclusive
                    onChange={handleStatusChange}
                    sx={{
                        '& .MuiToggleButton-root': {
                            textTransform: 'none',
                            margin: '0 5px',
                            borderRadius: '8px',
                            fontWeight: 600,
                            '&.Mui-selected': {
                                backgroundColor: 'info',
                                color: 'white',
                            }
                        }
                    }}
                >
                    {['ALL', 'HR', 'TECHNICAL', 'FINANCE', 'SALES', 'ACADEMY'].map((dept) => (
                        <ToggleButton key={dept} value={dept} sx={{ border: '1px solid #007a99' }}>
                            {dept}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Container>
            <SoftBox mt={4} mb={3} px={3}>
                {data.length === 0 ? (
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{ color: 'text.secondary', fontWeight: 500 }}
                    >
                        No members found for this department. Please select another department or &quot;ALL&quot; to view all members.
                    </Typography>
                ) : (
                    <Grid container spacing={4}>
                        {data.map((employee) => {
                            const avatarStyle = generateAvatarStyle(employee.name);
                            return (
                                <Grid item xs={12} sm={6} md={4} key={employee.employee_id}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            transition: 'transform 0.3s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.03)',
                                                boxShadow: '0 8px 15px rgba(0,0,0,0.1)'
                                            }
                                        }}
                                    >
                                        <CardContent sx={{ position: 'relative', pb: 3 }}>
                                            <SoftBox display="flex" alignItems="center" mt={1} mb={2}>
                                                <SoftAvatar
                                                    src={employee.photoUrl || ''} 
                                                    style={{
                                                        backgroundColor: employee.image ? undefined : avatarStyle.bg,
                                                        color: employee.image ? undefined : avatarStyle.text,
                                                        width: 60,
                                                        height: 60,
                                                        fontSize: '2rem',
                                                        fontWeight: 600,
                                                        ...((!employee.image) && {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        })
                                                    }}
                                                >
                                                    {!employee.image && employee.name.charAt(0).toUpperCase()}
                                                </SoftAvatar>
                                                <SoftBox ml={2}>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'text.primary'
                                                        }}
                                                    >
                                                        {employee.name}
                                                    </Typography>
                                                </SoftBox>
                                            </SoftBox>

                                            <Box sx={{
                                                backgroundColor: '#f4f7fe',
                                                borderRadius: 2,
                                                p: 2,
                                                mb: 2
                                            }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Email:</strong> {employee.email}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Phone:</strong>
                                                    {visiblePhones[employee.employee_id]
                                                        ? ` ${employee.phone_number}`
                                                        : ` *****${employee.phone_number.slice(-4)}`
                                                    }
                                                    {(permission === "1111" || isSuperAdmin) ? <Box
                                                        component="span"
                                                        onClick={() => togglePhoneVisibility(employee.employee_id)}
                                                        sx={{ ml: 1, cursor: 'pointer', verticalAlign: 'middle' }}
                                                    >
                                                        {visiblePhones[employee.employee_id]
                                                            ? <VisibilityOff fontSize="small" />
                                                            : <Visibility fontSize="small" />
                                                        }
                                                    </Box> : ""}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Gender:</strong> {employee.gender}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Qualification:</strong> {employee.qualification}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Joined Date:</strong> {employee.year_of_appointment}
                                                </Typography>

                                            </Box>

                                            {(permission === "1111" || isSuperAdmin) ? <Box mt={2} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: employee.workType === 'WORK_FROM_HOME' ? 'info' : 'text.secondary'
                                                    }}
                                                >
                                                    Work From Home
                                                </Typography>
                                                <Box sx={{ position: 'relative' }}>
                                                    <Switch
                                                        checked={employee.workType === 'WORK_FROM_HOME'}
                                                        onChange={() => handleWorkTypeToggle(employee)}
                                                        disabled={loadingEmployees[employee.employee_id]}
                                                        inputProps={{ 'aria-label': 'Toggle Work Type' }}
                                                    />
                                                    {loadingEmployees[employee.employee_id] && (
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                marginTop: '-12px',
                                                                marginLeft: '-12px',
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box> : ""}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default Members;