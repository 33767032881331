import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Card from "@mui/material/Card";
import {
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Divider
} from '@mui/material';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import SoftButton from 'components/SoftButton';
import Swal from 'sweetalert2';

import { APIData, org } from 'authentication/APIData';
import { Link } from 'react-router-dom';

// Truncate description helper function
const truncateDescription = (description, maxWords = 4) => {
    const words = description.split(' ');
    return words.length > maxWords
        ? words.slice(0, maxWords).join(' ') + '...'
        : description;
};

const InfraImage = ({ image, infraName, onClick }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={image}
                alt={infraName}
                size="sm"
                variant="rounded"
                style={{ cursor: 'pointer' }}
                onClick={onClick}
            />
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium">
            {infraName}
        </SoftTypography>
    </SoftBox>
);

InfraImage.propTypes = {
    image: PropTypes.string.isRequired,
    infraName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const Infra = () => {
    const [infraData, setInfraData] = useState([]);
    const [openImagePreview, setOpenImagePreview] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedInfra, setSelectedInfra] = useState(null);
    const [editInfra, setEditInfra] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    // Fetch Infra Data
    useEffect(() => {
        const fetchInfraData = async () => {
            const url = `${APIData.api}infra/?org=${org}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                setInfraData(data);
            } catch (error) {
                console.error('Error fetching infra data:', error);
            }
        };

        fetchInfraData();
    }, []);

    // Handle View Details
    const handleViewDetails = async (infraId) => {
        try {
            const url = `${APIData.api}infra/id?id=${infraId}`;
            const response = await fetch(url, { headers: APIData.headers });
            const infraDetails = await response.json();
            setSelectedInfra(infraDetails);
            setOpenDetailsDialog(true);
        } catch (error) {
            console.error('Error fetching infra details:', error);
            Swal.fire('Error', 'Failed to fetch infrastructure details', 'error');
        }
    };

    // Handle Edit 
    const handleEdit = async (infraId) => {
        try {
            const url = `${APIData.api}infra/id?id=${infraId}`;
            const response = await fetch(url, { headers: APIData.headers });
            const infraDetails = await response.json();
            setEditInfra(infraDetails);
            setOpenEditDialog(true);
        } catch (error) {
            console.error('Error fetching infra details:', error);
            Swal.fire('Error', 'Failed to fetch infrastructure details', 'error');
        }
    };

    // Handle Edit Input Change
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditInfra(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle Save Edit
    const handleSaveEdit = async () => {
        try {
            const url = `${APIData.api}infra/?id=${editInfra.id}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    ...APIData.headers,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editInfra)
            });

            if (response.ok) {
                // Update the local state
                setInfraData(prev =>
                    prev.map(infra =>
                        infra.id === editInfra.id ? editInfra : infra
                    )
                );

                Swal.fire('Success', 'Infrastructure updated successfully', 'success');
                setOpenEditDialog(false);
            } else {
                Swal.fire('Error', 'Failed to update infrastructure', 'error');
            }
        } catch (error) {
            console.error('Error updating infra:', error);
            Swal.fire('Error', 'Failed to update infrastructure', 'error');
        }
    };

    // Image Click Handler
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenImagePreview(true);
    };

    // Handle Delete
    const handleDelete = (infraId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this infrastructure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const deleteUrl = `${APIData.api}infra/?id=${infraId}`;
                fetch(deleteUrl, { method: 'DELETE', headers: APIData.headers })
                    .then(response => {
                        if (response.ok) {
                            Swal.fire('Deleted!', 'Your infrastructure has been deleted.', 'success');
                            setInfraData(infraData.filter(infra => infra.id !== infraId));
                        } else {
                            Swal.fire('Failed!', 'There was an error deleting the infrastructure.', 'error');
                        }
                    })
                    .catch((error) => {
                        console.error('Error deleting infrastructure:', error);
                        Swal.fire('Error!', 'There was an error deleting the infrastructure.', 'error');
                    });
            }
        });
    };

    // Columns for the table
    const columns = [
        { name: "Infrastructure Name", align: "left" },
        { name: "Type", align: "left" },
        { name: "Description", align: "left" },
        { name: "Action", align: "center" },
    ];

    // Filtering and Pagination
    const filteredInfra = infraData.filter(infra =>
        infra.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        infra.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        infra.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedInfra = filteredInfra.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    // Rows for the table
    const rows = paginatedInfra.map((infra) => ({
        "Infrastructure Name": (
            <InfraImage
                image={infra.image_url}
                infraName={infra.name}
                onClick={() => handleImageClick(infra.image_url)}
            />
        ),
        Type: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {infra.type}
            </SoftTypography>
        ),
        Description: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {truncateDescription(infra.description)}
            </SoftTypography>
        ),
        Action: (
            <SoftBox display="flex" justifyContent="center">
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    mr={2}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewDetails(infra.id)}
                >
                    View
                </SoftTypography>

                {(permission==="1110" || permission==="1111" || isSuperAdmin) ?<SoftTypography
                    component="span"
                    variant="button"
                    color="warning"
                    fontWeight="medium"
                    mr={2}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(infra.id)}
                >
                    Edit
                </SoftTypography> :""}

                {(permission==="1111" || isSuperAdmin) ?<SoftTypography

                    component="span"
                    variant="button"
                    color="error"
                    fontWeight="medium"
                    onClick={() => handleDelete(infra.id)}
                    style={{ cursor: 'pointer' }}
                >
                    Delete
                </SoftTypography> : "" }

            </SoftBox>
        ),
    }));

    // Edit Dialog
    const EditInfraDialog = () => (
        <Dialog
            open={openEditDialog}
            onClose={() => setOpenEditDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <SoftTypography variant="h6">Edit Infrastructure</SoftTypography>
            </DialogTitle>
            <DialogContent>
                {editInfra && (
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} mb={2}>
                            <SoftBox display="flex" justifyContent="center">
                                <img
                                    src={editInfra.image_url}
                                    alt={editInfra.name}
                                    style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                                />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <SoftInput
                                fullWidth
                                label="Name"
                                name="name"
                                value={editInfra.name}
                                onChange={handleEditInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SoftInput
                                fullWidth
                                label="Type"
                                name="type"
                                value={editInfra.type}
                                onChange={handleEditInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SoftInput
                                fullWidth
                                multiline
                                rows={4}
                                label="Description"
                                name="description"
                                value={editInfra.description}
                                onChange={handleEditInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SoftInput
                                fullWidth
                                label="Image URL"
                                name="image_url"
                                value={editInfra.image_url}
                                onChange={handleEditInputChange}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={() => setOpenEditDialog(false)} color="secondary">
                    Cancel
                </SoftButton>
                <SoftButton onClick={handleSaveEdit} color="primary">
                    Save Changes
                </SoftButton>
            </DialogActions>
        </Dialog>
    );

    const InfraDetailsDialog = () => (
        <Dialog
            open={openDetailsDialog}
            onClose={() => setOpenDetailsDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <SoftTypography variant="h6">Infrastructure Details</SoftTypography>
            </DialogTitle>
            <DialogContent>
                {selectedInfra && (
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} mb={2}>
                            <SoftBox display="flex" justifyContent="center">
                                <img
                                    src={selectedInfra.image_url}
                                    alt={selectedInfra.name}
                                    style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                                />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Name</SoftTypography>
                            <SoftTypography variant="body2">{selectedInfra.name}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Type</SoftTypography>
                            <SoftTypography variant="body2">{selectedInfra.type}</SoftTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <SoftTypography variant="subtitle2" color="text">Description</SoftTypography>
                            <SoftTypography variant="body2">{selectedInfra.description}</SoftTypography>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={() => setOpenDetailsDialog(false)} color="secondary">
                    Close
                </SoftButton>
            </DialogActions>
        </Dialog>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Infrastructure List</SoftTypography>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        size="small"
                                    />
                                </SoftBox>
                                <Tooltip title="Add New Infrastructure" arrow>
                                    <SoftBox display="flex" gap={2}>
                                        <Link to="/infraForm" style={{ textDecoration: 'none' }}>
                                            <SoftButton variant="gradient" color="info" size="small" startIcon={<AddIcon />}>
                                                ADD
                                            </SoftButton>
                                        </Link>
                                    </SoftBox>
                                </Tooltip>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}>
                            <Table columns={columns} rows={rows} />
                            <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                p={3}
                            >
                                <SoftBox display="flex" gap={1} alignItems="center">
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredInfra.length)} of {filteredInfra.length}
                                    </SoftTypography>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                        disabled={page === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowLeft />
                                    </SoftButton>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.min(Math.ceil(filteredInfra.length / itemsPerPage) - 1, prev + 1))}
                                        disabled={page >= Math.ceil(filteredInfra.length / itemsPerPage) - 1}
                                        iconOnly
                                    >
                                        <KeyboardArrowRight />
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />

            {/* Image Preview Dialog */}
            <Dialog
                open={openImagePreview}
                onClose={() => setOpenImagePreview(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <img
                        src={selectedImage}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </DialogContent>
            </Dialog>

            <InfraDetailsDialog />
            <EditInfraDialog />
        </DashboardLayout>
    );
};

export default Infra;