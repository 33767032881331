import React, { useEffect, useRef, useState } from 'react';
import {
    Button, Grid, Typography, Container, Paper, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import axios from 'axios';
import { Close as CloseIcon, Download as DownloadIcon } from '@mui/icons-material';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import EmpExit from './EmpExit';
import SoftInput from "components/SoftInput";
import SoftTypography from 'components/SoftTypography';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const containerStyle = {
    color: '#333',
    maxWidth: '700px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#fff',
};

const headerStyle = {
    textAlign: 'left',
    marginBottom: '20px',
};

const logoStyle = {
    width: '80px',
    height: '80px',
    marginLeft: '20px',
};

const headerContentStyle = {
    marginLeft: '0px',
};

const titleTextStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const paragraphStyle = {
    margin: '5px 0',
};

const paragraphStyle1 = {
    margin: '5px 0',
    marginBottom: '20px',
};

const contentStyle = {
    lineHeight: '1.6',
    marginBottom: '20px',
};

const nocTitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const signatureStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
};

const signatureBlockStyle = {
    textAlign: 'center',
};

const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const subtitleStyle = {
    fontSize: '16px',
    margin: '3px 0',
};

const sectionTitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '20px 0 10px 0',
};

const textStyle = {
    margin: '10px 0',
};

const signOffStyle = {
    fontWeight: 'bold',
};

const initialState = {

    header1: "",
    header10: "",
    header11: "",
    header12: "",
    header13: "",
    header14: "",
    header15: '',
    header16: "",
    header17: "",
    header18: "",
    header19: "",
    header2: "",
    header20: "",
    header21: "",
    header3: "",
    header4: "",
    header5: "",
    header6: "",
    header7: "",
    header8: "",
    header9: "",
    id: 0,
    org: org,
    para1: "",
    para10: "",
    para11: "",
    para12: "",
    para13: "",
    para14: "",
    para15: "",
    para16: "",
    para17: "",
    para18: "",
    para19: "",
    para2: "",
    para20: "",
    para21: "",
    para22: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    temp_name: "EMPEXIT",
    title: "Exit Appraisal Form"

}

function EmpExitedit() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const templateRef = useRef(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
    }, []);


    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };
    useEffect(() => {
        fetchOrgDetails();
    },);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });

            })
        console.log('Form Data Submitted:', formData);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDownloadPDF = () => {
        if (!templateRef.current) {
            Swal.fire({
                icon: 'error',
                title: 'PDF Generation Error',
                text: 'Template content not found.'
            });
            return;
        }
    
        html2canvas(templateRef.current, {
            scale: 2,
            useCORS: true,
            logging: false,
            scrollX: 0,
            scrollY: -window.scrollY,
            width: templateRef.current.scrollWidth,
            height: templateRef.current.scrollHeight
        }).then(canvas => {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = pageWidth - 20;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const totalPages = Math.ceil(imgHeight / pageHeight);

            for (let i = 0; i < totalPages; i++) {
                if (i > 0) {
                    pdf.addPage();
                }

                const yOffset = -i * pageHeight;

                pdf.addImage(
                    imgData, 
                    'PNG', 
                    10, 
                    10 + yOffset, 
                    imgWidth, 
                    imgHeight
                );
            }

            const filename = `NOC_Template_${formData.title || 'Untitled'}_${new Date().toISOString().split('T')[0]}.pdf`;

            pdf.save(filename);
        }).catch(error => {
            console.error('Error generating PDF:', error);
            Swal.fire({
                icon: 'error',
                title: 'PDF Generation Error',
                text: 'Could not generate PDF. Please try again.'
            });
        });
    };

    return (
        <Container>
            <PageLayout>
                <SoftButton onClick={handleBack}>
                    Back
                </SoftButton>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" gutterBottom>
                            Emp Exit Template Form
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header1</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header1'}
                                        value={formData.header1}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"

                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header2</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header2'}
                                        value={formData.header2}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"

                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header3</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header3'}
                                        value={formData.header3}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"

                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header4</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header4'}
                                        value={formData.header4}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"

                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header5</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header5'}
                                        value={formData.header5}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"

                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para1</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para1'}
                                        value={formData.para1}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"


                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para2</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para2'}
                                        value={formData.para2}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"

                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header6</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header6'}
                                        value={formData.header6}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"

                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header7</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header7'}
                                        value={formData.header7}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header8</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header8'}
                                        value={formData.header8}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header9</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header9'}
                                        value={formData.header9}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header10</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header10'}
                                        value={formData.header10}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header11</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header11'}
                                        value={formData.header11}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header12</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header12'}
                                        value={formData.header12}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header13</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header13'}
                                        value={formData.header13}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para3</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para3'}
                                        value={formData.para3}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para4</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para4'}
                                        value={formData.para4}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para5</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para5'}
                                        value={formData.para5}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para6</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para6'}
                                        value={formData.para6}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para7</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para7'}
                                        value={formData.para7}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para8</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para8'}
                                        value={formData.para8}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para9</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para9'}
                                        value={formData.para9}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para10</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para10'}
                                        value={formData.para10}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para11</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para11'}
                                        value={formData.para11}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para12</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para12'}
                                        value={formData.para12}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para13</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para13'}
                                        value={formData.para13}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para14</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para14'}
                                        value={formData.para14}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header14</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header14'}
                                        value={formData.header14}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header15</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header15'}
                                        value={formData.header15}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header16</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header16'}
                                        value={formData.header16}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header17</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header17'}
                                        value={formData.header17}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para15</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para15'}
                                        value={formData.para15}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header18</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header18'}
                                        value={formData.header18}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header19</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header19'}
                                        value={formData.header19}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header20</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header20'}
                                        value={formData.header20}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header21</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'header21'}
                                        value={formData.header21}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para16</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para16'}
                                        value={formData.para16}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Para17</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name={'para17'}
                                        value={formData.para17}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        style={{ marginBottom: '16px', width: "100%" }}
                                        onClick={handleClickOpen}
                                    >
                                        View Template in Full Screen and Download
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary" style={{ width: '100%' }}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} sm={4}>

                        <Typography variant="h5" gutterBottom>
                            Adaptive Template
                        </Typography>
                        <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                            {orgdetails ? (
                                <div style={styles.container}>
                                    <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                    <h3 style={{ fontSize: "16px" }}>{orgdetails.orgName}</h3>
                                    <div style={styles.companyDetails}>
                                        <p>+91 {orgdetails.orgMob}</p>
                                        <p>{orgdetails.orgEmailId}</p>
                                        <p>{orgdetails.orgUrl}</p>
                                    </div>
                                    <div style={styles.confidentialDetails}>
                                        <h2 style={styles.header1}><center>{formData.header1}</center></h2>
                                        <p>{formData.header2} Adhithyan P A
                                        </p>
                                        <p>{formData.header3} c4e2024adhithyan__p_a
                                        </p>
                                    </div>
                                    <div style={styles.companyDetails}>
                                        {formData.header4}
                                    </div>
                                    <h3 style={styles.header1}><center>{formData.header5}</center></h3>
                                    <p>
                                        {formData.para1}<strong>Care4Edu Solutions Pvt Ltd.</strong>{formData.para2}
                                    </p>
                                    <div style={styles.HRsign}>
                                        <p>{formData.header6}</p>
                                    </div>
                                    <h3 style={styles.header1}>{formData.header7}</h3>

                                    <div style={styles.flexContainer}>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header8}</strong> Developer</p>
                                        </div>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header9}</strong>___________</p>
                                        </div>
                                        <div style={styles.flexItem}>
                                            <p><strong>Dept:</strong> TECHNICAL</p>
                                        </div>
                                    </div>

                                    <div style={styles.flexContainer}>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header10}</strong> 06/08/2024</p>
                                        </div>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header11}</strong>___________</p>
                                        </div>

                                    </div>
                                    <div style={styles.flexItem}>
                                        <p><strong>{formData.header12}</strong> 20/08/2024</p>
                                    </div>

                                    <h3 style={styles.header1}>{formData.header13}</h3>
                                    <ol style={styles.list}>
                                        <li style={styles.listItem1}>{formData.para3}</li>
                                        <li style={styles.listItem1}>{formData.para4}</li>
                                        <li style={styles.listItem1}>{formData.para5}</li>
                                        <li style={styles.listItem1}>{formData.para6}</li>
                                        <li style={styles.listItem}>{formData.para7}</li>
                                        <h3 style={styles.header2}>Excellent Good Average Poor Remarks</h3>
                                        <ol style={styles.subList}>
                                            <li style={styles.listItem}>{formData.para8}</li>
                                            <li style={styles.listItem}>{formData.para9}</li>
                                            <li style={styles.listItem}>{formData.para10}</li>
                                            <li style={styles.listItem}>{formData.para11}</li>
                                            <li style={styles.listItem}>{formData.para12}</li>
                                            <li style={styles.listItem}>{formData.para13}</li>
                                        </ol>
                                        <li style={styles.listItem}>{formData.para14}</li>
                                        <ol style={styles.subList}>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header14}</li>
                                                <li style={styles.listItem2}>{formData.header15}</li>
                                            </div>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header16}</li>
                                                <li style={styles.listItem2}>{formData.header17}</li>
                                            </div>
                                        </ol>
                                        <li style={styles.listItem}>{formData.para15}</li>
                                        <ol style={styles.subList}>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header18}</li>
                                                <li style={styles.listItem2}>{formData.header19}</li>
                                            </div>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header20}</li>
                                                <li style={styles.listItem2}>{formData.header21}</li>
                                            </div>
                                        </ol>
                                        <li style={styles.listItem3}>{formData.para16}</li>
                                        <li style={styles.listItem1}>{formData.para17}</li>
                                    </ol>
                                    <div style={styles.row}>
                                        <p style={styles.signature}>
                                            (Adhithyan P A)
                                            <br />
                                            Signature
                                        </p>
                                        <p style={styles.signature}>
                                            Date:20/08/2024
                                        </p>
                                    </div>
                                    <p style={styles.signature}>HR Comments:</p>
                                    <textarea style={styles.textarea} />
                                    <p style={styles.signature1}>
                                        HR Signature:
                                    </p>

                                </div>
                            ) : (
                                <Typography variant="body1">Loading organization details...</Typography>
                            )}
                        </Paper >
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <EmpExit />
                    </Grid>
                </Grid>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        Full Screen View
                        {(permission === 1111 || isSuperAdmin) && (
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleDownloadPDF}
                                aria-label="download"
                                style={{ position: 'absolute', right: 56, top: 16 }}
                            >
                                <DownloadIcon />
                            </IconButton>
                        )}
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            style={{ position: 'absolute', right: 16, top: 16 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {orgdetails ? (
                            <div
                                ref={templateRef}
                                style={{
                                    ...containerStyle,
                                    backgroundColor: 'white',
                                    padding: '20px',
                                    margin: 'auto'
                                }}
                            >
                                <div style={styles.container}>
                                    <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                    <h3 style={{ fontSize: "16px" }}>{orgdetails.orgName}</h3>
                                    <div style={styles.companyDetails}>
                                        <p>+91 {orgdetails.orgMob}</p>
                                        <p>{orgdetails.orgEmailId}</p>
                                        <p>{orgdetails.orgUrl}</p>
                                    </div>
                                    <div style={styles.confidentialDetails}>
                                        <h2 style={styles.header1}><center>{formData.header1}</center></h2>
                                        <p>{formData.header2} Adhithyan P A
                                        </p>
                                        <p>{formData.header3} c4e2024adhithyan__p_a
                                        </p>
                                    </div>
                                    <div style={styles.companyDetails}>
                                        {formData.header4}
                                    </div>
                                    <h3 style={styles.header1}><center>{formData.header5}</center></h3>
                                    <p>
                                        {formData.para1}<strong>Care4Edu Solutions Pvt Ltd.</strong>{formData.para2}
                                    </p>
                                    <div style={styles.HRsign}>
                                        <p>{formData.header6}</p>
                                    </div>
                                    <h3 style={styles.header1}>{formData.header7}</h3>

                                    <div style={styles.flexContainer}>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header8}</strong> Developer</p>
                                        </div>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header9}</strong>___________</p>
                                        </div>
                                        <div style={styles.flexItem}>
                                            <p><strong>Dept:</strong> TECHNICAL</p>
                                        </div>
                                    </div>

                                    <div style={styles.flexContainer}>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header10}</strong> 06/08/2024</p>
                                        </div>
                                        <div style={styles.flexItem}>
                                            <p><strong>{formData.header11}</strong>___________</p>
                                        </div>

                                    </div>
                                    <div style={styles.flexItem}>
                                        <p><strong>{formData.header12}</strong> 20/08/2024</p>
                                    </div>

                                    <h3 style={styles.header1}>{formData.header13}</h3>
                                    <ol style={styles.list}>
                                        <li style={styles.listItem1}>{formData.para3}</li>
                                        <li style={styles.listItem1}>{formData.para4}</li>
                                        <li style={styles.listItem1}>{formData.para5}</li>
                                        <li style={styles.listItem1}>{formData.para6}</li>
                                        <li style={styles.listItem}>{formData.para7}</li>
                                        <h3 style={styles.header2}>Excellent Good Average Poor Remarks</h3>
                                        <ol style={styles.subList}>
                                            <li style={styles.listItem}>{formData.para8}</li>
                                            <li style={styles.listItem}>{formData.para9}</li>
                                            <li style={styles.listItem}>{formData.para10}</li>
                                            <li style={styles.listItem}>{formData.para11}</li>
                                            <li style={styles.listItem}>{formData.para12}</li>
                                            <li style={styles.listItem}>{formData.para13}</li>
                                        </ol>
                                        <li style={styles.listItem}>{formData.para14}</li>
                                        <ol style={styles.subList}>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header14}</li>
                                                <li style={styles.listItem2}>{formData.header15}</li>
                                            </div>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header16}</li>
                                                <li style={styles.listItem2}>{formData.header17}</li>
                                            </div>
                                        </ol>
                                        <li style={styles.listItem}>{formData.para15}</li>
                                        <ol style={styles.subList}>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header18}</li>
                                                <li style={styles.listItem2}>{formData.header19}</li>
                                            </div>
                                            <div style={styles.row}>
                                                <li style={styles.listItem2}>{formData.header20}</li>
                                                <li style={styles.listItem2}>{formData.header21}</li>
                                            </div>
                                        </ol>
                                        <li style={styles.listItem3}>{formData.para16}</li>
                                        <li style={styles.listItem1}>{formData.para17}</li>
                                    </ol>
                                    <div style={styles.row}>
                                        <p style={styles.signature}>
                                            (Adhithyan P A)
                                            <br />
                                            Signature
                                        </p>
                                        <p style={styles.signature}>
                                            Date:20/08/2024
                                        </p>
                                    </div>
                                    <p style={styles.signature}>HR Comments:</p>
                                    <textarea style={styles.textarea} />
                                    <p style={styles.signature1}>
                                        HR Signature:
                                    </p>

                                </div>
                            </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                    </DialogContent>
                </Dialog>
            </PageLayout>
        </Container>
    );
};

const styles = {
    container: {
        maxWidth: '700px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },
    header: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    header1: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '10px',
        textAlign: "center   "
    },
    header2: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '10px',
        textAlign: "right"
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },
    flexItem: {
        flex: '1',
        padding: '10px',
    },
    companyDetails: {
        fontSize: '14px',
        lineHeight: '1.6',
    },
    confidentialDetails: {
        fontSize: '14px',
        fontWeight: "bold",
        marginBottom: "20px"
    },
    HRsign: {
        fontSize: '14px',
        fontWeight: "bold",
        marginBottom: "40px",
        marginTop: "60px"
    },
    list: {
        paddingLeft: '20px',
        marginBottom: "40px",
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#333',
    },
    listItem: {
        marginBottom: '15px',
    },
    listItem1: {
        marginBottom: '50px',
    },
    listItem3: {
        marginBottom: '70px',
    },
    listItem2: {
        flex: 1,
        margin: '0 10px',
        boxSizing: 'border-box'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    subList: {
        paddingLeft: '40px',
        marginTop: '10px',
        marginBottom: '20px',
        fontSize: '14px',
        color: '#555',
        listStyleType: 'lower-alpha',
    },

    flexItem: {
        width: '50%',
        marginBottom: '15px',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        color: '#555',
    },
    input: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ddd',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ddd',
        height: '100px',
    },
    signature: {
        fontSize: '14px',
        marginBottom: '10px',
    },
    signature1: {
        fontSize: '14px',
        marginBottom: '10px',
        marginTop: "30px"
    },
};

export default EmpExitedit;