// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import html2canvas from 'html2canvas';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import axios from "axios";
// import SoftBox from 'components/SoftBox';
// import { Container } from '@mui/material';
// import IdCard1 from './idCard1';
// import IdCard2 from './idCard2';
// import IdCard3 from './idCard3';
// import IdCard4 from './idCard4';
// import IdCard5 from './idCard5';
// import { APIData, org } from 'authentication/APIData';
// import SoftButton from 'components/SoftButton';
// import SoftTypography from 'components/SoftTypography';
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';

// function StudentIdCard() {
//     const [selectedID, setSelectedID] = useState("");
//     const [showBadge, setShowBadge] = useState(false);
//     const [dob, setDob] = useState("");
//     const [name, setName] = useState("");
//     const [id, setId] = useState("");
//     const [photo, setPhoto] = useState("");
//     const [orgName, setOrgName] = useState("");
//     const [orgAddress, setOrgAddress] = useState("");
//     const [logoUrl, setLogoUrl] = useState("");
//     const [studentData, setStudentData] = useState([]);
//     const [idCardNumber, setIdCardNumber] = useState("");
//     const badgeRef = useRef();

//     useEffect(() => {
//         const fetchStudentData = async () => {
//             try {
//                 const url = `${APIData.api}students?org=${org}`;
//                 const response = await fetch(url, { headers: APIData.headers });
//                 const data = await response.json();
//                 setStudentData(data);
//             } catch (err) {
//                 console.error(err);
//             }
//         };

//         const fetchIcon = async () => {
//             try {
//                 const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers });
//                 if (response.status === 200) {
//                     const { orgLogo, orgName, orgAddress, idCardTemplate } = response.data;
//                     setLogoUrl(orgLogo || "");
//                     setOrgName(orgName || "");
//                     setOrgAddress(orgAddress || "");
//                     setIdCardNumber(idCardTemplate || "1");
//                 } else {
//                     console.error("No data found.");
//                 }
//             } catch (error) {
//                 console.error("Error fetching icon:", error);
//             }
//         };

//         fetchStudentData();
//         fetchIcon();
//     }, []);

//     const handleIDChange = (e) => {
//         const id = e.value;
//         setSelectedID(id);
//         setShowBadge(false);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const url = `${APIData.api}students/${selectedID}`;
//         axios.get(url, { headers: APIData.headers })
//             .then((response) => {
//                 setName(response.data.name);
//                 setDob(response.data.dob);
//                 setId(response.data.student_id);
//                 setPhoto(""); // Students might not have photos
//                 setShowBadge(true);
//             })
//             .catch((err) => console.error(err));
//     };

//     const downloadBadge = useCallback(async () => {
//         if (badgeRef.current) {
//             try {
//                 const canvas = await html2canvas(badgeRef.current, { scale: 2, useCORS: true });
//                 const dataUrl = canvas.toDataURL('image/png');
//                 const link = document.createElement('a');
//                 link.download = `student_badge_${id}.png`;
//                 link.href = dataUrl;
//                 link.click();
//             } catch (error) {
//                 console.error('Error generating badge:', error);
//             }
//         }
//     }, [id]);

//     const renderIdCard = () => {
//         const props = {
//             name,
//             dob,
//             id,
//             photo,
//             orgName,
//             orgAddress,
//             logoUrl
//         };

//         switch (idCardNumber) {
//             case "1":
//                 return <IdCard1 {...props} />;
//             case "2":
//                 return <IdCard2 {...props} />;
//             case "3":
//                 return <IdCard3 {...props} />;
//             case "4":
//                 return <IdCard4 {...props} />;
//             case "5":
//                 return <IdCard5 {...props} />;
//             default:
//                 return <IdCard1 {...props} />;
//         }
//     };

//     const uniqueIds = studentData.map(item => ({ 
//         value: item.student_id, 
//         label: item.name 
//     }));

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <Container maxWidth="sm">
//                 <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
//                     <SoftTypography variant="h6" textAlign="center">
//                         Select Student
//                     </SoftTypography>
//                     <CustomSelect
//                         options={uniqueIds}
//                         placeholder="Select Student"
//                         name="studentType"
//                         onChange={handleIDChange}
//                         value={uniqueIds.find(item => item.value === selectedID)}
//                         required
//                         style={{ width: '300px' }}
//                     />
//                 </SoftBox>
//                 <SoftBox mt={2} mb={4} display="flex" justifyContent="center">
//                     <SoftButton
//                         onClick={handleSubmit}
//                         variant="gradient"
//                         color="info"
//                         style={{ width: '200px' }}
//                     >
//                         Generate ID Card
//                     </SoftButton>
//                 </SoftBox>

//                 {showBadge && (
//                     <SoftBox mb={4} display="flex" flexDirection="column" alignItems="center">
//                         <div ref={badgeRef} style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0 0 16px 0' }}>
//                             {renderIdCard()}
//                         </div>
//                         <SoftButton
//                             variant="gradient"
//                             color="secondary"
//                             onClick={downloadBadge}
//                             style={{ width: '200px' }}
//                         >
//                             Download Student ID Card 
//                         </SoftButton>
//                     </SoftBox>
//                 )}
//             </Container>
//         </DashboardLayout>
//     );
// }

// export default StudentIdCard;

// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import html2canvas from 'html2canvas';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import axios from "axios";
// import SoftBox from 'components/SoftBox';
// import { Container } from '@mui/material';
// import IdCard1 from './idCard1';
// import IdCard2 from './idCard2';
// import IdCard3 from './idCard3';
// import IdCard4 from './idCard4';
// import IdCard5 from './idCard5';
// import { APIData, org } from 'authentication/APIData';
// import SoftButton from 'components/SoftButton';
// import SoftTypography from 'components/SoftTypography';
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';

// function StudentIdCard() {
//     const [selectedID, setSelectedID] = useState("");
//     const [showBadge, setShowBadge] = useState(false);
//     const [dob, setDob] = useState("");
//     const [name, setName] = useState("");
//     const [id, setId] = useState("");
//     const [photo, setPhoto] = useState("");
//     const [orgName, setOrgName] = useState("");
//     const [orgAddress, setOrgAddress] = useState("");
//     const [logoUrl, setLogoUrl] = useState("");
//     const [studentData, setStudentData] = useState([]);
//     const [idCardNumber, setIdCardNumber] = useState("");
//     const badgeRef = useRef();

//     useEffect(() => {
//         const fetchStudentData = async () => {
//             try {
//                 // Using the new endpoint
//                 const url = `${APIData.api}studentadmissiondetails/?org=${org}`;
//                 const response = await fetch(url, { headers: APIData.headers });
//                 const data = await response.json();
//                 console.log("Fetched student data:", data); // Debug log
//                 setStudentData(data);
//             } catch (err) {
//                 console.error("Error fetching student data:", err);
//             }
//         };

//         const fetchIcon = async () => {
//             try {
//                 const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers });
//                 if (response.status === 200) {
//                     const { orgLogo, orgName, orgAddress, idCardTemplate } = response.data;
//                     setLogoUrl(orgLogo || "");
//                     setOrgName(orgName || "");
//                     setOrgAddress(orgAddress || "");
//                     setIdCardNumber(idCardTemplate || "1");
//                 } else {
//                     console.error("No data found.");
//                 }
//             } catch (error) {
//                 console.error("Error fetching icon:", error);
//             }
//         };

//         fetchStudentData();
//         fetchIcon();
//     }, []);

//     const handleIDChange = (e) => {
//         const id = e.value;
//         console.log("Selected ID:", id); // Debug log
//         setSelectedID(id);
//         setShowBadge(false);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Using the new endpoint with email parameter
//         const encodedEmail = encodeURIComponent(selectedID);
//         const url = `${APIData.api}studentadmissiondetails/email?email=${encodedEmail}`;
        
//         console.log("Fetching student details from:", url); // Debug log
        
//         axios.get(url, { headers: APIData.headers })
//             .then((response) => {
//                 console.log("Student details response:", response.data); // Debug log
//                 const studentData = response.data;
                
//                 // Using the correct field names from your JSON
//                 setName(studentData.name || "");
//                 setDob(studentData.dateOfBirth || "");
//                 setId(studentData.emailId || "");
//                 setPhoto(studentData.image_url || "");
//                 setShowBadge(true);
//             })
//             .catch((err) => {
//                 console.error("Error fetching student details:", err);
//                 console.error("Error response:", err.response?.data);
//             });
//     };

//     const downloadBadge = useCallback(async () => {
//         if (badgeRef.current) {
//             try {
//                 const canvas = await html2canvas(badgeRef.current, { 
//                     scale: 2, 
//                     useCORS: true,
//                     logging: true, // Enable logging for debugging
//                     allowTaint: true
//                 });
//                 const dataUrl = canvas.toDataURL('image/png');
//                 const link = document.createElement('a');
//                 link.download = `student_badge_${id}.png`;
//                 link.href = dataUrl;
//                 link.click();
//             } catch (error) {
//                 console.error('Error generating badge:', error);
//             }
//         }
//     }, [id]);

//     const renderIdCard = () => {
//         const props = {
//             name,
//             dob,
//             id,
//             photo,
//             orgName,
//             orgAddress,
//             logoUrl
//         };

//         switch (idCardNumber) {
//             case "1":
//                 return <IdCard1 {...props} />;
//             case "2":
//                 return <IdCard2 {...props} />;
//             case "3":
//                 return <IdCard3 {...props} />;
//             case "4":
//                 return <IdCard4 {...props} />;
//             case "5":
//                 return <IdCard5 {...props} />;
//             default:
//                 return <IdCard1 {...props} />;
//         }
//     };

//     // Updated to use emailId instead of student_id
//     const uniqueIds = studentData.map(item => ({ 
//         value: item.emailId,  // Changed from student_id to emailId
//         label: item.name 
//     }));

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <Container maxWidth="sm">
//                 <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
//                     <SoftTypography variant="h6" textAlign="center">
//                         Select Student
//                     </SoftTypography>
//                     <CustomSelect
//                         options={uniqueIds}
//                         placeholder="Select Student"
//                         name="studentType"
//                         onChange={handleIDChange}
//                         value={uniqueIds.find(item => item.value === selectedID)}
//                         required
//                         style={{ width: '300px' }}
//                     />
//                 </SoftBox>
//                 <SoftBox mt={2} mb={4} display="flex" justifyContent="center">
//                     <SoftButton
//                         onClick={handleSubmit}
//                         variant="gradient"
//                         color="info"
//                         style={{ width: '200px' }}
//                     >
//                         Generate ID Card
//                     </SoftButton>
//                 </SoftBox>

//                 {showBadge && (
//                     <SoftBox mb={4} display="flex" flexDirection="column" alignItems="center">
//                         <div ref={badgeRef} style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0 0 16px 0' }}>
//                             {renderIdCard()}
//                         </div>
//                         <SoftButton
//                             variant="gradient"
//                             color="secondary"
//                             onClick={downloadBadge}
//                             style={{ width: '200px' }}
//                         >
//                             Download Student ID Card 
//                         </SoftButton>
//                     </SoftBox>
//                 )}
//             </Container>
//         </DashboardLayout>
//     );
// }

// export default StudentIdCard;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import html2canvas from 'html2canvas';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import axios from "axios";
import SoftBox from 'components/SoftBox';
import { Container } from '@mui/material';
import IdCard1 from './idCard1';
import IdCard2 from './idCard2';
import IdCard3 from './idCard3';
import IdCard4 from './idCard4';
import IdCard5 from './idCard5';
import { APIData, org } from 'authentication/APIData';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';

function StudentIdCard() {
    const [selectedID, setSelectedID] = useState("");
    const [showBadge, setShowBadge] = useState(false);
    const [dob, setDob] = useState("");
    const [name, setName] = useState("");
    const [photo, setPhoto] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgAddress, setOrgAddress] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [studentData, setStudentData] = useState([]);
    const [idCardNumber, setIdCardNumber] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [sclass, setSClass] = useState("");
    const [role, setRole] = useState("");
    const badgeRef = useRef();

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                const url = `${APIData.api}studentadmissiondetails/?org=${org}`;
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                console.log("Fetched student data:", data);
                setStudentData(data);
            } catch (err) {
                console.error("Error fetching student data:", err);
            }
        };

        const fetchIcon = async () => {
            try {
                const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers });
                if (response.status === 200) {
                    const { orgLogo, orgName, orgAddress, idCardTemplate } = response.data;
                    setLogoUrl(orgLogo || "");
                    setOrgName(orgName || "");
                    setOrgAddress(orgAddress || "");
                    setIdCardNumber(idCardTemplate || "1");
                } else {
                    console.error("No data found.");
                }
            } catch (error) {
                console.error("Error fetching icon:", error);
            }
        };

        fetchStudentData();
        fetchIcon();
    }, []);

    const handleIDChange = (e) => {
        const id = e.value;
        console.log("Selected ID:", id);
        setSelectedID(id);
        setShowBadge(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const encodedEmail = encodeURIComponent(selectedID);
        const url = `${APIData.api}studentadmissiondetails/email?email=${encodedEmail}`;
        console.log("Fetching student details from:", url);

        axios.get(url, { headers: APIData.headers })
            .then((response) => {
                const studentData = response.data[0]; // Assuming the API returns an array of student data
                console.log("Student details response:", studentData);

                setName(studentData.name || "");
                setDob(studentData.dateOfBirth || "");
                setPhoto(studentData.image_url || "");
                setBloodGroup(studentData.bloodGroup || "");
                setSClass(studentData.studentClass || "");
                setRole(studentData.role || "Student");

                setShowBadge(true);
            })
            .catch((err) => {
                console.error("Error fetching student details:", err);
            });
    };

    const downloadBadge = useCallback(async () => {
        if (badgeRef.current) {
            try {
                const canvas = await html2canvas(badgeRef.current, { 
                    scale: 2, 
                    useCORS: true,
                    logging: true,
                    allowTaint: true
                });
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = `student_badge_${name}.png`;
                link.href = dataUrl;
                link.click();
            } catch (error) {
                console.error('Error generating badge:', error);
            }
        }
    }, [name]);

    const renderIdCard = () => {
        const props = {
            name,
            dob,
            photo,
            role,
            orgName,
            orgAddress,
            logoUrl,
            bloodGroup,
            studentClass: sclass
        };

        console.log("Props passed to IdCard:", props);

        switch (idCardNumber) {
            case "1":
                return <IdCard1 {...props} />;
            case "2":
                return <IdCard2 {...props} />;
            case "3":
                return <IdCard3 {...props} />;
            case "4":
                return <IdCard4 {...props} />;
            case "5":
                return <IdCard5 {...props} />;
            default:
                return <IdCard1 {...props} />;
        }
    };

    const uniqueIds = studentData.map(item => ({
        value: item.emailId,
        label: item.name
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="sm">
                <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                    <SoftTypography variant="h6" textAlign="center">
                        Select Student
                    </SoftTypography>
                    <CustomSelect
                        options={uniqueIds}
                        placeholder="Select Student"
                        name="studentType"
                        onChange={handleIDChange}
                        value={uniqueIds.find(item => item.value === selectedID)}
                        required
                        style={{ width: '300px' }}
                    />
                </SoftBox>
                <SoftBox mt={2} mb={4} display="flex" justifyContent="center">
                    <SoftButton
                        onClick={handleSubmit}
                        variant="gradient"
                        color="info"
                        style={{ width: '200px' }}
                    >
                        Generate ID Card
                    </SoftButton>
                </SoftBox>

                {showBadge && (
                    <SoftBox mb={4} display="flex" flexDirection="column" alignItems="center">
                        <div ref={badgeRef} style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '0 0 16px 0' }}>
                            {renderIdCard()}
                        </div>
                        <SoftButton
                            variant="gradient"
                            color="secondary"
                            onClick={downloadBadge}
                            style={{ width: '200px' }}
                        >
                            Download Student ID Card 
                        </SoftButton>
                    </SoftBox>
                )}
            </Container>
        </DashboardLayout>
    );
}

export default StudentIdCard;
