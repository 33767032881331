import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import breakpoints from "assets/theme/base/breakpoints";
import SoftButton from 'components/SoftButton';
import { Link } from 'react-router-dom';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Swal from 'sweetalert2';
import { Card } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const Name = ({ name, email }) => {
  const [showFullName, setShowFullName] = useState(false);
  const nameWords = name.split(" ");

  const handleToggleFullName = () => {
    setShowFullName(!showFullName);
  };

  const displayedName = showFullName || nameWords.length <= 3
    ? name
    : `${nameWords.slice(0, 3).join(" ")}...`;

  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar
          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
          alt={name}
          size="sm"
          variant="rounded"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {displayedName}
        </SoftTypography>
        {nameWords.length > 3 && (
          <SoftTypography
            variant="caption"
            color="info"
            onClick={handleToggleFullName}
            style={{ cursor: "pointer" }}
          >
            {showFullName ? "Show Less" : "Read More"}
          </SoftTypography>
        )}
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

Name.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const Organization = ({ salesType, orgName }) => (
  <SoftBox display="flex" flexDirection="column">
    <SoftTypography variant="caption" fontWeight="medium" color="text">
      {salesType}
    </SoftTypography>
    <SoftTypography variant="caption" color="secondary">
      {orgName}
    </SoftTypography>
  </SoftBox>
);

Organization.propTypes = {
  salesType: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

const CoursesDetails = () => {
  const [courseData, setCourseData] = useState([]);
  const [status, setStatus] = useState("All");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  
  // New state variables for search and pagination
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(accessLevel);
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  const handleDelete = async (courseId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteUrl = `${APIData.api}courses/${courseId}`;
          const response = await fetch(deleteUrl, {
            method: 'DELETE',
            headers: APIData.headers
          });
  
          if (response.ok) {
            Swal.fire({
              icon: "success",
              title: "Deleted successfully!",
              showConfirmButton: false,
              timer: 1500
            });
            setCourseData(prevData => prevData.filter(course => course.course_id !== courseId));
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Failed to Delete Course',
              text: 'Please try again later.',
              confirmButtonText: 'OK'
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Submission Error",
            text: error.response?.data?.message || 'Error deleting course. Please try again later.',
            confirmButtonText: 'OK'
          });
        }
      }
    });
  };
  

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = `${APIData.api}courses/?org=${org}`;
      try {
        const response = await fetch(baseUrl, { headers: APIData.headers });
        const data = await response.json();
        setCourseData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [tabValue]);

  // Filter courses based on search term
  const filteredCourses = courseData.filter(course => 
    course.course_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.course_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.course_sub_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.admin_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Paginate the filtered courses
  const paginatedCourses = filteredCourses.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const columns = [
    { name: "Course", align: "left" },
    { name: "Course_Name", align: "left" },
    { name: "Course_Type", align: "left" },
    { name: "sub_Type", align: "left" },
    { name: "Course_Fees", align: "left" },
    { name: "Course_Duration", align: "left" },
    { name: "Admin_id", align: "left" },
    ...(permission === "1100" || permission === "1000" ? [] : [{ name: "Action", align: "left" }]),
  ];

  const rows = paginatedCourses.map((course) => ({
    Course: <Name name={course.course_id} />,
    Course_Name: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.courseName}
      </SoftTypography>
    ),
    Course_Type: <Organization orgName={course.course_type} />,
    sub_Type: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.course_sub_type}
      </SoftTypography>
    ),
    Course_Fees: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.course_fees}
      </SoftTypography>
    ),
    Course_Duration: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.course_duration}
      </SoftTypography>
    ),
    Admin_id: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.admin_id}
      </SoftTypography>
    ),
    Action: !(permission === "1100" || permission === "1000") ? (
      <>
        {!(permission === "1100" || permission === "1000") && (
          <Link to={`/edit-course/${course.course_id}`}>
            <SoftTypography
              component="a"
              variant="button"
              color="info"
              fontWeight="medium"
            >
              Edit
            </SoftTypography>
          </Link>
        )}
        {(permission === "1111" || isSuperAdmin ) && (
          <Link>
            <SoftTypography
              component="a"
              variant="button"
              color="error"
              fontWeight="medium"
              sx={{ marginLeft: "10px" }}
              onClick={() => handleDelete(course.course_id)}
            >
              Delete
            </SoftTypography>
          </Link>
        )}
      </>
    ) : null,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card>
          <SoftBox mb={3}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Course Details</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap={2}>
                <SoftBox>
                  <SoftInput
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                  />
                </SoftBox>
                {permission !== "1000" && (
                  <Link to='/add-course'>
                    <SoftButton variant="gradient" color="info" size="small">
                      + Add New Course
                    </SoftButton>
                  </Link>
                )}
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={3}
            >
              <SoftBox display="flex" gap={1} alignItems="center">
                <SoftTypography variant="caption" color="secondary">
                  {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredCourses.length)} of {filteredCourses.length}
                </SoftTypography>
                <SoftButton
                  variant="text"
                  color="info"
                  onClick={() => setPage(prev => Math.max(0, prev - 1))}
                  disabled={page === 0}
                  iconOnly
                >
                  <KeyboardArrowLeft />
                </SoftButton>
                <SoftButton
                  variant="text"
                  color="info"
                  onClick={() => setPage(prev => Math.min(Math.ceil(filteredCourses.length / itemsPerPage) - 1, prev + 1))}
                  disabled={page >= Math.ceil(filteredCourses.length / itemsPerPage) - 1}
                  iconOnly
                >
                  <KeyboardArrowRight />
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CoursesDetails;