// import React from 'react';
// import background from '../../assets/images/Whiteblue.png';
// import profile from '../../assets/images/profile image.jpg';
// import PropTypes from 'prop-types';

// const IdCard1 = ({ name, dob, id, photo, role, orgName, orgAddress, logoUrl }) => {
//     return (
//         <div style={{
//             height: "420px",
//             width: "250px",
//             padding: "0px",
//             position: "relative",
//             borderRadius: "10px",
//             backgroundImage: `url(${background})`,
//             backgroundSize: "250px",
//             backgroundColor: "transparent",
//         }}>
//             <div style={{ textAlign: "center" }}>
//                 {logoUrl ? (
//                     <img 
//                         src={logoUrl} 
//                         style={{ height: '60px', width: '70px', objectFit: "contain" }} 
//                         alt="logo" 
//                     />
//                 ) : (
//                     <div style={{ height: '60px', width: '90px', backgroundColor: '#ddd' }}></div>
//                 )}
//                 <p style={{ fontWeight: "bolder", fontSize: '16px', color: "#004bac" }}>{orgName}</p>
//                 <p style={{ fontSize: '8px', color: 'black' }}>{orgAddress}</p>
//             </div>
//             <div style={{ marginTop: "30px", textAlign: "center" }}>
//                 {photo ? (
//                     <img
//                         src={photo}
//                         style={{
//                             height: "100px",
//                             width: "100px",
//                             backgroundColor: "white",
//                             borderRadius: "60px",
//                             border: '5px solid #004bac',
//                             boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
//                             marginBottom: "15px",
//                             objectFit: "fill"
//                         }}
//                         alt="employee"
//                     />
//                 ) : (
//                     <img
//                         src={profile} // Default profile image
//                         style={{
//                             height: "100px",
//                             width: "100px",
//                             backgroundColor: "white",
//                             borderRadius: "60px",
//                             border: '5px solid #004bac',
//                             boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
//                             marginBottom: "15px",
//                             objectFit: "cover"
//                         }}
//                         alt="default profile"
//                     />
//                 )}
//                 <p style={{
//                     textTransform: "capitalize",
//                     fontWeight: "bold",
//                     fontSize: 18,
//                     color: "#004bac",
//                     margin: "5px 0"
//                 }}>
//                     {name}
//                 </p>
//                 <p style={{
//                     fontWeight: "normal",
//                     fontSize: 14,
//                     color: "#666",
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                     whiteSpace: "nowrap",
//                 }}>
//                     {role} 
//                 </p>
//                 <p style={{
//                     fontSize: 16,
//                     color: "#333",
//                     margin: "5px 0"
//                 }}>
//                     {id}
//                 </p>
//                 <p style={{
//                     fontSize: 14,
//                     color: "#666",
//                     margin: "5px 0"
//                 }}>
//                     {dob}
//                 </p>
//             </div>
//         </div>
//     );
// }
// IdCard1.propTypes = {
//     name: PropTypes.string.isRequired,
//     dob: PropTypes.string.isRequired,
//     id: PropTypes.string.isRequired,
//     photo: PropTypes.string,
//     role: PropTypes.string.isRequired,
//     orgName: PropTypes.string.isRequired,
//     orgAddress: PropTypes.string.isRequired,
//     logoUrl: PropTypes.string,
// };
// export default IdCard1;
// import React from 'react';
// import background from '../../assets/images/Whiteblue.png';
// import profile from '../../assets/images/profile image.jpg';
// import PropTypes from 'prop-types';

// const IdCard1 = ({ name, dob, id, photo, orgName, orgAddress, logoUrl, bloodGroup, studentClass }) => {
//     return (
//         <div style={{
//             height: "460px", // Adjusted height
//             width: "250px",
//             padding: "0px",
//             position: "relative",
//             borderRadius: "10px",
//             backgroundImage: `url(${background})`,
//             backgroundSize: "250px",
//             backgroundColor: "transparent",
//         }}>
//             <div style={{ textAlign: "center" }}>
//                 {logoUrl ? (
//                     <img 
//                         src={logoUrl} 
//                         style={{ height: '60px', width: '70px', objectFit: "contain" }} 
//                         alt="logo" 
//                     />
//                 ) : (
//                     <div style={{ height: '60px', width: '90px', backgroundColor: '#ddd' }}></div>
//                 )}
//                 <p style={{ fontWeight: "bolder", fontSize: '16px', color: "#004bac" }}>{orgName}</p>
//                 <p style={{ fontSize: '8px', color: 'black' }}>{orgAddress}</p>
//             </div>
//             <div style={{ marginTop: "30px", textAlign: "center" }}>
//                 {photo ? (
//                     <img
//                         src={photo}
//                         style={{
//                             height: "100px",
//                             width: "100px",
//                             backgroundColor: "white",
//                             borderRadius: "60px",
//                             border: '5px solid #004bac',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
//                             marginBottom: "15px",
//                             objectFit: "fill"
//                         }}
//                         alt="employee"
//                     />
//                 ) : (
//                     <img
//                         src={profile} // Default profile image
//                         style={{
//                             height: "100px",
//                             width: "100px",
//                             backgroundColor: "white",
//                             borderRadius: "60px",
//                             border: '5px solid #004bac',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
//                             marginBottom: "15px",
//                             objectFit: "cover"
//                         }}
//                         alt="default profile"
//                     />
//                 )}
//                 <p style={{
//                     textTransform: "capitalize",
//                     fontWeight: "bold",
//                     fontSize: 18,
//                     color: "#004bac",
//                     margin: "5px 0"
//                 }}>
//                     {name}
//                 </p>
//                 <p style={{
//                     fontSize: 16,
//                     color: "#333",
//                     margin: "5px 0"
//                 }}>
//                     {id}
//                 </p>
//                 <p style={{
//                     fontSize: 14,
//                     color: "#666",
//                     margin: "5px 0"
//                 }}>
//                     {dob}
//                 </p>

//                 <p style={{
//                     fontSize: 14,
//                     color: "#004bac",
//                     margin: "5px 0",
//                     fontWeight: "bold",
//                 }}>
//                     Blood Group: {bloodGroup || "N/A"}
//                 </p>
//                 <p style={{
//                     fontSize: 14,
//                     color: "#004bac",
//                     margin: "5px 0",
//                     fontWeight: "bold",
//                 }}>
//                     Class: {studentClass || "N/A"}
//                 </p>
//             </div>
//         </div>
//     );
// }

// IdCard1.propTypes = {
//     name: PropTypes.string.isRequired,
//     dob: PropTypes.string.isRequired,
//     id: PropTypes.string.isRequired,
//     photo: PropTypes.string,
//     orgName: PropTypes.string.isRequired,
//     orgAddress: PropTypes.string.isRequired,
//     logoUrl: PropTypes.string,
//     bloodGroup: PropTypes.string, // Blood group prop
//     studentClass: PropTypes.string, // Class prop
// };

// export default IdCard1;


import React from 'react';
import background from '../../assets/images/Whiteblue.png';
import profile from '../../assets/images/profile image.jpg';
import PropTypes from 'prop-types';

const IdCard1 = ({ name, dob, id, photo, role, orgName, orgAddress, logoUrl, bloodGroup, studentClass }) => {
    return (
        <div style={{
            height: "420px",
            width: "250px",
            padding: "0px",
            position: "relative",
            borderRadius: "10px",
            backgroundImage: `url(${background})`,
            backgroundSize: "250px",
            backgroundColor: "transparent",
        }}>
            <div style={{ textAlign: "center" }}>
                {logoUrl ? (
                    <img 
                        src={logoUrl} 
                        style={{ height: '60px', width: '70px', objectFit: "contain" }} 
                        alt="logo" 
                    />
                ) : (
                    <div style={{ height: '60px', width: '90px', backgroundColor: '#ddd' }}></div>
                )}
                <p style={{ fontWeight: "bolder", fontSize: '16px', color: "#004bac" }}>{orgName}</p>
                <p style={{ fontSize: '8px', color: 'black' }}>{orgAddress}</p>
            </div>

            <div style={{ marginTop: "30px", textAlign: "center" }}>
                {photo ? (
                    <img
                        src={photo}
                        style={{
                            height: "100px",
                            width: "100px",
                            backgroundColor: "white",
                            borderRadius: "60px",
                            border: '5px solid #004bac',
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                            marginBottom: "15px",
                            objectFit: "fill"
                        }}
                        alt="employee"
                    />
                ) : (
                    <img
                        src={profile} // Default profile image
                        style={{
                            height: "100px",
                            width: "100px",
                            backgroundColor: "white",
                            borderRadius: "60px",
                            border: '5px solid #004bac',
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                            marginBottom: "15px",
                            objectFit: "cover"
                        }}
                        alt="default profile"
                    />
                )}

                <p style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "#004bac",
                    margin: "5px 0"
                }}>
                    {name}
                </p>

                {/* Role Display for both students and employees */}
                <p style={{
                    fontWeight: "normal",
                    fontSize: 14,
                    color: "#666",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal", // Allow wrapping here
                    wordWrap: "break-word", // Ensures the text wraps if it's too long
                    margin: "0 5px", // Added some margin for better spacing
                }}>
                 {role} 
                </p>

                {/* Student Specific Fields */}
                {role === 'Student' && (
                    <>
                        <p style={{
                            fontSize: 14,
                            color: "#004bac",
                            margin: "5px 0",
                            fontWeight: "bold",
                        }}>
                            Blood Group: {bloodGroup || 'N/A'}
                        </p>
                        <p style={{
                            fontSize: 14,
                            color: "#004bac",
                            margin: "5px 0",
                            fontWeight: "bold",
                        }}>
                            Class: {studentClass || 'N/A'}
                        </p>
                    </>
                )}

                {/* Common Fields */}
                <p style={{
                    fontSize: 16,
                    color: "#333",
                    margin: "5px 0"
                }}>
                    {id}
                </p>
                <p style={{
                    fontSize: 14,
                    color: "#666",
                    margin: "5px 0"
                }}>
                    {dob}
                </p>
            </div>
        </div>
    );
}

IdCard1.propTypes = {
    name: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    photo: PropTypes.string,
    role: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    orgAddress: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    bloodGroup: PropTypes.string,
    studentClass: PropTypes.string,
};

export default IdCard1;
