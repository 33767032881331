import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import typography from "assets/theme/base/typography";
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";
import Swal from "sweetalert2";

function Dashboard() {
  const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const superadmin = sessiondetails.userType;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const navigate = useNavigate();
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [currentMonthDisplay, setCurrentMonthDisplay] = useState(new Date().toISOString().slice(0, 7));
  const [candidateCount, setCandidateCount] = useState(0);
  const [currentStatus, setCurrentStatus] = useState('ALL');
  const [expenseChartData, setExpenseChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Expenses",
        color: "info",
        data: [],
      },
    ],
  });

  const statusList = ['ALL', 'APPLIED', 'SCRUTINY', 'NEXTROUND', 'FINALROUND', 'OFFERRELEASED'];

  const [enrollmentCount, setEnrollmentCount] = useState(0);
  const [enrollmentType, setEnrollmentType] = useState('ALL');
  const enrollmentTypes = ['ALL', 'EMPLOYEE', 'STUDENT'];
  const [employeeCount, setEmployeeCount] = useState(0);
  const [employeeDept, setEmployeeDept] = useState('ALL');
  const employeeDepts = ['ALL', 'TECHNICAL', 'HR', 'SALES', 'FINANCE', 'ACADEMY'];

  const [departmentChartData, setDepartmentChartData] = useState({
    labels: [],
    datasets: {
      label: "Employees",
      data: [],
    },
  });

  const [departmentItems, setDepartmentItems] = useState([]);

  const fetchDepartmentCounts = async () => {
    try {
      const departments = ['TECHNICAL', 'HR', 'FINANCE', 'SALES', 'ACADEMY'];
      const counts = [];
      const items = [];

      for (const dept of departments) {
        const response = await axios.get(
          `${APIData.api}employee/employees/count?org=${org}&dept=${dept}`,
          { headers: APIData.headers }
        );
        counts.push(response.data || 0);

        items.push({
          icon: { color: "primary", component: "person" },
          label: dept.charAt(0) + dept.slice(1).toLowerCase(),
          progress: { content: response.data || 0, percentage: response.data }
        });
      }

      setDepartmentChartData({
        labels: departments.map(dept => dept.charAt(0) + dept.slice(1).toLowerCase()),
        datasets: {
          label: "Employees",
          data: counts,
        },
      });

      // Update items
      setDepartmentItems(items);

    } catch (error) {
      console.error("Error fetching department counts:", error);
    }
  };

  useEffect(() => {
    fetchTotalExpenses();
    fetchemailsrvice();
    fetchDepandDesignation();
    fetchMonthlyExpenses();
    fetchCandidateCount();
    fetchEnrollmentCount();
    fetchEmployeeCount();
    fetchDepartmentCounts();
  }, [currentMonthDisplay, currentStatus, enrollmentType, employeeDept]);

  const fetchEmployeeCount = async () => {
    try {
      let url = `${APIData.api}employee/employees/count?org=${org}`;
      if (employeeDept !== 'ALL') {
        url += `&dept=${employeeDept}`;
      }

      const response = await axios.get(url, { headers: APIData.headers });
      setEmployeeCount(response.data || 0);
    } catch (error) {
      console.error("Error fetching employee count:", error);
      setEmployeeCount(0);
    }
  };

  const handleEmployeeDeptChange = (direction) => {
    const currentIndex = employeeDepts.indexOf(employeeDept);
    let newIndex;

    if (direction === "prev") {
      newIndex = currentIndex <= 0 ? employeeDepts.length - 1 : currentIndex - 1;
    } else {
      newIndex = currentIndex >= employeeDepts.length - 1 ? 0 : currentIndex + 1;
    }

    setEmployeeDept(employeeDepts[newIndex]);
  };

  const fetchEnrollmentCount = async () => {
    try {
      let url = `${APIData.api}enrollments/count?org=${org}`;
      if (enrollmentType !== 'ALL') {
        url += `&enrollment_type=${enrollmentType}`;
      }

      const response = await axios.get(url, { headers: APIData.headers });
      setEnrollmentCount(response.data || 0);
    } catch (error) {
      console.error("Error fetching enrollment count:", error);
      setEnrollmentCount(0);
    }
  };


  const handleEnrollmentTypeChange = (direction) => {
    const currentIndex = enrollmentTypes.indexOf(enrollmentType);
    let newIndex;

    if (direction === "prev") {
      newIndex = currentIndex <= 0 ? enrollmentTypes.length - 1 : currentIndex - 1;
    } else {
      newIndex = currentIndex >= enrollmentTypes.length - 1 ? 0 : currentIndex + 1;
    }

    setEnrollmentType(enrollmentTypes[newIndex]);
  };


  const fetchCandidateCount = async () => {
    try {
      const url = currentStatus === 'ALL'
        ? `${APIData.api}candidate/count?org=${org}`
        : `${APIData.api}candidate/count?status=${currentStatus}&org=${org}`;

      const response = await axios.get(url, { headers: APIData.headers });
      setCandidateCount(response.data || 0);
    } catch (error) {
      console.error("Error fetching candidate count:", error);
      setCandidateCount(0);
    }
  };

  const handleStatusChange = (direction) => {
    const currentIndex = statusList.indexOf(currentStatus);
    let newIndex;

    if (direction === "prev") {
      newIndex = currentIndex <= 0 ? statusList.length - 1 : currentIndex - 1;
    } else {
      newIndex = currentIndex >= statusList.length - 1 ? 0 : currentIndex + 1;
    }

    setCurrentStatus(statusList[newIndex]);
  };

  const fetchMonthlyExpenses = async () => {
    try {
      // Get data for the last 6 months
      const endDate = new Date(currentMonthDisplay + "-01");
      const labels = [];
      const expenseData = [];

      for (let i = 5; i >= 0; i--) {
        const date = new Date(endDate);
        date.setMonth(date.getMonth() - i);
        const monthYear = date.toISOString().slice(0, 7);

        const response = await axios.get(
          `${APIData.api}expenses/total-cost-month?org=${org}&month=${monthYear}`,
          { headers: APIData.headers }
        );

        labels.push(date.toLocaleString('en-US', { month: 'short' }));
        expenseData.push(response.data || 0);
      }

      setExpenseChartData({
        labels,
        datasets: [
          {
            label: "Monthly Expenses",
            color: "info",
            data: expenseData,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching monthly expenses:", error);
    }
  };

  const fetchTotalExpenses = async () => {
    try {
      const response = await axios.get(
        `${APIData.api}expenses/total-cost-month?org=${org}&month=${currentMonthDisplay}`,
        { headers: APIData.headers }
      );
      setTotalExpenses(response.data || 0);
    } catch (error) {
      console.error("Error fetching total expenses:", error);
    }
  };

  const handleMonthChange = (direction) => {
    const currentDate = new Date(currentMonthDisplay + "-01");
    const today = new Date();

    if (direction === "prev") {
      currentDate.setMonth(currentDate.getMonth() - 1);
    } else if (direction === "next") {
      const nextMonth = new Date(currentDate);
      nextMonth.setMonth(currentDate.getMonth() + 1);

      if (nextMonth > today) {
        return;
      }

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    setCurrentMonthDisplay(currentDate.toISOString().slice(0, 7));
  };


  const formatMonthYear = (dateString) => {
    const date = new Date(dateString + "-01");
    return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
  };

  const fetchemailsrvice = () => {
    axios.get(APIData.api + `asset-email/all?org=${org}`, { headers: APIData.headers })
      .then((resp) => {
        if (!resp.data || resp.data.length === 0) {
          if (superadmin === "SUPERADMIN") {
            navigate('/stepper')
          }
          else {
            Swal.fire({
              title: "There some details needs to filled by superadmin",
              showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDepandDesignation = () => {
    axios.get(APIData.api + `org-roles-head?org=${org}`, { headers: APIData.headers })
      .then((resp) => {
        if (!resp.data || resp.data.length === 0) {
          if (superadmin === "SUPERADMIN") {
            navigate('/stepper')
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Calculate the expense trend percentage
  const calculateExpenseTrend = () => {
    if (expenseChartData.datasets[0].data.length >= 2) {
      const currentMonth = expenseChartData.datasets[0].data[expenseChartData.datasets[0].data.length - 1];
      const previousMonth = expenseChartData.datasets[0].data[expenseChartData.datasets[0].data.length - 2];
      if (previousMonth === 0) return 0;
      return ((currentMonth - previousMonth) / previousMonth * 100).toFixed(1);
    }
    return 0;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: `Expense ` }}
                count={`₹${totalExpenses.toLocaleString()}`}
                percentage={{ color: "success", text:`(${formatMonthYear(currentMonthDisplay)})` }}
                icon={{ color: "info", component: "paid" }}
                showNavigation={true}
                onPrevClick={() => handleMonthChange("prev")}
                onNextClick={() => handleMonthChange("next")}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Employees" }}
                count={employeeCount}
                percentage={{ color: "info", text: `(${employeeDept.toLowerCase()})` }}
                icon={{
                  color: "info",
                  component: "person",
                }}
                showNavigation={true}
                onPrevClick={() => handleEmployeeDeptChange("prev")}
                onNextClick={() => handleEmployeeDeptChange("next")}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: `Candidates ` }}
                count={candidateCount}
                percentage={{ color: "info", text: `(${currentStatus.toLowerCase()})` }}
                icon={{ color: "info", component: "person" }}
                showNavigation={true}
                onPrevClick={() => handleStatusChange("prev")}
                onNextClick={() => handleStatusChange("next")}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Enrollments" }}
                count={enrollmentCount}
                percentage={{ color: "info", text: `(${enrollmentType.toLowerCase()})` }}
                icon={{
                  color: "info",
                  component: "person",
                }}
                showNavigation={true}
                onPrevClick={() => handleEnrollmentTypeChange("prev")}
                onNextClick={() => handleEnrollmentTypeChange("next")}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <ReportsBarChart
                title="Employees"
                description={
                  <>
                    Employee distribution across departments
                  </>
                }
                chart={{
                  labels: departmentChartData.labels,
                  datasets: departmentChartData.datasets,
                }}
                items={departmentItems}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <GradientLineChart
                title="Expense Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color={calculateExpenseTrend() > 0 ? "error" : "success"} mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">
                        {calculateExpenseTrend() > 0 ? "arrow_upward" : "arrow_downward"}
                      </Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      {Math.abs(calculateExpenseTrend())}% {calculateExpenseTrend() > 0 ? "increase" : "decrease"}{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        compared to last month
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                chart={expenseChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;