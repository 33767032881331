import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import AssignTeacherDialog from './AssignTeacher';


const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

const TeacherInfo = ({ name }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

TeacherInfo.propTypes = {
    name: PropTypes.string.isRequired,
};

const CoursesTable = () => {
    const [assignments, setAssignments] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentAssignment, setCurrentAssignment] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    const classLevels = [
        "FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH",
        "SIXTH", "SEVENTH", "EIGHTH", "NINTH", "TENTH","PROFESSIONAL1","PROFESSIONAL2"
    ];

    // Keep all existing functions unchanged
    const fetchExistingAssignments = async () => {
        try {
            const response = await axios.get(`${APIData.api}courses/by-org?org=${org}`, {
                headers: APIData.headers,
            });
            const existingAssignments = response.data.map((assignment) => ({
                id: assignment.id || 0,
                courseId: assignment.courseId,
                teacherName: assignment.teacherName,
                classLevels: assignment.classLevels,
                courseName: assignment.courseName || '',
                teacherId: assignment.teacherId || '',
                createDateTime: assignment.createDateTime || new Date().toISOString(),
                createdBy: assignment.createdBy || '',
                org: assignment.org || '',
                updateDateTime: new Date().toISOString(),
                updatedBy: sessiondetails.userName,
            }));
            setAssignments(existingAssignments);
        } catch (error) {
            console.error('Error fetching existing assignments:', error.response || error.message);
            // toast.error('Data not Exists', error.response || error.message);
            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Data not Exists! Assign the Courses",
                footer: error.response || error.message
            });
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${APIData.api}employee/empdetailsByDepartment?department=ACADEMY&org=${org}`, {
                headers: APIData.headers,
            });
            setEmployees(response.data.map(employee => ({
                teacherId: employee.employee_id,
                teacherName: employee.name,
            })));
        } catch (error) {
            console.error("Error fetching employees:", error.response || error.message);
            // toast.error('Failed to fetch teachers.');
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to fetch teachers.",
                footer: error.response || error.message
            });
        }
    };

    const handleUpdate = async (id) => {
        try {
            const response = await axios.put(`${APIData.api}courses/${id}`, currentAssignment, { headers: APIData.headers });
            toast.success('Assignment updated successfully!' || response.status);
            fetchExistingAssignments();
            setOpenDialog(false);
        } catch (error) {
            console.error('Error updating assignment:', error.response || error.message);
            toast.error('Failed to update assignment.');
        }
    };

    const handleOpenDialog = (assignment) => {
        setCurrentAssignment({
            ...assignment,
            updateDateTime: new Date().toISOString(),
            updatedBy: sessiondetails.userName,
        });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentAssignment(null);
    };

    const classLevelOptions = classLevels.map(level => ({
        value: level,
        label: level
    }));

    const teacherOptions = employees.map(employee => ({
        value: employee.teacherName,
        label: employee.teacherName
    }));

    // ... [keep all existing functions but update handleChange]

    const handleChange = (name, value) => {
        if (name === "teacherName") {
            const selectedTeacher = employees.find(emp => emp.teacherName === value);
            setCurrentAssignment((prev) => ({
                ...prev,
                teacherName: value,
                teacherId: selectedTeacher ? selectedTeacher.teacherId : "",
            }));
        } else {
            setCurrentAssignment((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const filteredAssignments = assignments.filter(assignment =>
        Object.values(assignment).some(value =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const paginatedAssignments = filteredAssignments.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    useEffect(() => {
        fetchExistingAssignments();
        fetchEmployees();
    }, []);

    const shouldShowActions = () => {
        if (permission === 1100 || permission === 1000) {
            return false;
        }
        return isSuperAdmin || userDepartment === "ACADEMY";
    };

    const columns = [
        { name: "Teacher", align: "left" },
        { name: "Course ID", align: "left" },
        { name: "Class Level", align: "center" },
        ...(shouldShowActions() ? [{ name: "Action", align: "center" }] : []),

    ];

    const rows = paginatedAssignments.map((assignment) => ({
        "Teacher": (
            <TeacherInfo name={assignment.teacherName} />
        ),
        "Course ID": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {assignment.courseId}
            </SoftTypography>
        ),
        "Class Level": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {assignment.classLevels}
            </SoftTypography>
        ),
        "Action": (
            <SoftButton
                variant="text"
                color="info"
                onClick={() => handleOpenDialog(assignment)}
                iconOnly
            >
                <EditIcon />
            </SoftButton>
        ),
    }));

    return (
        <DashboardLayout>
            {/* <DashboardNavbar /> */}
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card sx={{ maxWidth: '1000px', margin: '0 auto' }}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftBox display="flex" alignItems="center">
                                <SoftTypography variant="h6" sx={{ ml: 2 }}>Courses</SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" gap={2}>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                                {(shouldShowActions()) ?
                                    <SoftButton variant="gradient" color="info" onClick={() => setDialogOpen(true)}>
                                        New
                                    </SoftButton> : ""}

                            </SoftBox>
                        </SoftBox>

                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>

                        <SoftBox p={3}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, filteredAssignments.length)} of {filteredAssignments.length}
                                    </SoftTypography>
                                </Grid>
                                {/* <Grid item>
                                    <SoftBox display="flex" gap={2}>
                                        <SoftButton
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                        >
                                            Previous
                                        </SoftButton>
                                        <SoftButton
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredAssignments.length / rowsPerPage) - 1, prev + 1))}
                                            disabled={(page + 1) * rowsPerPage >= filteredAssignments.length}
                                        >
                                            Next
                                        </SoftButton>
                                    </SoftBox>
                                </Grid> */}

                                <Grid item>
                                    <SoftBox display="flex" gap={2}>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                        >
                                            <KeyboardArrowLeft />
                                        </SoftButton>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredAssignments.length / rowsPerPage) - 1, prev + 1))}
                                            disabled={(page + 1) * rowsPerPage >= filteredAssignments.length}
                                        >
                                            <KeyboardArrowRight />
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>

            <AssignTeacherDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSubmitSuccess={(assignments) => {
                    // Optional: Do something after successful submission
                    console.log('Assignments submitted:', assignments);
                }}
            />
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle>
                    <SoftTypography variant="h6">Update Assignment</SoftTypography>
                </DialogTitle>
                <DialogContent>
                    {currentAssignment && (
                        <SoftBox display="flex" flexDirection="column" gap={2} mt={1}>
                            <SoftInput
                                label="Course ID"
                                type="text"
                                value={currentAssignment.courseId}
                                disabled
                            />
                            <CustomSelect
                                options={teacherOptions}
                                value={{ value: currentAssignment.teacherName, label: currentAssignment.teacherName }}
                                onChange={(selectedOption) => handleChange("teacherName", selectedOption.value)}
                                placeholder="Select Teacher"

                            />
                            <CustomSelect
                                options={classLevelOptions}
                                value={{ value: currentAssignment.classLevels, label: currentAssignment.classLevels }}
                                onChange={(selectedOption) => handleChange("classLevels", selectedOption.value)}
                                placeholder="Select Class Level"

                            />
                        </SoftBox>
                    )}
                </DialogContent>
                <DialogActions>
                    <SoftButton onClick={handleCloseDialog} variant="text" color="secondary">
                        Cancel
                    </SoftButton>
                    <SoftButton
                        onClick={() => handleUpdate(currentAssignment.id)}
                        variant="gradient"
                        color="info"
                    >
                        Update
                    </SoftButton>
                </DialogActions>
            </Dialog>
            {/* <Footer /> */}
        </DashboardLayout>
    );
};

export default CoursesTable;