import React, { useEffect, useState } from 'react';
import { APIData, org } from '../authentication/APIData';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

// Soft UI Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material UI Components
import {
    Grid,
    Card,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Box,
    FormHelperText
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SoftInput from 'components/SoftInput';

const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

const MAX_IMAGE_SIZE = 1024 * 1024; // 1MB
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
const MIN_QUESTION_LENGTH = 10;
const MAX_QUESTION_LENGTH = 1000;

export const ViewQuestions = () => {
    // Existing state variables
    const [data, setData] = useState(null);
    const [data1, setData1] = useState(null);
    const [grades, setGrades] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedChapter, setSelectedChapter] = useState('');
    const [loading, setLoading] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [updatedQuestion, setUpdatedQuestion] = useState('');
    const [uploadedImage, setUploadedImage] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageError, setImageError] = useState('');
    const [gradeNameMap, setGradeNameMap] = useState({});
    const [isLoadingGrades, setIsLoadingGrades] = useState(true);

    // Function to fetch course name by ID
    const fetchCourseName = async (courseId) => {
        try {
            const response = await fetch(`${APIData.api}courses/${courseId}`, {
                headers: APIData.headers
            });
            if (response.ok) {
                const data = await response.json();
                // Check if data exists and has a course_name or name property
                return data?.courseName || data?.courseName || courseId;
            }
            return courseId;
        } catch (error) {
            console.error(`Error fetching course name for ID ${courseId}:`, error);
            return courseId;
        }
    };


    // New validation state variables
    const [errors, setErrors] = useState({
        grade: '',
        subject: '',
        chapter: '',
        question: '',
        image: ''
    });

    const navigate = useNavigate();

    // Validation functions
    const validateGrade = (grade) => {
        if (!grade) {
            return 'Grade is required';
        }
        return '';
    };

    const validateSubject = (subject) => {
        if (!subject) {
            return 'Subject is required';
        }
        return '';
    };

    const validateChapter = (chapter) => {
        if (!chapter) {
            return 'Chapter is required';
        }
        return '';
    };

    const validateQuestion = (question) => {
        if (!question) {
            return 'Question is required';
        }
        if (question.length < MIN_QUESTION_LENGTH) {
            return `Question must be at least ${MIN_QUESTION_LENGTH} characters long`;
        }
        if (question.length > MAX_QUESTION_LENGTH) {
            return `Question cannot exceed ${MAX_QUESTION_LENGTH} characters`;
        }
        return '';
    };

    const validateImage = (file) => {
        if (file) {
            if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
                return 'Only JPEG, PNG, and GIF images are allowed';
            }
            if (file.size > MAX_IMAGE_SIZE) {
                return 'Image size must be less than 1MB';
            }
        }
        return '';
    };

    // Modified handlers with validation
    // const handleGradeChange = (option) => {
    //     const value = option?.value || '';
    //     setSelectedGrade(value);
    //     setErrors(prev => ({
    //         ...prev,
    //         grade: validateGrade(value)
    //     }));
    //     // Reset dependent fields
    //     setSelectedSubject('');
    //     setSelectedChapter('');
    // };

    const handleSubjectChange = (option) => {
        const value = option?.value || '';
        setSelectedSubject(value);
        setErrors(prev => ({
            ...prev,
            subject: validateSubject(value)
        }));
        // Reset dependent field
        setSelectedChapter('');
    };

    const handleChapterChange = (option) => {
        const value = option?.value || '';
        setSelectedChapter(value);
        setErrors(prev => ({
            ...prev,
            chapter: validateChapter(value)
        }));
    };

    const handleQuestionChange = (e) => {
        const value = e.target.value;
        setUpdatedQuestion(value);
        setErrors(prev => ({
            ...prev,
            question: validateQuestion(value)
        }));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageError = validateImage(file);
            setErrors(prev => ({
                ...prev,
                image: imageError
            }));

            if (!imageError) {
                setUploadedImage(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreview(reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                setUploadedImage(null);
                setImagePreview(null);
            }
        }
    };

    // Modified submit handlers with validation
    const handleSubmit = async () => {
        // Validate all fields
        const validationErrors = {
            grade: validateGrade(selectedGrade),
            subject: validateSubject(selectedSubject),
            chapter: validateChapter(selectedChapter)
        };

        setErrors(prev => ({
            ...prev,
            ...validationErrors
        }));

        // Check if there are any validation errors
        if (Object.values(validationErrors).some(error => error)) {
            Swal.fire('Error', 'Please fix all validation errors before submitting', 'error');
            return;
        }

        setLoading(true);
        const url = `${APIData.api}questions/grade-subject-chapter?grade=${selectedGrade}&subject=${selectedSubject}&chapter=${selectedChapter}&org=${org}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: APIData.headers
            });
            const jsonData = await response.json();
            setData1(jsonData);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch questions', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateQuestion = async () => {
        if (!selectedQuestion) return;

        // Validate question and image
        const validationErrors = {
            question: validateQuestion(updatedQuestion),
            image: validateImage(uploadedImage)
        };

        setErrors(prev => ({
            ...prev,
            ...validationErrors
        }));

        // Check if there are any validation errors
        if (Object.values(validationErrors).some(error => error)) {
            Swal.fire('Error', 'Please fix all validation errors before updating', 'error');
            return;
        }

        setLoading(true);
        const url = `${APIData.api}questions/?id=${selectedQuestion.id}`;
        const formData = new FormData();
        formData.append('question', updatedQuestion);
        formData.append('grade', selectedQuestion.grade);
        formData.append('question_type', selectedQuestion.question_type);
        formData.append('imp_question', selectedQuestion.imp_question);
        formData.append('subject', selectedQuestion.subject);
        formData.append('chapter', selectedQuestion.chapter);
        formData.append('updated_by', sessiondetails.user);
        formData.append('org', org);

        if (uploadedImage) {
            formData.append('image', uploadedImage);
        }

        try {
            const response = await axios.put(url, formData, {
                headers: { ...APIData.headers, 'Content-Type': 'multipart/form-data' }
            });

            if (response.status === 201) {
                Swal.fire('Success', 'Question updated successfully', 'success');
                setEditDialogOpen(false);
                window.location.reload();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to update question', 'error');
        } finally {
            setLoading(false);
        }
    };

    // Rest of the existing code remains the same
    useEffect(() => {
        const fetchQuestionsData = async () => {
            setLoading(true);
            const url = APIData.api + `questions/?org=${org}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });
                const jsonData = await response.json();
                setData(jsonData);
                
                // Get unique grades
                const uniqueGrades = [...new Set(jsonData.map(item => item.grade))];
                setGrades(uniqueGrades);
                
                // Initialize course name fetching
                const nameMap = {};
                await Promise.all(
                    uniqueGrades.map(async (gradeId) => {
                        if (gradeId) {  // Only fetch if gradeId exists
                            const courseName = await fetchCourseName(gradeId);
                            nameMap[gradeId] = courseName;
                        }
                    })
                );
                
                console.log('Course Name Map:', nameMap); // Debug log
                setGradeNameMap(nameMap);
            } catch (error) {
                console.error('Error fetching questions data:', error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch questions data",
                });
            } finally {
                setLoading(false);
                setIsLoadingGrades(false);
            }
        };

        fetchQuestionsData();
    }, []);

    useEffect(() => {
        if (selectedGrade) {
            const filteredSubjects = [...new Set(data.filter(item => item.grade === selectedGrade).map(item => item.subject))];
            setSubjects(filteredSubjects);
        }
    }, [selectedGrade, data]);

    useEffect(() => {
        if (selectedGrade && selectedSubject) {
            const filteredChapters = [...new Set(data.filter(item =>
                item.grade === selectedGrade && item.subject === selectedSubject).map(item => item.chapter))];
            setChapters(filteredChapters);
        }
    }, [selectedGrade, selectedSubject, data]);

    const handleEdit = (item) => {
        setSelectedQuestion(item);
        setUpdatedQuestion(item.question);
        setImagePreview(item.image_url);
        setEditDialogOpen(true);
        // Reset errors when opening edit dialog
        setErrors({
            grade: '',
            subject: '',
            chapter: '',
            question: '',
            image: ''
        });
    };

    const handleDelete = (itemId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                performDelete(itemId);
            }
        });
    };

    const performDelete = async (itemId) => {
        setLoading(true);
        const url = `${APIData.api}questions/?id=${itemId}`;
        try {
            const response = await axios.delete(url, { headers: APIData.headers });
            if (response.status === 200) {
                Swal.fire('Deleted!', 'Question has been deleted.', 'success');
                window.location.reload();
            }
        } catch (error) {
            Swal.fire('Error!', 'Failed to delete question.', 'error');
        } finally {
            setLoading(false);
        }
    };

    const gradeOptions = React.useMemo(() => {
        if (isLoadingGrades) {
            return [{ value: '', label: 'Loading grades...' }];
        }
        
        return grades.map(grade => {
            const courseName = gradeNameMap[grade];
            console.log(`Mapping grade ${grade} to name ${courseName}`); // Debug log
            return {
                value: grade,
                label: courseName || grade
            };
        });
    }, [grades, gradeNameMap, isLoadingGrades]);
    const subjectOptions = subjects.map(subject => ({ value: subject, label: subject }));
    const chapterOptions = chapters.map(chapter => ({ value: chapter, label: chapter }));

    const handleGradeChange = (option) => {
        const value = option?.value || '';
        console.log('Selected grade:', value); // Debug log
        setSelectedGrade(value);
        setErrors(prev => ({
            ...prev,
            grade: validateGrade(value)
        }));
        setSelectedSubject('');
        setSelectedChapter('');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/dashboard">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Question Paper Editor
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={2} pb={3} px={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" mb={1}>Grade</SoftTypography>
                                        <CustomSelect
                                            options={gradeOptions}
                                            placeholder={isLoadingGrades ? "Loading grades..." : "Select Grade"}
                                            name="grade"
                                            onChange={handleGradeChange}
                                            value={gradeOptions.find(option => option.value === selectedGrade) || null}
                                            isDisabled={isLoadingGrades}
                                            isLoading={isLoadingGrades}
                                        />
                                        {errors.grade && (
                                            <FormHelperText error>{errors.grade}</FormHelperText>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" mb={1}>Subject</SoftTypography>
                                        <CustomSelect
                                            options={subjectOptions}
                                            placeholder="Select Subject"
                                            name="subject"
                                            onChange={handleSubjectChange}
                                            value={selectedSubject}
                                            isDisabled={!selectedGrade}
                                        />
                                        {errors.subject && (
                                            <FormHelperText error>{errors.subject}</FormHelperText>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" mb={1}>Chapter</SoftTypography>
                                        <CustomSelect
                                            options={chapterOptions}
                                            placeholder="Select Chapter"
                                            name="chapter"
                                            onChange={handleChapterChange}
                                            value={selectedChapter}
                                            isDisabled={!selectedSubject}
                                        />
                                        {errors.chapter && (
                                            <FormHelperText error>{errors.chapter}</FormHelperText>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox mt={2} mb={2} textAlign="center">
                                        <SoftButton
                                            variant="gradient"
                                            color="info"
                                            onClick={handleSubmit}
                                            disabled={!selectedGrade || !selectedSubject || !selectedChapter}
                                        >
                                            View Questions
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>

                                {data1 && data1.length > 0 && (
                                    <Grid item xs={12}>
                                        <SoftBox>
                                            {data1.map((item, index) => (
                                                <Card key={index} sx={{ mb: 2, p: 2 }}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={9}>
                                                            <SoftTypography variant="body2">
                                                                {item.question}
                                                            </SoftTypography>
                                                            {item.image_url && (
                                                                <Box mt={2}>
                                                                    <img
                                                                        src={item.image_url}
                                                                        alt="Question"
                                                                        style={{ maxWidth: "100%", height: "auto", maxHeight: "200px" }}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <SoftBox display="flex" justifyContent="center" gap={2}>
                                                                <IconButton onClick={() => handleEdit(item)}>
                                                                    <EditIcon color="info" />
                                                                </IconButton>
                                                                <IconButton onClick={() => handleDelete(item.id)}>
                                                                    <DeleteIcon color="error" />
                                                                </IconButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            ))}
                                        </SoftBox>
                                    </Grid>
                                )}
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>

            <Dialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <SoftTypography variant="h6">Edit Question</SoftTypography>
                </DialogTitle>
                <DialogContent>
                    <SoftBox mb={2}>
                        <SoftTypography variant="subtitle2" mb={1}>
                            Question Text
                            <span style={{ color: 'red' }}>*</span>
                        </SoftTypography>
                        <SoftInput
                            value={updatedQuestion}
                            onChange={handleQuestionChange}
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            error={!!errors.question}
                        />
                        {errors.question && (
                            <FormHelperText error>{errors.question}</FormHelperText>
                        )}
                        <SoftTypography variant="caption" color="text">
                            {updatedQuestion.length}/{MAX_QUESTION_LENGTH} characters
                        </SoftTypography>
                    </SoftBox>

                    <SoftBox mb={2}>
                        <SoftTypography variant="subtitle2" mb={1}>
                            Question Image
                        </SoftTypography>
                        <input
                            type="file"
                            accept={ALLOWED_IMAGE_TYPES.join(',')}
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                            id="image-upload"
                        />
                        <label htmlFor="image-upload">
                            <SoftButton
                                component="span"
                                variant="outlined"
                                color="info"
                                size="small"
                            >
                                Upload New Image
                            </SoftButton>
                        </label>
                        {errors.image && (
                            <FormHelperText error>{errors.image}</FormHelperText>
                        )}
                        <SoftTypography variant="caption" color="text" display="block" mt={1}>
                            Allowed formats: JPEG, PNG, GIF. Max size: 1MB
                        </SoftTypography>
                    </SoftBox>

                    {(imagePreview || selectedQuestion?.image_url) && (
                        <SoftBox mb={2}>
                            <img
                                src={imagePreview || selectedQuestion?.image_url}
                                alt="Question"
                                style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                        </SoftBox>
                    )}
                </DialogContent>
                <DialogActions>
                    <SoftButton
                        onClick={() => {
                            setEditDialogOpen(false);
                            setErrors({
                                grade: '',
                                subject: '',
                                chapter: '',
                                question: '',
                                image: ''
                            });
                        }}
                        variant="text"
                        color="dark"
                    >
                        Cancel
                    </SoftButton>
                    <SoftButton
                        onClick={handleUpdateQuestion}
                        variant="gradient"
                        color="info"
                        disabled={loading || !!errors.question || !!errors.image}
                    >
                        {loading ? "Updating..." : "Update"}
                    </SoftButton>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ToastContainer />
        </DashboardLayout>
    );
};

export default ViewQuestions;