import React from 'react';
import logo from '../../assets/images/c4e.png';
import { Typography, Paper } from '@mui/material';

const EmpExit = () => {

    return (
        <>
            <Typography variant="h5" gutterBottom>
            Default Template
            </Typography>
            <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                <div style={styles.container}>
                    <img src={logo} alt="Company Logo" style={styles.logo} />
                    <h3 style={{ fontSize: "16px" }}>Care4Edu Solutions Pvt Ltd</h3>
                    <div style={styles.companyDetails}>
                        <p>+91 7259785184</p>
                        <p>info@care4edu.com</p>
                        <p>www.care4edu.com</p>
                    </div>
                    <div style={styles.confidentialDetails}>
                        <h2 style={styles.header1}><center>STRICTLY CONFIDENTIAL</center></h2>
                        <p>Name of the Employee: Adhithyan P A
                        </p>
                        <p>Employee ID: c4e2024adhithyan__p_a
                        </p>
                    </div>
                    <div style={styles.companyDetails}>
                        Dear,
                    </div>
                    <h3 style={styles.header1}><center>EXIT APPRAISAL</center></h3>
                    <p>
                        As you leave  <strong>Care4Edu Solutions Pvt Ltd.</strong>, we shall appreciate your free and frank comments on some
                        aspects of your employment in the company. We very much value your objective feedback to look at our
                        own policies, and practices. Please be rest assured that your comments will remain strictly confidential.
                        When you complete this form, please return it in an envelope marked ‘STRICTLY CONFIDENTIAL’
                        EITHER TO YOUR Department Head or Head – HR or to the Executive Director, directly, as you deem fit
                        and proper.
                    </p>
                    <div style={styles.HRsign}>
                        <p>HR Signature</p>
                    </div>
                    <h3 style={styles.header1}>EMPLOYEE DETAILS (To be filled by HR)</h3>

                    <div style={styles.flexContainer}>
                        <div style={styles.flexItem}>
                            <p><strong>Job Title:</strong> Developer</p>
                        </div>
                        <div style={styles.flexItem}>
                            <p><strong>Group/Grade:</strong> _____________</p>
                        </div>
                        <div style={styles.flexItem}>
                            <p><strong>Dept:</strong> TECHNICAL</p>
                        </div>
                    </div>

                    {/* Flexbox container for Date of joining to Last day */}
                    <div style={styles.flexContainer}>
                        <div style={styles.flexItem}>
                            <p><strong>Date of joining service:</strong> 06/08/2024</p>
                        </div>
                        <div style={styles.flexItem}>
                            <p><strong>Date of confirmation:</strong> ___________</p>
                        </div>

                    </div>
                    <div style={styles.flexItem}>
                        <p><strong>Last day of service:</strong> 20/08/2024</p>
                    </div>

                    <h3 style={styles.header1}>To be filled by employee</h3>
                    <ol style={styles.list}>
                        <li style={styles.listItem1}>Reasons for separation (better job opportunity / family obligation/ lack of advancement/ salary and
                            perquisites / health / further studies/ supervision, etc.)Please explain in detail;</li>
                        <li style={styles.listItem1}>When you started working for the Company how long did you intend to stay and what were your expectations?</li>
                        <li style={styles.listItem1}>Under what conditions would you have stayed back with the Company?</li>
                        <li style={styles.listItem1}>What sort of employment are you taking up now and at what additional benefits/compensation/conditions?</li>
                        <li style={styles.listItem}>Rate the following;(Please give your descriptive remarks) </li>
                        <h3 style={styles.header2}>Excellent Good Average Poor Remarks</h3>
                        <ol style={styles.subList}>
                            <li style={styles.listItem}>Working relationship with your Supervisor/Manager</li>
                            <li style={styles.listItem}>Your compensation and service Conditions</li>
                            <li style={styles.listItem}>Your training/on the job development</li>
                            <li style={styles.listItem}>Your promotional opportunities</li>
                            <li style={styles.listItem}>Handling of your grievances/problems</li>
                            <li style={styles.listItem}>Any other factors</li>
                        </ol>
                        <li style={styles.listItem}>What did you like most about:</li>
                        <ol style={styles.subList}>
                            <div style={styles.row}>
                                <li style={styles.listItem2}>Your Job:</li>
                                <li style={styles.listItem2}>The Company:</li>
                            </div>
                            <div style={styles.row}>
                                <li style={styles.listItem2}>Your Supervisor:</li>
                                <li style={styles.listItem2}>Colleagues in your Department:</li>
                            </div>
                        </ol>
                        <li style={styles.listItem}>What did you like least about:</li>
                        <ol style={styles.subList}>
                            <div style={styles.row}>
                                <li style={styles.listItem2}>Your Job:</li>
                                <li style={styles.listItem2}>The Company:</li>
                            </div>
                            <div style={styles.row}>
                                <li style={styles.listItem2}>Your Supervisor:</li>
                                <li style={styles.listItem2}>Colleagues in your Department:</li>
                            </div>
                        </ol>
                        <li style={styles.listItem3}>Any other comments, remarks, and suggestions:</li>
                        <li style={styles.listItem1}>Would you wish to rejoin (Company) should an offer be made in the future? Yes/No Why?</li>
                    </ol>
                    <div style={styles.row}>
                        <p style={styles.signature}>
                            (Adhithyan P A)
                            <br />
                            Signature
                        </p>
                        <p style={styles.signature}>
                            Date:20/08/2024
                        </p>
                    </div>
                    <p style={styles.signature}>HR Comments:</p>
                    <textarea style={styles.textarea} />
                    <p style={styles.signature1}>
                        HR Signature:
                    </p>

                </div>
            </Paper >
        </>
    );
};

const styles = {
    container: {
        maxWidth: '700px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },
    header: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    header1: {
        fontSize: '15px',
        fontWeight: 'bold',
        marginBottom: '10px',
        textAlign: "center   "
    },
    header2: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '10px',
        textAlign: "right"
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },
    flexItem: {
        flex: '1',
        padding: '10px',
    },
    companyDetails: {
        fontSize: '14px',
        lineHeight: '1.6',
    },
    confidentialDetails: {
        fontSize: '14px',
        fontWeight: "bold",
        marginBottom: "20px"
    },
    HRsign: {
        fontSize: '14px',
        fontWeight: "bold",
        marginBottom: "40px",
        marginTop: "60px"
    },
    list: {
        paddingLeft: '20px',
        marginBottom: "40px",
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#333',
    },
    listItem: {
        marginBottom: '15px',
    },
    listItem1: {
        marginBottom: '50px',
    },
    listItem3: {
        marginBottom: '70px',
    },
    listItem2: {
        flex: 1,
        margin: '0 10px',
        boxSizing: 'border-box'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    subList: {
        paddingLeft: '40px',
        marginTop: '10px',
        marginBottom: '20px',
        fontSize: '14px',
        color: '#555',
        listStyleType: 'lower-alpha',
    },

    flexItem: {
        width: '50%',
        marginBottom: '15px',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        color: '#555',
    },
    input: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ddd',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ddd',
        height: '100px',
    },
    signature: {
        fontSize: '14px',
        marginBottom: '10px',
    },
    signature1: {
        fontSize: '14px',
        marginBottom: '10px',
        marginTop:"30px"
    },
};

export default EmpExit;