// /**
// =========================================================
// * Soft UI Dashboard React - v4.0.1
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// // @mui material components
// import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// // Soft UI Dashboard React components
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

// // Soft UI Dashboard React examples
// import TimelineItem from "examples/Timeline/TimelineItem";

// function OrdersOverview() {
//   return (
//     <Card className="h-100">
//       <SoftBox pt={3} px={3}>
//         <SoftTypography variant="h6" fontWeight="medium">
//           Orders overview
//         </SoftTypography>
//         <SoftBox mt={1} mb={2}>
//           <SoftTypography variant="button" color="text" fontWeight="regular">
//             <SoftTypography display="inline" variant="body2" verticalAlign="middle">
//               <Icon sx={{ fontWeight: "bold", color: ({ palette: { success } }) => success.main }}>
//                 arrow_upward
//               </Icon>
//             </SoftTypography>
//             &nbsp;
//             <SoftTypography variant="button" color="text" fontWeight="medium">
//               24%
//             </SoftTypography>{" "}
//             this month
//           </SoftTypography>
//         </SoftBox>
//       </SoftBox>
//       <SoftBox p={2}>
//         <TimelineItem
//           color="success"
//           icon="notifications"
//           title="$2400, Design changes"
//           dateTime="22 DEC 7:20 PM"
//         />
//         <TimelineItem
//           color="error"
//           icon="inventory_2"
//           title="New order #1832412"
//           dateTime="21 DEC 11 PM"
//         />
//         <TimelineItem
//           color="info"
//           icon="shopping_cart"
//           title="Server payments for April"
//           dateTime="21 DEC 9:34 PM"
//         />
//         <TimelineItem
//           color="warning"
//           icon="payment"
//           title="New card added for order #4395133"
//           dateTime="20 DEC 2:20 AM"
//         />
//         <TimelineItem
//           color="primary"
//           icon="vpn_key"
//           title="New card added for order #4395133"
//           dateTime="18 DEC 4:54 AM"
//         />
//         <TimelineItem color="dark" icon="paid" title="New order #9583120" dateTime="17 DEC" />
//       </SoftBox>
//     </Card>
//   );
// }

// export default OrdersOverview;


import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";

function OrdersOverview() {
  const [procurementData, setProcurementData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Dynamic color assignment based on index to ensure we always have a color
  const colors = ["info", "warning", "success", "error", "primary", "secondary", "dark"];
  const getStatusColor = (index) => colors[index % colors.length];

  // Default icons that can be used for any status
  const defaultIcons = [
    "inbox",
    "pending",
    "check_circle",
    "cancel",
    "history",
    "update",
    "assignment",
    "track_changes",
    "work",
    "schedule",
  ];
  
  // Now we'll use the original index (before reversal) to get the icon
  const getStatusIcon = (originalIndex) => defaultIcons[originalIndex % defaultIcons.length];

  useEffect(() => {
    const fetchProcurementData = async () => {
      try {
        const response = await fetch(
          `${APIData.api}procurement/life-cycle/all?org=${org}`,
          { headers: APIData.headers }
        );
        
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // Sort data by id in ascending order first to establish the original order
        const sortedData = data.sort((a, b) => a.id - b.id);
        // Add original index to each item before reversing
        const dataWithIndices = sortedData.map((item, index) => ({
          ...item,
          originalIndex: index
        }));
        // Reverse the array while preserving the original indices
        const reversedData = dataWithIndices.reverse();
        setProcurementData(reversedData);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchProcurementData();
  }, []);

  const formatDateTime = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
    } catch (error) {
      return dateString; // Return original string if parsing fails
    }
  };

  return (
    <Card className="h-100">
      <SoftBox pt={3} px={3}>
        <SoftTypography variant="h6" fontWeight="medium">
          Procurement Overview
        </SoftTypography>
        <SoftBox mt={1} mb={2}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            <SoftTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ fontWeight: "bold", color: ({ palette: { info } }) => info.main }}>
                update
              </Icon>
            </SoftTypography>
            &nbsp;
            <SoftTypography variant="button" color="text" fontWeight="medium">
              Status Count:
            </SoftTypography>{" "}
            {procurementData.length} statuses
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={2}>
        {isLoading && (
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Loading procurement data...
          </SoftTypography>
        )}
        {error && (
          <SoftTypography variant="button" color="error" fontWeight="regular">
            Error loading data: {error}
          </SoftTypography>
        )}
        {procurementData.map((item, currentIndex) => (
          <TimelineItem
            key={item.id}
            color={getStatusColor(item.originalIndex)} // Use originalIndex for consistent colors
            icon={getStatusIcon(item.originalIndex)} // Use originalIndex for consistent icons
            title={`${item.statusName} - ${item.statusType}`}
            description={`Updated by: ${item.updatedBy}`}
          />
        ))}
      </SoftBox>
    </Card>
  );
}

export default OrdersOverview;