// /**
// =========================================================
// * Soft UI Dashboard React - v4.0.1
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// import { useState } from "react";

// // @mui material components
// import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

// // Soft UI Dashboard React components
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

// // Soft UI Dashboard Materail-UI example components
// import Table from "examples/Tables/Table";

// // Data
// import data from "layouts/dashboard/components/Projects/data";

// function Projects() {
//   const { columns, rows } = data();
//   const [menu, setMenu] = useState(null);

//   const openMenu = ({ currentTarget }) => setMenu(currentTarget);
//   const closeMenu = () => setMenu(null);

//   const renderMenu = (
//     <Menu
//       id="simple-menu"
//       anchorEl={menu}
//       anchorOrigin={{
//         vertical: "top",
//         horizontal: "left",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//       open={Boolean(menu)}
//       onClose={closeMenu}
//     >
//       <MenuItem onClick={closeMenu}>Action</MenuItem>
//       <MenuItem onClick={closeMenu}>Another action</MenuItem>
//       <MenuItem onClick={closeMenu}>Something else</MenuItem>
//     </Menu>
//   );

//   return (
//     <Card>
//       <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
//         <SoftBox>
//           <SoftTypography variant="h6" gutterBottom>
//             Projects
//           </SoftTypography>
//           <SoftBox display="flex" alignItems="center" lineHeight={0}>
//             <Icon
//               sx={{
//                 fontWeight: "bold",
//                 color: ({ palette: { info } }) => info.main,
//                 mt: -0.5,
//               }}
//             >
//               done
//             </Icon>
//             <SoftTypography variant="button" fontWeight="regular" color="text">
//               &nbsp;<strong>30 done</strong> this month
//             </SoftTypography>
//           </SoftBox>
//         </SoftBox>
//         <SoftBox color="text" px={2}>
//           <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
//             more_vert
//           </Icon>
//         </SoftBox>
//         {renderMenu}
//       </SoftBox>
//       <SoftBox
//         sx={{
//           "& .MuiTableRow-root:not(:last-child)": {
//             "& td": {
//               borderBottom: ({ borders: { borderWidth, borderColor } }) =>
//                 `${borderWidth[1]} solid ${borderColor}`,
//             },
//           },
//         }}
//       >
//         <Table columns={columns} rows={rows} />
//       </SoftBox>
//     </Card>
//   );
// }

// export default Projects;


import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { AiOutlinePlusCircle } from "react-icons/ai";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import Table from "examples/Tables/Table";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";

function Projects() {
  const [menu, setMenu] = useState(null);
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await fetch(`${APIData.api}customer-sales/salestype?salesType=OPPURTUNITY&org=${org}`,{ headers: APIData.headers });
        const data = await response.json();
        setSalesData(Array.isArray(data) ? data : [data]);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>View Details</MenuItem>
      <MenuItem onClick={closeMenu}>Edit</MenuItem>
      <MenuItem onClick={closeMenu}>Delete</MenuItem>
    </Menu>
  );

  const columns = [
    { name: "customer", align: "left" },
    { name: "contact", align: "left" },
    { name: "organization", align: "left" },
    { name: "salestype", align: "center" },
  ];

  const filteredData = salesData.filter(sale => 
    sale.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sale.customerEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sale.org_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = filteredData.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const rows = paginatedData.map((sale) => ({
    customer: (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(sale.customerName)}&background=random`}
            alt={sale.customerName}
            size="sm"
            variant="rounded"
          />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {sale.customerName}
          </SoftTypography>
          <SoftTypography variant="caption" color="text">
            {sale.customerAddress}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    ),
    contact: (
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium">
          {sale.customerEmail}
        </SoftTypography>
      </SoftBox>
    ),
    organization: (
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium">
          {sale.org_name}
        </SoftTypography>
      </SoftBox>
    ),
    salestype: (
      <SoftTypography variant="caption" color="info" fontWeight="medium">
        {sale.salesType}
      </SoftTypography>
    ),
  }));

  if (loading) {
    return (
      <Card>
        <SoftBox p={3}>
          <SoftTypography variant="button" color="text">
            Loading sales data...
          </SoftTypography>
        </SoftBox>
      </Card>
    );
  }

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SoftTypography variant="h6">Sales Opportunities</SoftTypography>
        <SoftBox display="flex" alignItems="center" gap={2}>
          <SoftBox>
            <SoftInput
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
        <SoftBox display="flex" justifyContent="flex-end" alignItems="center" p={3}>
          <SoftBox display="flex" gap={1} alignItems="center">
            <SoftTypography variant="caption" color="secondary">
              {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredData.length)} of {filteredData.length}
            </SoftTypography>
            <SoftButton
              variant="text"
              color="info"
              onClick={() => setPage(prev => Math.max(0, prev - 1))}
              disabled={page === 0}
              iconOnly
            >
              <KeyboardArrowLeft />
            </SoftButton>
            <SoftButton
              variant="text"
              color="info"
              onClick={() => setPage(prev => Math.min(Math.ceil(filteredData.length / itemsPerPage) - 1, prev + 1))}
              disabled={page >= Math.ceil(filteredData.length / itemsPerPage) - 1}
              iconOnly
            >
              <KeyboardArrowRight />
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Projects;