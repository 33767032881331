// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import wavesWhite from "assets/images/shapes/waves-white.svg";
// import rocketWhite from "assets/images/illustrations/smarter.png";
// import { useEffect, useState } from "react";
// import { APIData } from "authentication/APIData";
// import { org } from "authentication/APIData";

// function BuildByDevelopers() {

//    const [orgData, setOrgData] = useState("");
//    const [showMore, setShowMore] = useState(false);

//    useEffect(() => {
//     const fetchOrgData = async () => {
//       try {
//         const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch organization data");
//         }
//         const data = await response.json();
//         setOrgData(data);
//       } catch (error) {
//         console.error("Error fetching organization data:", error);
//       }
//     };
//     fetchOrgData()
//   }, [org])

//   const handleReadMore = () => {
//     setShowMore((prev) => !prev);
//   };

//   const aboutContent = orgData.about || [];
//   const displayedContent = showMore ? aboutContent.join(" ") : aboutContent[0];

//   return (
//     <Card>
//       <SoftBox p={2}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} lg={6}>
//             <SoftBox display="flex" flexDirection="column" height="100%">
//               <SoftTypography variant="h4" fontWeight="bold" gutterBottom p={2}>
//                 {orgData.orgName}
//               </SoftTypography>
//               <SoftTypography variant="body2" fontWeight="bold" gutterBottom>
//                 About us :
//               </SoftTypography>
//               <SoftBox mb={6}>
//                 <SoftTypography variant="body2" color="text">
//                 {displayedContent}
//                 </SoftTypography>
//               </SoftBox>
//               <SoftTypography
//                 component="a"
//                 variant="button"
//                 color="text"
//                 fontWeight="medium"
//                 sx={{
//                   mt: "auto",
//                   mr: "auto",
//                   display: "inline-flex",
//                   alignItems: "center",
//                   cursor: "pointer",

//                   "& .material-icons-round": {
//                     fontSize: "1.125rem",
//                     transform: `translate(2px, -0.5px)`,
//                     transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
//                   },

//                   "&:hover .material-icons-round, &:focus  .material-icons-round": {
//                     transform: `translate(6px, -0.5px)`,
//                   },
//                 }}
//               >
//                 {showMore ? "Show Less" : "Read More"}
//                 <Icon sx={{ fontWeight: "bold" }}>
//                   {showMore ? "arrow_upward" : "arrow_forward"}
//                 </Icon>
//               </SoftTypography>
//             </SoftBox>
//           </Grid>
//           <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
//             <SoftBox
//               height="100%"
//               display="outline"
//               justifyContent="center"
//               alignItems="center"
//               bgColor="info"
//               borderRadius="lg"
//               variant="gradient"
//             >
//               <SoftBox
//                 component="img"
//                 src={wavesWhite}
//                 alt="waves"
//                 display="block"
//                 position="absolute"
//                 left={0}
//                 width="100%"
//                 height="100%"
//               />
//               <SoftBox component="img" src={orgData.orgLogo} alt="rocket" width="100%" pt={3} />
//             </SoftBox>
//           </Grid>
//         </Grid>
//       </SoftBox>
//     </Card>
//   );
// }

// export default BuildByDevelopers;


import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/smarter.png";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";

function BuildByDevelopers() {
  const [orgData, setOrgData] = useState("");
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization data");
        }
        const data = await response.json();
        setOrgData(data);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrgData();
  }, [org]);

  const handleReadMore = () => {
    setShowMore((prev) => !prev);
  };

  const aboutContent = orgData.about || [];
  const displayedContent = showMore ? aboutContent : aboutContent.slice(0, 1);

  return (
    <Card>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SoftBox display="flex" flexDirection="column" height="100%">
              <SoftTypography variant="h4" fontWeight="bold" gutterBottom>
                {orgData.orgName}
              </SoftTypography>
              <SoftTypography variant="body2" fontWeight="bold" gutterBottom>
                About us:
              </SoftTypography>
              <SoftBox mb={6}>
                {displayedContent.map((paragraph, index) => (
                  <SoftTypography key={index} variant="body2" color="text" paragraph>
                    {paragraph}
                  </SoftTypography>
                ))}
              </SoftBox>
              <SoftTypography
                component="button"
                variant="button"
                color="text"
                fontWeight="medium"
                onClick={handleReadMore}
                sx={{
                  mt: "auto",
                  mr: "auto",
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                  padding: 0,

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: `translate(2px, -0.5px)`,
                    transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                  },

                  "&:hover .material-icons-round, &:focus  .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                  },
                }}
              >
                {showMore ? "Show Less" : "Read More"}
                <Icon sx={{ fontWeight: "bold" }}>
                  {showMore ? "arrow_upward" : "arrow_forward"}
                </Icon>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
            <SoftBox
              height="100%"
              display="outline"
              justifyContent="center"
              alignItems="center"
              bgColor="light"
              borderRadius="lg"
              variant="gradient"
            >
              <SoftBox
                component="img"
                src={wavesWhite}
                alt="waves"
                display="block"
                position="absolute"
                left={0}
                width="100%"
                height="100%"
              />
              <SoftBox component="img" src={orgData.orgLogo} alt="rocket" width="100%" pt={2} />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BuildByDevelopers;
