import Grid from "@mui/material/Grid";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <SoftBox component="footer" py={0.7}> {/* Further reduced padding to decrease height */}
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <SoftBox display="flex" justifyContent="center" flexWrap="wrap" mb={1}> {/* Reduced bottom margin */}
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography component={Link} to="/company" variant="body2" color="secondary" sx={{ fontSize: '1.25rem' }}>
                Company
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography component={Link} to="/about-us" variant="body2" color="secondary" sx={{ fontSize: '1.25rem' }}>
                About Us
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 0, lg: 3, xl: 6 }}>
              <SoftTypography component={Link} to="/team" variant="body2" color="secondary" sx={{ fontSize: '1.25rem' }}>
                Team
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography component={Link} to="/product" variant="body2" color="secondary" sx={{ fontSize: '1.25rem' }}>
                Product
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <SoftTypography component={Link} to="/blog" variant="body2" color="secondary" sx={{ fontSize: '1.25rem' }}>
                Blog
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              <SoftTypography component={Link} to="/pricing" variant="body2" color="secondary" sx={{ fontSize: '1.25rem' }}>
                Pricing
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <SoftBox display="flex" justifyContent="center" mt={1} mb={1}> {/* Reduced bottom margin */}
            <SoftBox mr={3} color="secondary">
              <a href="https://www.linkedin.com/company/care-4-edu-solutions-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon fontSize="large" /> {/* Increased icon size */}
              </a>
            </SoftBox>

            <SoftBox color="secondary">
              <a href="https://www.instagram.com/care_4_edu_?igsh=YzZoeWlqb2Rid2Vw" target="_blank" rel="noopener noreferrer">
                <InstagramIcon fontSize="large" /> {/* Increased icon size */}
              </a>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Footer;
