import React, { useEffect, useState } from 'react';
import { APIData, org } from '../authentication/APIData';
import axios from 'axios';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import SoftAvatar from 'components/SoftAvatar';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Swal from 'sweetalert2';

const Name = ({ name }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
};

export const RegisterationDetails = () => {
    const [regdata, setRegData] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState("");
    const [updatedTask, setUpdatedTask] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [errors, setErrors] = useState({});
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const fetchedDept = localStorage.getItem("Depart Details");

    let fetchedPrivileges = null;
    let fetchedSupervisor = null;

    if (sessiondetails !== null) {
        if (sessiondetails.userType === "EMPLOYEE") {
            sessiondetails.privileges.forEach(privilege => {
                if (privilege.dept === fetchedDept) {
                    fetchedPrivileges = privilege.privileges;
                    fetchedSupervisor = privilege.supervisor;
                }
            });
        }
        else if (sessiondetails.userType === "user") {
            fetchedPrivileges = "1000";
        }
        else if (sessiondetails.userType === "SUPERADMIN") {
            fetchedPrivileges = "1111";
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const url = APIData.api + `studentadmissiondetails/?org=${org}`;
            const response = await axios.get(url, { headers: APIData.headers });
            setRegData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error(error.message);
            setLoading(false);
        }
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setErrors({});
    };

    const validateForm = () => {
        const newErrors = {};
        if (!updatedTask?.name?.trim()) {
            newErrors.name = 'Name is required';
        }
        if (!updatedTask?.studentClass?.trim()) {
            newErrors.studentClass = 'Class is required';
        }
        if (!updatedTask?.syllabusType?.trim()) {
            newErrors.syllabusType = 'Syllabus Type is required';
        }
        if (!updatedTask?.academicYear?.trim()) {
            newErrors.academicYear = 'Academic Year is required';
        }
        if (!updatedTask?.mobileNo?.trim()) {
            newErrors.mobileNo = 'Mobile Number is required';
        } else if (!/^\d{10}$/.test(updatedTask.mobileNo)) {
            newErrors.mobileNo = 'Invalid mobile number format';
        }
        if (!updatedTask?.address?.trim()) {
            newErrors.address = 'Address is required';
        }
        if (!updatedTask?.schoolOrCollegeName?.trim()) {
            newErrors.schoolOrCollegeName = 'Organization Name is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleEdit = (data) => {
        setSelectedTask(data);
        setUpdatedTask(data);
        setIsDialogOpen(true);
    };

    const handleChange = (field, value) => {
        setUpdatedTask(prevState => ({
            ...prevState,
            [field]: value
        }));
        // Clear error when user starts typing
        if (errors[field]) {
            setErrors(prev => ({ ...prev, [field]: '' }));
        }
    };

    const handleUpdate = async () => {
        if (!validateForm()) {
            toast.error("Please fill all required fields correctly");
            return;
        }

        try {
            const url = APIData.api + `studentadmissiondetails/%7Bid%7D?id=${selectedTask.id}`;
            const response = await axios.put(url, updatedTask, { headers: APIData.headers });
            fetchData();
            // toast.success("Updated Successfully");
            Swal.fire({
                icon: "success",
                title: "Updated Successfully" || response,
                showConfirmButton: false,
                timer: 1500
            });
            setIsDialogOpen(false);
        } catch (error) {
            console.error(error);
            // toast.error(error.message);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to Upadte the DAta.",
                footer:error.message
            });
        }
    };

    // Table configuration
    const getFilteredAndSortedData = () => {
        let filtered = [...regdata].filter(data => {
            const searchStr = searchQuery.toLowerCase();
            return (
                data.name.toLowerCase().includes(searchStr) ||
                data.studentClass.toLowerCase().includes(searchStr) ||
                data.syllabusType.toLowerCase().includes(searchStr) ||
                data.academicYear.toLowerCase().includes(searchStr) ||
                data.mobileNo.toLowerCase().includes(searchStr) ||
                data.schoolOrCollegeName.toLowerCase().includes(searchStr)
            );
        });

        if (sortField) {
            filtered.sort((a, b) => {
                const aValue = a[sortField]?.toLowerCase();
                const bValue = b[sortField]?.toLowerCase();
                return sortDirection === 'asc'
                    ? aValue?.localeCompare(bValue)
                    : bValue?.localeCompare(aValue);
            });
        }

        return filtered;
    };

    const filteredData = getFilteredAndSortedData();
    const paginatedData = filteredData.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "Name", align: "left" },
        { name: "Class", align: "center" },
        { name: "Syllabus", align: "center" },
        { name: "Academic Year", align: "center" },
        { name: "Contact", align: "center" },
        { name: "Organization", align: "left" },
        { name: "Address", align: "left" },
        ...(sessiondetails && fetchedPrivileges &&
            (fetchedPrivileges === "1111" || fetchedPrivileges === "1110")
            ? [{ name: "Action", align: "center" }] : [])
    ];

    const rows = paginatedData.map((data) => ({
        "Name": <Name name={data.name} />,
        Class: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {data.studentClass}
            </SoftTypography>
        ),
        Syllabus: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {data.syllabusType}
            </SoftTypography>
        ),
        "Academic Year": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {data.academicYear}
            </SoftTypography>
        ),
        Contact: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {data.mobileNo}
            </SoftTypography>
        ),
        Organization: (
            <SoftTypography variant="caption" fontWeight="medium" color="text">
                {data.schoolOrCollegeName}
            </SoftTypography>
        ),
        Address: (
            <SoftTypography variant="caption" fontWeight="medium" color="text">
                {data.address}
            </SoftTypography>
        ),
        ...(sessiondetails && fetchedPrivileges &&
            (fetchedPrivileges === "1111" || fetchedPrivileges === "1110")
            ? {
                Action: (
                    <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => handleEdit(data)}
                        size="small"
                    >
                        Edit
                    </SoftButton>
                )
            } : {})
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftBox display="flex" alignItems="center">
                                <SoftTypography variant="h6" sx={{ ml: 2 }}>Registered Students</SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" gap={2}>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    size="small"
                                />
                                {/* <Link to="/studentRegistration" style={{ textDecoration: 'none' }}>
                                    <SoftButton variant="gradient" color="info" size="small" startIcon={<AddIcon />}>
                                        New
                                    </SoftButton>
                                </Link> */}
                            </SoftBox>
                        </SoftBox>

                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>

                        {/* Pagination Controls */}
                        <SoftBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            p={3}
                        >
                            <SoftBox display="flex" gap={1} alignItems="center">
                                <SoftTypography variant="caption" color="secondary">
                                    {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredData.length)} of {filteredData.length}
                                </SoftTypography>
                                <SoftButton
                                    variant="text"
                                    color="info"
                                    onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                    disabled={page === 0}
                                    iconOnly
                                >
                                    <KeyboardArrowLeft />
                                </SoftButton>
                                <SoftButton
                                    variant="text"
                                    color="info"
                                    onClick={() => setPage(prev => Math.min(Math.ceil(filteredData.length / itemsPerPage) - 1, prev + 1))}
                                    disabled={page >= Math.ceil(filteredData.length / itemsPerPage) - 1}
                                    iconOnly
                                >
                                    <KeyboardArrowRight />
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>

            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="md"
            >
                <SoftBox p={3}>
                    <SoftTypography variant="h6" mb={3}>
                        Update Student Details
                    </SoftTypography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                            <SoftTypography variant="h6" component="label" htmlFor="Name">Name</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    placeholder="Name"
                                    value={updatedTask?.name || ""}
                                    onChange={(e) => handleChange('name', e.target.value)}
                                    error={!!errors.name}
                                />
                                {errors.name && (
                                    <SoftTypography variant="caption" color="error">
                                        {errors.name}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                            <SoftTypography variant="h6" component="label" htmlFor="Class">Class</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    placeholder="Class"
                                    value={updatedTask?.studentClass || ""}
                                    onChange={(e) => handleChange('studentClass', e.target.value)}
                                    error={!!errors.studentClass}
                                />
                                {errors.studentClass && (
                                    <SoftTypography variant="caption" color="error">
                                        {errors.studentClass}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                            <SoftTypography variant="h6" component="label" htmlFor="SyllabusType">Syllabus Type</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    placeholder="Syllabus Type"
                                    value={updatedTask?.syllabusType || ""}
                                    onChange={(e) => handleChange('syllabusType', e.target.value)}
                                    error={!!errors.syllabusType}
                                />
                                {errors.syllabusType && (
                                    <SoftTypography variant="caption" color="error">
                                        {errors.syllabusType}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                            <SoftTypography variant="h6" component="label" htmlFor="AcademicYear">Academic Year</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    placeholder="Academic Year"
                                    value={updatedTask?.academicYear || ""}
                                    onChange={(e) => handleChange('academicYear', e.target.value)}
                                    error={!!errors.academicYear}
                                />
                                {errors.academicYear && (
                                    <SoftTypography variant="caption" color="error">
                                        {errors.academicYear}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                            <SoftTypography variant="h6" component="label" htmlFor="MobileNumber">Mobile Number</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    placeholder="Mobile Number"
                                    value={updatedTask?.mobileNo || ""}
                                    onChange={(e) => handleChange('mobileNo', e.target.value)}
                                    error={!!errors.mobileNo}
                                />
                                {errors.mobileNo && (
                                    <SoftTypography variant="caption" color="error">
                                        {errors.mobileNo}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SoftBox mb={2}>
                            <SoftTypography variant="h6" component="label" htmlFor="OrganizationName">Organization Name</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    placeholder="Organization Name"
                                    value={updatedTask?.schoolOrCollegeName || ""}
                                    onChange={(e) => handleChange('schoolOrCollegeName', e.target.value)}
                                    error={!!errors.schoolOrCollegeName}
                                />
                                {errors.schoolOrCollegeName && (
                                    <SoftTypography variant="caption" color="error">
                                        {errors.schoolOrCollegeName}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                            <SoftTypography variant="h6" component="label" htmlFor="Address">Address</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    placeholder="Address"
                                    multiline
                                    rows={3}
                                    value={updatedTask?.address || ""}
                                    onChange={(e) => handleChange('address', e.target.value)}
                                    error={!!errors.address}
                                />
                                {errors.address && (
                                    <SoftTypography variant="caption" color="error">
                                        {errors.address}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
                <DialogActions>
                    <SoftBox display="flex" justifyContent="center" gap={2} width="100%" p={2}>
                        <SoftButton
                            variant="gradient"
                            fullWidth
                            color="secondary"
                            onClick={() => setIsDialogOpen(false)}
                        >
                            Cancel
                        </SoftButton>
                        <SoftButton
                            variant="gradient"
                            fullWidth
                            color="info"
                            onClick={handleUpdate}
                        >
                            Save
                        </SoftButton>
                    </SoftBox>
                </DialogActions>
            </Dialog>
            <Footer />
        </DashboardLayout >
    );
}

export default RegisterationDetails;
