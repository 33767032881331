// import React, { useState } from 'react';
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";
// import SoftInput from "components/SoftInput";
// import { Card, Grid, FormControl, Tooltip } from "@mui/material";
// import { CircularProgress, Backdrop } from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
// import * as AiIcons from "react-icons/ai";
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import { APIData, org } from 'authentication/APIData';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// function HolidayForm() {
//     const [values, setValues] = useState({
//         id: 0,
//         description: '',
//         holidayDate: '',
//         holiday_name: '',
//         org: org
//     });
//     const [errors, setErrors] = useState({});
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     const handleChange = (prop) => (event) => {
//         setValues({ ...values, [prop]: event.target.value });
//         setErrors({ ...errors, [prop]: '' });
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         const errors = {};
//         const today = new Date().toISOString().split('T')[0];

//         // Holiday Name should not contain numbers
//         if (!values.holiday_name.trim()) {
//             errors.holiday_name = 'Holiday name is required';
//         } else if (/\d/.test(values.holiday_name)) {
//             errors.holiday_name = 'Holiday name should not contain numbers';
//         }

//         // Holiday Date should not be in the past
//         if (!values.holidayDate) {
//             errors.holidayDate = 'Holiday date is required';
//         } else if (values.holidayDate < today) {
//             errors.holidayDate = 'Holiday date cannot be in the past';
//         }

//         // Description should be less than 255 characters
//         if (!values.description.trim()) {
//             errors.description = 'Description is required';
//         } else if (values.description.length > 255) {
//             errors.description = 'Description should be less than 255 characters';
//         }

//         if (Object.keys(errors).length === 0) {
//             setLoading(true);
//             try {
//                 await axios.post(APIData.api + 'holidays/', values, { headers: APIData.headers });
//                 Swal.fire("Success", "Holiday Added Successfully", "success");
//                 setTimeout(() => {
//                     navigate("/holiday-list");
//                 }, 1500);
//             } catch (err) {
//                 Swal.fire("Error", err.message || "An error occurred", "error");
//             } finally {
//                 setLoading(false);
//             }
//         } else {
//             setErrors(errors);
//         }
//     };

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
//                 <CircularProgress color="inherit" />
//             </Backdrop>
//             <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
//                 <Grid item xs={12} sm={12} md={10} lg={8}>
//                     <Card className="w-full">
//                         <SoftBox p={3} mb={1} display="flex" alignItems="center">
//                             <Tooltip title="Close" arrow>
//                                 <Link to="/holiday-list">
//                                     <AiIcons.AiOutlineCloseCircle />
//                                 </Link>
//                             </Tooltip>
//                             <SoftTypography mb={2} variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
//                                 Add Holiday Details
//                             </SoftTypography>
//                         </SoftBox>

// <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
//     <Grid container spacing={2}>
//         <Grid item xs={12}>
//             <SoftBox>
//                 <SoftTypography variant="h6">Holiday Name</SoftTypography>
//                 <FormControl fullWidth>
//                     <SoftInput
//                         id="holiday_name"
//                         value={values.holiday_name}
//                         onChange={handleChange('holiday_name')}
//                         error={!!errors.holiday_name}
//                         required
//                     />
//                 </FormControl>
//                 {errors.holiday_name && (
//                     <SoftTypography variant="caption" color="error">
//                         {errors.holiday_name}
//                     </SoftTypography>
//                 )}
//             </SoftBox>
//         </Grid>

//         <Grid item xs={12}>
//             <SoftBox>
//                 <SoftTypography variant="h6">Holiday Date</SoftTypography>
//                 <FormControl fullWidth>
//                     <SoftInput
//                         type="date"
//                         id="holidayDate"
//                         value={values.holidayDate}
//                         onChange={handleChange('holidayDate')}
//                         error={!!errors.holidayDate}
//                         required
//                     />
//                 </FormControl>
//                 {errors.holidayDate && (
//                     <SoftTypography variant="caption" color="error">
//                         {errors.holidayDate}
//                     </SoftTypography>
//                 )}
//             </SoftBox>
//         </Grid>

//         <Grid item xs={12}>
//             <SoftBox>
//                 <SoftTypography variant="h6">Description</SoftTypography>
//                 <FormControl fullWidth>
//                     <SoftInput
//                         id="description"
//                         value={values.description}
//                         onChange={handleChange('description')}
//                         error={!!errors.description}
//                         multiline
//                         rows={4}
//                         required
//                     />
//                 </FormControl>
//                 {errors.description && (
//                     <SoftTypography variant="caption" color="error">
//                         {errors.description}
//                     </SoftTypography>
//                 )}
//             </SoftBox>
//         </Grid>

//         <Grid item xs={12}>
//             <SoftBox display="flex" justifyContent="center" mt={4} mb={2}>
//                 <SoftButton 
//                     type="submit" 
//                     variant="gradient" 
//                     color="info" 
//                     fullWidth 
//                     disabled={loading}
//                 >
//                     {loading ? "Submitting..." : "Submit"}
//                 </SoftButton>
//             </SoftBox>
//         </Grid>
//     </Grid>
// </SoftBox>
//                     </Card>
//                 </Grid>
//             </Grid>
//         </DashboardLayout>
//     );
// }

// export default HolidayForm;

// import React, { useState, useEffect } from 'react';
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";
// import SoftInput from "components/SoftInput";
// import { Card, Grid, FormControl, Tooltip } from "@mui/material";
// import { CircularProgress, Backdrop } from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
// import * as AiIcons from "react-icons/ai";
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import { APIData, org } from 'authentication/APIData';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// function HolidayForm() {
//     const [holidays, setHolidays] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [submissionProgress, setSubmissionProgress] = useState({
//         total: 0,
//         submitted: 0,
//         failed: 0
//     });
//     const navigate = useNavigate();

//     // Function to fetch Google Calendar Holidays
//     const fetchIndiaHolidays = async () => {
//         setLoading(true);
//         try {
//             const currentYear = new Date().getFullYear();

//             const response = await axios.get(
//                 `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events`, 
//                 {
//                     params: {
//                         key: 'AIzaSyDZGcq10HIrq7J7OUmAMWL32uUvQjI-_cc',
//                         timeMin: `${currentYear}-01-01T00:00:00Z`,
//                         timeMax: `${currentYear}-12-31T23:59:59Z`,
//                         maxResults: 999,
//                         singleEvents: true,
//                         orderBy: 'startTime'
//                     }
//                 }
//             );

//             // Transform Google Calendar events to our holiday format
//             const formattedHolidays = response.data.items.map(event => {
//                 // Clean up description, removing observance info
//                 let cleanDescription = event.description 
//                     ? event.description.replace(/^Observance\n.*$/m, '').trim() 
//                     : event.summary;

//                 return {
//                     holiday_name: event.summary,
//                     holidayDate: event.start.date, // Use start date directly
//                     description: cleanDescription,
//                     org: org
//                 };
//             });

//             // Filter out past dates and duplicates
//             const today = new Date().toISOString().split('T')[0];
//             const uniqueHolidays = formattedHolidays.filter((holiday, index, self) => 
//                 holiday.holidayDate >= today && 
//                 self.findIndex(h => 
//                     h.holidayDate === holiday.holidayDate && 
//                     h.holiday_name === holiday.holiday_name
//                 ) === index
//             );

//             // Check if holidays already exist in the database before setting state
//             const existingHolydaysResponse = await axios.get(
//                 APIData.api + `holidays/?org=${org}`, 
//                 { headers: APIData.headers }
//             );

//             const existingHolidays = existingHolydaysResponse.data;

//             // Filter out holidays that already exist in the database
//             const newHolidays = uniqueHolidays.filter(holiday => 
//                 !existingHolidays.some(existingHoliday => 
//                     existingHoliday.holidayDate === holiday.holidayDate && 
//                     existingHoliday.holiday_name === holiday.holiday_name
//                 )
//             );

//             setHolidays(newHolidays);
//             setSubmissionProgress({
//                 total: newHolidays.length,
//                 submitted: 0,
//                 failed: 0
//             });

//             // Show message if no new holidays
//             if (newHolidays.length === 0) {
//                 Swal.fire("Info", "No new holidays to add", "info");
//             }
//         } catch (error) {
//             console.error('Error fetching holidays:', error);
//             Swal.fire("Error", "Failed to fetch holidays", "error");
//         } finally {
//             setLoading(false);
//         }
//     };

//     const addHolidaysToDatabase = async () => {
//         setLoading(true);
//         const submissionResults = {
//             total: holidays.length,
//             submitted: 0,
//             failed: 0
//         };

//         try {
//             for (const holiday of holidays) {
//                 try {
//                     // Validate individual holiday
//                     if (!holiday.holiday_name.trim() || 
//                         !holiday.holidayDate) {
//                         throw new Error('Invalid holiday data');
//                     }

//                     // Submit single holiday
//                     await axios.post(
//                         APIData.api + 'holidays/', 
//                         holiday, 
//                         { headers: APIData.headers }
//                     );

//                     submissionResults.submitted++;
//                 } catch (err) {
//                     submissionResults.failed++;
//                     console.error(`Failed to submit holiday: ${holiday.holiday_name}`, err);
//                 }

//                 // Update progress state after each submission
//                 setSubmissionProgress({
//                     ...submissionResults
//                 });
//             }

//             // Final success or partial success message
//             if (submissionResults.submitted > 0) {
//                 Swal.fire(
//                     "Holiday Submission", 
//                     `Submitted: ${submissionResults.submitted}, Failed: ${submissionResults.failed}`, 
//                     submissionResults.failed > 0 ? "warning" : "success"
//                 );

//                 // Navigate after successful submission
//                 setTimeout(() => {
//                     navigate("/holiday-list");
//                 }, 1500);
//             } else {
//                 Swal.fire("Error", "No holidays could be submitted", "error");
//             }
//         } catch (error) {
//             Swal.fire("Error", "Unexpected error during submission", "error");
//         } finally {
//             setLoading(false);
//         }
//     };
//     // Fetch holidays on component mount
//     useEffect(() => {
//         fetchIndiaHolidays();
//     }, []);

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
//                 <CircularProgress color="inherit" />
//             </Backdrop>

//             <Grid container spacing={3} justifyContent="center" alignItems="center" mt={3}>
//                 <Grid item xs={12} sm={12} md={10} lg={8}>
//                     <Card>
//                         <SoftBox p={3} display="flex" alignItems="center" justifyContent="space-between">
//                             <SoftTypography variant="h5" fontWeight="medium">
//                                 {new Date().getFullYear()} India Public Holidays
//                             </SoftTypography>
//                             <SoftButton 
//                                 variant="gradient" 
//                                 color="info" 
//                                 onClick={addHolidaysToDatabase}
//                                 disabled={loading || holidays.length === 0}
//                             >
//                                 {loading ? "Processing..." : "Add All Holidays"}
//                             </SoftButton>
//                         </SoftBox>

//                         {holidays.length > 0 && (
//                             <SoftBox px={3} pb={3}>
//                                 <SoftTypography variant="body2" color="text" mb={2}>
//                                     Holidays to be added: {submissionProgress.total} | 
//                                     Submitted: {submissionProgress.submitted} | 
//                                     Failed: {submissionProgress.failed}
//                                 </SoftTypography>

//                                 <Grid container spacing={2}>
//                                     {holidays.map((holiday, index) => (
//                                         <Grid item xs={12} key={index}>
//                                             <Card variant="outlined">
//                                                 <SoftBox p={2}>
//                                                     <SoftTypography variant="h6">
//                                                         {holiday.holiday_name}
//                                                     </SoftTypography>
//                                                     <SoftTypography variant="body2" color="text">
//                                                         Date: {holiday.holidayDate}
//                                                     </SoftTypography>
//                                                     <SoftTypography variant="body2" color="text">
//                                                         {holiday.description}
//                                                     </SoftTypography>
//                                                 </SoftBox>
//                                             </Card>
//                                         </Grid>
//                                     ))}
//                                 </Grid>
//                             </SoftBox>
//                         )}
//                     </Card>
//                 </Grid>
//             </Grid>
//         </DashboardLayout>
//     );
// }

// export default HolidayForm;

import React, { useState, useEffect } from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Card, Grid, FormControl, Tooltip } from "@mui/material";
import { CircularProgress, Backdrop } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as AiIcons from "react-icons/ai";
import axios from 'axios';
import { APIData, org } from 'authentication/APIData';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftInput from "components/SoftInput"; // Assuming SoftInput is imported

function HolidayForm() {
    const [holidays, setHolidays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submissionProgress, setSubmissionProgress] = useState({
        total: 0,
        submitted: 0,
        failed: 0
    });
    const [values, setValues] = useState({
        holiday_name: '',
        holidayDate: '',
        description: ''
    });
    const [errors, setErrors] = useState({});
    const [showForm, setShowForm] = useState(false); // State to control form visibility
    const navigate = useNavigate();

    // Calendarific API Configuration
    const CALENDARIFIC_API_KEY = 'l3XbdezOtHpY9suuAv6W6J39Pc7e4C9h';
    const CALENDARIFIC_API_URL = 'https://calendarific.com/api/v2/holidays';

    // Function to fetch India Holidays from Calendarific
    const fetchIndiaHolidays = async () => {
        setLoading(true);
        try {
            // Validate API key
            if (!CALENDARIFIC_API_KEY) {
                throw new Error('Calendarific API key is not configured');
            }

            const currentYear = new Date().getFullYear();

            const response = await axios.get(CALENDARIFIC_API_URL, {
                params: {
                    api_key: CALENDARIFIC_API_KEY,
                    country: 'IN', // India country code
                    year: currentYear,
                    type: 'national' // Only national holidays
                }
            });

            // Detailed logging of API response
            console.log('Calendarific API Response:', response.data);

            // Check if the response contains holidays
            if (!response.data.response || !response.data.response.holidays) {
                throw new Error('No holidays found or unexpected response format');
            }

            // Transform Calendarific holidays to our format
            const formattedHolidays = response.data.response.holidays.map(holiday => ({
                holiday_name: holiday.name,
                holidayDate: holiday.date.iso, // ISO format date
                description: holiday.description || holiday.name,
                org: org
            }));

            // Filter out past dates
            const today = new Date().toISOString().split('T')[0];
            const uniqueHolidays = formattedHolidays.filter((holiday, index, self) =>
                holiday.holidayDate >= today &&
                self.findIndex(h =>
                    h.holidayDate === holiday.holidayDate &&
                    h.holiday_name === holiday.holiday_name
                ) === index
            );

            // Check if holidays already exist in the database
            const url = `${APIData.api}holidays/?org=${org}`;

            // Fetch existing holidays from the database
            const response1 = await fetch(url, { headers: APIData.headers });

            // Check if the response is okay
            if (!response1.ok) {
                throw new Error(`Failed to fetch holidays: ${response1.statusText}`);
            }

            // Parse the JSON response
            const existingHolidays = await response1.json();

            // Filter out holidays that already exist in the database
            const newHolidays = uniqueHolidays.filter(holiday =>
                !existingHolidays.some(existingHoliday =>
                    existingHoliday.holidayDate === holiday.holidayDate &&
                    existingHoliday.holiday_name === holiday.holiday_name
                )
            );

            setHolidays(newHolidays);
            setSubmissionProgress({
                total: newHolidays.length,
                submitted: 0,
                failed: 0
            });

            // Show message if no new holidays
            if (newHolidays.length === 0) {
                Swal.fire("Info", "No new holidays to add", "info");
            }
        } catch (error) {
            // Comprehensive error handling
            console.error('Error fetching holidays:', error);
            let errorMessage = 'Failed to fetch holidays';
            if (error.response) {
                errorMessage = error.response.data.meta?.error_detail || error.response.data.meta?.error_type || 'Authentication failed';
            }
            Swal.fire({
                icon: 'error',
                title: 'Holiday Fetch Error',
                text: errorMessage,
                footer: '<a href="https://calendarific.com/api-documentation">Check API Documentation</a>'
            });
        } finally {
            setLoading(false);
        }
    };

    const addHolidaysToDatabase = async () => {
        setLoading(true);
        const submissionResults = {
            total: holidays.length,
            submitted: 0,
            failed: 0
        };

        try {
            for (const holiday of holidays) {
                try {
                    // Validate individual holiday
                    if (!holiday.holiday_name.trim() || !holiday.holidayDate) {
                        throw new Error('Invalid holiday data');
                    }

                    // Submit single holiday
                    await axios.post(
                        APIData.api + 'holidays/',
                        holiday,
                        { headers: APIData.headers }
                    );

                    submissionResults.submitted++;
                } catch (err) {
                    submissionResults.failed++;
                    console.error(`Failed to submit holiday: ${holiday.holiday_name}`, err);
                }

                // Update progress state after each submission
                setSubmissionProgress({
                    ...submissionResults
                });
            }

            // Final success or partial success message
            if (submissionResults.submitted > 0) {
                Swal.fire(
                    "Holiday Submission",
                    `Submitted: ${submissionResults.submitted}, Failed: ${submissionResults.failed}`,
                    submissionResults.failed > 0 ? "warning" : "success"
                );

                // Navigate after successful submission
                setTimeout(() => {
                    navigate("/holiday-list");
                }, 1500);
            } else {
                Swal.fire("Error", "No holidays could be submitted", "error");
            }
        } catch (error) {
            Swal.fire("Error", "Unexpected error during submission", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (field) => (event) => {
        setValues({ ...values, [field]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { holiday_name, holidayDate, description } = values;

        // Check for existing holidays in the database
        const url = `${APIData.api}holidays/?org=${org}`;
        const response = await fetch(url, { headers: APIData.headers });
        const existingHolidays = await response.json();

        const isDuplicate = existingHolidays.some(existingHoliday =>
            existingHoliday.holidayDate === holidayDate &&
            existingHoliday.holiday_name === holiday_name
        );

        if (isDuplicate) {
            Swal.fire("Error", "This holiday already exists in the database.", "error");
            return;
        }

        try {
            await axios.post(APIData.api + 'holidays/', {
                holiday_name,
                holidayDate,
                description,
                org
            }, { headers: APIData.headers });

            Swal.fire("Success", "Holiday added successfully!", "success");
            setValues({ holiday_name: '', holidayDate: '', description: '' }); // Reset form
        } catch (error) {
            console.error('Error adding holiday:', error);
            Swal.fire("Error", "Failed to add holiday.", "error");
        }
    };

    // Fetch holidays on component mount
    useEffect(() => {
        fetchIndiaHolidays();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={3} justifyContent="center" alignItems="center" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card>
                        <SoftBox p={3} display="flex" alignItems="center" justifyContent="space-between">
                            <Tooltip title="Close" arrow>
                                <Link to="/holiday-list">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium">
                                {new Date().getFullYear()} Holidays
                            </SoftTypography>
                            <SoftButton
                                variant="gradient"
                                color="info"
                                onClick={addHolidaysToDatabase}
                                disabled={loading || holidays.length === 0}
                            >
                                {loading ? "Processing..." : "Add All Holidays"}
                            </SoftButton>
                        </SoftBox>

                        {holidays.length > 0 && (
                            <SoftBox px={3} pb={3}>
                                <SoftTypography variant="body2" color="text" mb={2}>
                                    Holidays to be added: {submissionProgress.total} |
                                    Submitted: {submissionProgress.submitted} |
                                    Failed: {submissionProgress.failed}
                                </SoftTypography>

                                <Grid container spacing={2}>
                                    {holidays.map((holiday, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Card variant="outlined">
                                                <SoftBox p={2}>
                                                    <SoftTypography variant="h6">
                                                        {holiday.holiday_name}
                                                    </SoftTypography>
                                                    <SoftTypography variant="body2" color="text">
                                                        Date: {holiday.holidayDate}
                                                    </SoftTypography>
                                                    <SoftTypography variant="body2" color="text">
                                                        {holiday.description}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>

                                {/* Button to toggle the form visibility */}

                            </SoftBox>
                        )}

                        <SoftBox display="flex" justifyContent="center" mt={3}>
                            <SoftButton
                                variant="gradient"
                                color="info"
                                onClick={() => setShowForm(!showForm)}
                            >
                                {showForm ? 'Hide Holiday Form' : 'Add A Holiday Manually'}
                            </SoftButton>
                        </SoftBox>                        {showForm && (
                            <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6">Holiday Name</SoftTypography>
                                            <FormControl fullWidth>
                                                <SoftInput
                                                    id="holiday_name"
                                                    value={values.holiday_name}
                                                    onChange={handleChange('holiday_name')}
                                                    placeholder="Holiday Name"
                                                />
                                            </FormControl>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6">Holiday Date</SoftTypography>
                                            <FormControl fullWidth>
                                                <SoftInput
                                                    type="date"
                                                    id="holidayDate"
                                                    value={values.holidayDate}
                                                    onChange={handleChange('holidayDate')}
                                                    error={!!errors.holidayDate}
                                                    required
                                                />
                                            </FormControl>
                                            {errors.holidayDate && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.holidayDate}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox>
                                            <SoftTypography variant="h6">Description</SoftTypography>
                                            <FormControl fullWidth>
                                                <SoftInput
                                                    id="description"
                                                    value={values.description}
                                                    onChange={handleChange('description')}
                                                    error={!!errors.description}
                                                    multiline
                                                    rows={4}
                                                    required
                                                />
                                            </FormControl>
                                            {errors.description && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.description}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} display="flex" justifyContent="center">
                                        <SoftButton
                                            variant="gradient"
                                            color="info"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading ? "Submitting..." : "Submit Holiday"}
                                        </SoftButton>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default HolidayForm;
