import React, { useState, useRef } from 'react';
import {
    Grid,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from 'components/SoftButton';
import Card from "@mui/material/Card";
import Swal from 'sweetalert2';
import { APIData, org } from 'authentication/APIData';
import { Link, useNavigate } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';

const InfraForm = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [newInfra, setNewInfra] = useState({
        name: '',
        type: '',
        description: '',
        image_url: '',
        org: org
    });
    const infraTypeOptions = [
        { value: 'HARDWARE', label: 'Hardware' },
        { value: 'SOFTWARE', label: 'Software' },
        { value: 'NETWORKING', label: 'Networking' },
        { value: 'ROOM', label: 'Room' },
        { value: 'OTHERS', label: 'Others' }
    ];
    const [selectedImage, setSelectedImage] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewInfra(prev => ({
            ...prev,
            [name]: value
        }));
    };
    const handleTypeChange = (selectedOption) => {
        setNewInfra(prev => ({
            ...prev,
            type: selectedOption.value
        }));
    };
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 1 * 1024 * 1024) { // 1MB limit
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64string = reader.result.split(",")[1];
                setNewInfra({
                    ...newInfra,
                    image_url: base64string,
                });
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Image Size Error',
                text: 'Image size should be less than 1MB',
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!newInfra.image_url) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Image',
                text: 'Please upload an image for the infrastructure',
            });
            return;
        }

        try {
            const url = `${APIData.api}infra/`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    ...APIData.headers,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newInfra)
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Infrastructure added successfully.',
                    timer: 2000,
                    timerProgressBar: true,
                });
                navigate('/infra');
            } else {
                const errorData = await response.json();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorData.message || 'Failed to add infrastructure',
                });
            }
        } catch (error) {
            console.error('Error adding infrastructure:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was a problem adding the infrastructure',
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={8} lg={8}>
                        <Card>
                            <SoftBox p={3}>
                                <SoftBox p={3} mb={1} display="flex" alignItems="center">
                                    <Link to="/infra" style={{ textDecoration: 'none' }}>
                                        <AiIcons.AiOutlineCloseCircle />
                                    </Link>
                                    <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                        Add new Infrastructure
                                    </SoftTypography>
                                </SoftBox>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <SoftTypography variant="h6" component="label" htmlFor="name">
                                                Name
                                            </SoftTypography>
                                            <SoftInput
                                                fullWidth
                                                label="Infrastructure Name"
                                                name="name"
                                                value={newInfra.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SoftTypography variant="h6" component="span" >
                                                Infrastructure Type:
                                            </SoftTypography>
                                            <CustomSelect
                                                options={infraTypeOptions.map(type => ({ value: type.value, label: type.label }))} // Use string values
                                                placeholder="Select Infrastructure Type"
                                                name="type"
                                                onChange={handleTypeChange} // No need for manual structuring
                                                value={infraTypeOptions.find(option => option.value === newInfra.type)} // Set the selected value
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <SoftTypography variant="h6" component="label" htmlFor="description">
                                                Description
                                            </SoftTypography>
                                            <SoftInput
                                                fullWidth
                                                multiline
                                                rows={4}
                                                label="Description"
                                                name="description"
                                                value={newInfra.description}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                accept="image/jpeg,image/png,image/gif,image/webp"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none' }}
                                            />
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                startIcon={<CloudUpload />}
                                                onClick={() => fileInputRef.current.click()}
                                            >
                                                Upload Infrastructure Image
                                            </SoftButton>
                                        </Grid>
                                        {selectedImage && (
                                            <Grid item xs={12}>
                                                <SoftBox
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    border={1}
                                                    borderColor="light.main"
                                                    borderRadius="md"
                                                    p={2}
                                                >
                                                    <img
                                                        src={selectedImage}
                                                        alt="Selected Infrastructure"
                                                        style={{
                                                            maxWidth: '100%',
                                                            maxHeight: '300px',
                                                            objectFit: 'contain',
                                                        }}
                                                    />
                                                </SoftBox>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <SoftBox display="flex" justifyContent="center" gap={2}>
                                                <Link to="/infra" style={{ textDecoration: 'none' }}>
                                                    <SoftButton variant="gradient" color="info">
                                                        Cancel
                                                    </SoftButton>
                                                </Link>
                                                <SoftButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                >
                                                    Add Infrastructure
                                                </SoftButton>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </form>
                            </SoftBox>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default InfraForm;