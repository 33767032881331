// // import React, { useState, useEffect } from 'react';
// // import { ChevronLeft, ChevronRight } from 'lucide-react';

// // const Carousel = () => {
// //   const [currentIndex, setCurrentIndex] = useState(0);

// //   const cards = [
// //     { id: 1, title: "Card 1", color: "linear-gradient(to bottom right, #7C3AED, #4F46E5)" }, 
// //     { id: 2, title: "Card 2", color: "linear-gradient(to bottom right, #3B82F6, #06B6D4)" },
// //     { id: 3, title: "Card 3", color: "linear-gradient(to bottom right, #10B981, #14B8A6)" }, 
// //     { id: 4, title: "Card 4", color: "linear-gradient(to bottom right, #F43F5E, #EC4899)" }, 
// //     { id: 5, title: "Card 5", color: "linear-gradient(to bottom right, #F59E0B, #EA580C)" }, 
// //   ];

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       nextCard();
// //     }, 3000); 
// //     return () => clearInterval(interval);
// //   }, [currentIndex]);

// //   const nextCard = () => {
// //     setCurrentIndex((prevIndex) => 
// //       prevIndex === cards.length - 1 ? 0 : prevIndex + 1
// //     );
// //   };

// //   const prevCard = () => {
// //     setCurrentIndex((prevIndex) => 
// //       prevIndex === 0 ? cards.length - 1 : prevIndex - 1
// //     );
// //   };

// //   const getCardStyle = (index) => {
// //     const position = index - currentIndex;

// //     let style = {
// //       position: 'absolute',
// //       width: '300px',
// //       height: '400px',
// //       transition: 'all 0.5s ease-out',
// //       cursor: 'pointer',
// //       borderRadius: '16px',
// //       boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
// //       background: cards[index].color,
// //       display: 'flex',
// //       flexDirection: 'column',
// //       justifyContent: 'center',
// //       alignItems: 'center',
// //       color: 'white',
// //       overflow: 'hidden',
// //     };

// //     if (position === 0) {
// //       style.transform = 'translateX(0) scale(1)';
// //       style.zIndex = 3;
// //       style.opacity = 1;
// //     } else if (position === 1 || position === -cards.length + 1) {
// //       style.transform = 'translateX(100%) scale(0.85)';
// //       style.zIndex = 2;
// //       style.opacity = 0.7;
// //     } else if (position === -1 || position === cards.length - 1) {
// //       style.transform = 'translateX(-100%) scale(0.85)';
// //       style.zIndex = 2;
// //       style.opacity = 0.7;
// //     } else if (position === 2 || position === -cards.length + 2) {
// //       style.transform = 'translateX(200%) scale(0.7)';
// //       style.zIndex = 1;
// //       style.opacity = 0.4;
// //     } else if (position === -2 || position === cards.length - 2) {
// //       style.transform = 'translateX(-200%) scale(0.7)';
// //       style.zIndex = 1;
// //       style.opacity = 0.4;
// //     } else {
// //       style.opacity = 0;
// //       style.transform = 'translateX(0) scale(0.6)';
// //     }

// //     return style;
// //   };

// //   return (
// //     <div style={{
// //       display: 'flex',
// //       justifyContent: 'center',
// //       alignItems: 'center',
// //       minHeight: '100%',
// //     }}>
// //       <div style={{
// //         position: 'relative',
// //         width: '100%',
// //         maxWidth: '1440px',
// //         height: '600px',
// //         display: 'flex',
// //         justifyContent: 'center',
// //         alignItems: 'center',
// //         padding: '0 64px'
// //       }}>
// //         <button 
// //           onClick={prevCard}
// //           style={{
// //             position: 'absolute',
// //             left: '0',
// //             zIndex: 10,
// //             backgroundColor: 'rgba(255, 255, 255, 0.8)',
// //             backdropFilter: 'blur(10px)',
// //             transition: 'all 0.2s',
// //             padding: '16px',
// //             borderRadius: '50%',
// //             boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
// //             color: '#4B5563',
// //             border: 'none',
// //             cursor: 'pointer'
// //           }}
// //           aria-label="Previous card"
// //         >
// //           <ChevronLeft size={24} />
// //         </button>

// //         <div style={{
// //           position: 'relative',
// //           width: '100%',
// //           height: '100%',
// //           display: 'flex',
// //           justifyContent: 'center',
// //           alignItems: 'center'
// //         }}>
// //           {cards.map((card, index) => (
// //             <div
// //               key={card.id}
// //               style={getCardStyle(index)}
// //               onClick={() => setCurrentIndex(index)}
// //             >
// //               <div style={{
// //                 transform: 'scale(1)',
// //                 transition: 'transform 0.3s ease-out'
// //               }}>
// //                 <h2 style={{
// //                   fontSize: '36px',
// //                   fontWeight: 'bold',
// //                   marginBottom: '16px',
// //                   color: 'rgba(255, 255, 255, 0.9)'
// //                 }}>
// //                   {card.title}
// //                 </h2>
// //                 <p style={{
// //                   fontSize: '18px',
// //                   color: 'rgba(255, 255, 255, 0.75)'
// //                 }}>
// //                   Coming Soon
// //                 </p>
// //               </div>
// //               <div style={{
// //                 position: 'absolute',
// //                 bottom: 0,
// //                 left: 0,
// //                 right: 0,
// //                 height: '33%',
// //                 background: 'linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent)',
// //               }} />
// //             </div>
// //           ))}
// //         </div>

// //         <button 
// //           onClick={nextCard}
// //           style={{
// //             position: 'absolute',
// //             right: '0',
// //             zIndex: 10,
// //             backgroundColor: 'rgba(255, 255, 255, 0.8)',
// //             backdropFilter: 'blur(10px)',
// //             transition: 'all 0.2s',
// //             padding: '16px',
// //             borderRadius: '50%',
// //             boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
// //             color: '#4B5563',
// //             border: 'none',
// //             cursor: 'pointer'
// //           }}
// //           aria-label="Next card"
// //         >
// //           <ChevronRight size={24} />
// //         </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Carousel;

import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import axios from 'axios';
import { APIData } from 'authentication/APIData';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [cards, setCards] = useState([]); 

  useEffect(() => {
    const getProducts = () => {
      axios.get(`https://qa-api.care4edu.com/c4e/org-placeholder/details/type?org=avinya&image_type=product_image`, { headers: APIData.headers })
        .then((resp) => {
          console.log(resp.data);
          setProducts(resp.data);
          setCards(resp.data.map((product, index) => ({
            id: index + 1,
            title: product.placeholderName, 
            description: product.placeholder, 
            color: product.color, 
            imageUrl: product.placeholderImage 
          })));
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getProducts();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextCard();
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextCard = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevCard = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
  };

  const getCardStyle = (index) => {
    const position = index - currentIndex;

    let style = {
      position: 'absolute',
      width: '300px',
      height: '400px',
      transition: 'all 0.5s ease-out',
      cursor: 'pointer',
      borderRadius: '16px',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
      overflow: 'hidden',
    };

    if (position === 0) {
      style.transform = 'translateX(0) scale(1)';
      style.zIndex = 3;
      style.opacity = 1;
    } else if (position === 1 || position === -cards.length + 1) {
      style.transform = 'translateX(100%) scale(0.85)';
      style.zIndex = 2;
      style.opacity = 0.7;
    } else if (position === -1 || position === cards.length - 1) {
      style.transform = 'translateX(-100%) scale(0.85)';
      style.zIndex = 2;
      style.opacity = 0.7;
    } else if (position === 2 || position === -cards.length + 2) {
      style.transform = 'translateX(200%) scale(0.7)';
      style.zIndex = 1;
      style.opacity = 0.4;
    } else if (position === -2 || position === cards.length - 2) {
      style.transform = 'translateX(-200%) scale(0.7)';
      style.zIndex = 1;
      style.opacity = 0.4;
    } else {
      style.opacity = 0;
      style.transform = 'translateX(0) scale(0.6)';
    }

    return style;
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100%',
    }}>
      <div style={{
        position: 'relative',
        width: '100%',
        maxWidth: '1440px',
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 64px'
      }}>
        <button
          onClick={prevCard}
          style={{
            position: 'absolute',
            left: '0',
            zIndex: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(10px)',
            transition: 'all 0.2s',
            padding: '16px',
            borderRadius: '50%',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
            color: '#4B5563',
            border: 'none',
            cursor: 'pointer'
          }}
          aria-label="Previous card"
        >
          <ChevronLeft size={24} />
        </button>

        <div style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {cards.map((card, index) => (
            <div
              key={card.id}
              style={getCardStyle(index)}
              onClick={() => setCurrentIndex(index)}
            >
              {/* Image Container */}
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1
              }}>
                <img
                  src={card.imageUrl}
                  alt={card.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>

              {/* Gradient Overlay */}
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: `linear-gradient(
                  to bottom,
                  transparent 0%,
                  rgba(0, 0, 0, 0.2) 50%,
                  rgba(0, 0, 0, 0.7) 100%
                )`,
                zIndex: 2
              }} />

              {/* Text Content */}
              <div style={{
                position: 'absolute',
                bottom: '24px',
                left: '24px',
                right: '24px',
                zIndex: 3,
                transform: 'scale(1)',
                transition: 'transform 0.3s ease-out'
              }}>
                <h2 style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  color: 'white'
                }}>
                  {card.title}
                </h2>
                <p style={{
                  fontSize: '16px',
                  color: 'rgba(255, 255, 255, 0.9)'
                }}>
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={nextCard}
          style={{
            position: 'absolute',
            right: '0',
            zIndex: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(10px)',
            transition: 'all 0.2s',
            padding: '16px',
            borderRadius: '50%',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
            color: '#4B5563',
            border: 'none',
            cursor: 'pointer'
          }}
          aria-label="Next card"
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
// import React, { useState, useEffect } from 'react';
// import { ChevronLeft, ChevronRight } from 'lucide-react';
// import axios from 'axios';
// import { APIData } from 'authentication/APIData';

// const Carousel = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [products, setProducts] = useState([]);
//   const [cards, setCards] = useState([]);

//   useEffect(() => {
//     const getProducts = async () => {
//       try {
//         const resp = await axios.get(
//           `https://qa-api.care4edu.com/c4e/org-placeholder/details/type?org=avinya&image_type=product_image`,
//           { headers: APIData.headers }
//         );
//         setProducts(resp.data);
//         setCards(
//           resp.data.map((product, index) => ({
//             id: index + 1,
//             title: product.placeholderName,
//             description: product.placeholder,
//             imageUrl: product.placeholderImage,
//           }))
//         );
//       } catch (err) {
//         console.log(err);
//       }
//     };
//     getProducts();
//   }, []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       nextCard();
//     }, 3000);
//     return () => clearInterval(interval);
//   }, [currentIndex]);

//   const nextCard = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === cards.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const prevCard = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? cards.length - 1 : prevIndex - 1
//     );
//   };

//   const getCardStyle = (index) => {
//     return {
//       display: index === currentIndex ? 'flex' : 'none',
//       flexDirection: 'column',
//       alignItems: 'center',
//       justifyContent: 'center',
//       width: '100%',
//       height: '100%',
//       padding: '20px',
//       boxSizing: 'border-box',
//     };
//   };

//   return (
//     <div
//       style={{
//         position: 'absolute',
//         right: '0',
//         top: '50%',
//         transform: 'translateY(-50%)',
//         width: '50%',
//         height: '400px',
//         display: 'flex',
//         alignItems: 'center',
//         overflow: 'hidden',
//         boxSizing: 'border-box',
//       }}
//     >
//       {/* Previous Button */}
//       <button
//         onClick={prevCard}
//         style={{
//           position: 'absolute',
//           left: '10px',
//           zIndex: 10,
//           backgroundColor: 'rgba(255, 255, 255, 0.8)',
//           backdropFilter: 'blur(10px)',
//           padding: '16px',
//           borderRadius: '50%',
//           border: 'none',
//           cursor: 'pointer',
//         }}
//         aria-label="Previous card"
//       >
//         <ChevronLeft size={24} />
//       </button>

//       {/* Carousel Content */}
//       <div
//         style={{
//           width: '100%',
//           height: '100%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           position: 'relative',
//         }}
//       >
//         {cards.map((card, index) => (
//           <div key={card.id} style={getCardStyle(index)}>
//             <div
//               style={{
//                 flex: 1,
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <img
//                 src={card.imageUrl}
//                 alt={card.title}
//                 style={{
//                   maxWidth: '100%', // Increased width
//                   maxHeight: '350px', // Increased height
//                   objectFit: 'contain',
//                   borderRadius: '8px',
//                   boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
//                 }}
//               />
//             </div>
//             <div
//               style={{
//                 width: '100%',
//                 marginTop: '16px',
//                 textAlign: 'center',
//               }}
//             >
//               <h2
//                 style={{
//                   fontSize: '18px',
//                   fontWeight: 'bold',
//                   marginBottom: '8px',
//                   color: '#333',
//                 }}
//               >
//                 {card.title}
//               </h2>
//               <p style={{ fontSize: '14px', color: '#555' }}>
//                 {card.description}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Next Button */}
//       <button
//         onClick={nextCard}
//         style={{
//           position: 'absolute',
//           right: '10px',
//           zIndex: 10,
//           backgroundColor: 'rgba(255, 255, 255, 0.8)',
//           backdropFilter: 'blur(10px)',
//           padding: '16px',
//           borderRadius: '50%',
//           border: 'none',
//           cursor: 'pointer',
//         }}
//         aria-label="Next card"
//       >
//         <ChevronRight size={24} />
//       </button>
//     </div>
//   );
// };

// export default Carousel;
