// import React, { useState, useEffect } from 'react';
// import SoftBox from 'components/SoftBox';
// import SoftButton from 'components/SoftButton';
// import Footer from 'examples/Footer';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import SoftTypography from 'components/SoftTypography';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { APIData, org } from 'authentication/APIData';
// import Table from 'examples/Tables/Table';
// import SoftAvatar from 'components/SoftAvatar';
// import PropTypes from 'prop-types';
// import { useNavigate } from "react-router-dom";
// import SoftBadge from 'components/SoftBadge';
// import { Link } from 'react-router-dom';
// import Icon from "@mui/material/Icon";

// const Invoice = () => {
//   const [invoiceData, setInvoiceData] = useState([]);
//   const navigate = useNavigate();
//   const Name = ({ name, email }) => (
//     <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
//       <SoftBox mr={2}>
//         <SoftAvatar
//           src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
//           alt={name}
//           size="sm"
//           variant="rounded"
//         />
//       </SoftBox>
//       <SoftBox display="flex" flexDirection="column">
//         <SoftTypography variant="button" fontWeight="medium">
//           {name}
//         </SoftTypography>
//         <SoftTypography variant="caption" color="secondary">
//           {email}
//         </SoftTypography>
//       </SoftBox>
//     </SoftBox>
//   );
//   Name.propTypes = {
//     name: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired,
//   };
//   const fetchInvoices = () => {
//     const url = APIData.api + `invoices?org=${org}`;
//     axios
//       .get(url, { headers: APIData.headers })
//       .then((resp) => {
//         console.log(resp.data);

//         setInvoiceData(resp.data);
//       })
//       .catch((err) => {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error Fetching Invoices',
//           text: 'Please try again later.',
//           confirmButtonText: 'OK'
//         });
//       });
//   };

//   const downloadInvoice = (invoiceId) => {
//     const url = `${APIData.api}invoices/download/${invoiceId}`;

//     axios
//       .get(url, {
//         headers: APIData.headers,
//         responseType: 'blob'
//       })
//       .then((resp) => {
//         const url = window.URL.createObjectURL(new Blob([resp.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', `invoice_${invoiceId}.pdf`);
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       })
//       .catch((err) => {
//         console.error("Download error:", err);
//       });
//   };

//   useEffect(() => {
//     fetchInvoices();
//   }, []);

//   const navigateToAddInvoice = () => {
//     navigate("/add-invoice");
//   }


//   const columns = [
//     { name: "User", align: "left" },
//     { name: "InvoiceType", align: "left" },
//     { name: "PaymentStatus", align: "left" },
//     { name: "Date", align: "left" },
//     { name: "Action", align: "left" }
//   ];
//   const rows = invoiceData.map((invoice) => ({
//     User: <Name name={invoice.toName} email={invoice.custEmailId} />,
//     InvoiceType: (
//       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//         {invoice.invType}
//       </SoftTypography>
//     ),
//     PaymentStatus: (
//       <SoftBadge
//         variant="gradient"
//         badgeContent={invoice.paymentstatus.toLowerCase()}
//         color={invoice.paymentstatus.toLowerCase() === "received" ? "success" : "secondary"}
//         size="xs"
//         container
//       />
//     ),
//     Date: (
//       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//         {invoice.date}
//       </SoftTypography>
//     ),
//     Action: (
//       <>
//         <Link>
//           <SoftTypography
//             component="a"
//             variant="button"
//             color="info"
//             fontWeight="medium"
//             onClick={() => downloadInvoice(invoice.invoiceid)}
//           >
//             Download
//           </SoftTypography>
//         </Link>
//       </>
//     ),
//   }));
//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10, marginTop: 10 }}>
//         <SoftButton variant="gradient" color="info" size="small" onClick={navigateToAddInvoice}>
//           <Icon sx={{ fontWeight: "bold" }}>add</Icon>
//           &nbsp;Add Invoice
//         </SoftButton>
//       </div>
//       {invoiceData != null && <Table columns={columns} rows={rows} />}
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default Invoice;


import React, { useState, useEffect } from 'react';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftTypography from 'components/SoftTypography';
import axios from 'axios';
import Swal from 'sweetalert2';
import { APIData, org } from 'authentication/APIData';
import SoftAvatar from 'components/SoftAvatar';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import SoftBadge from 'components/SoftBadge';
import Icon from "@mui/material/Icon";
import SoftInput from 'components/SoftInput';
import Card from '@mui/material/Card';
import Table from 'examples/Tables/Table';

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const navigate = useNavigate();

  const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar
          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
          alt={name}
          size="sm"
          variant="rounded"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );

  Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  const fetchInvoices = () => {
    const url = APIData.api + `invoices?org=${org}`;
    axios
      .get(url, { headers: APIData.headers })
      .then((resp) => {
        setInvoiceData(resp.data);
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error Fetching Invoices',
          text: 'Please try again later.',
          confirmButtonText: 'OK'
        });
      });
  };

  const downloadInvoice = (invoiceId) => {
    const url = `${APIData.api}invoices/download/${invoiceId}`;
    axios
      .get(url, {
        headers: APIData.headers,
        responseType: 'blob'
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${invoiceId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("Download error:", err);
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const navigateToAddInvoice = () => {
    navigate("/add-invoice");
  };

  const filteredInvoices = invoiceData.filter((invoice) =>
    (invoice.toName?.toLowerCase() ?? "").includes(searchTerm.toLowerCase()) ||
    (invoice.invType?.toLowerCase() ?? "").includes(searchTerm.toLowerCase()) ||
    (invoice.paymentstatus?.toLowerCase() ?? "").includes(searchTerm.toLowerCase()) ||
    (invoice.date ?? "").includes(searchTerm)
  );

  const paginatedInvoices = filteredInvoices.slice(page * itemsPerPage, (page + 1) * itemsPerPage);

  const columns = [
    { name: "User", align: "left" },
    { name: "InvoiceType", align: "left" },
    { name: "PaymentStatus", align: "left" },
    { name: "Date", align: "left" },
    { name: "Action", align: "left" }
  ];

  const rows = paginatedInvoices.map((invoice) => ({
    User: <Name name={invoice.toName} email={invoice.custEmailId} />,
    InvoiceType: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {invoice.invType}
      </SoftTypography>
    ),
    PaymentStatus: (
      <SoftBadge
        variant="gradient"
        badgeContent={invoice.paymentstatus.toLowerCase()}
        color={invoice.paymentstatus.toLowerCase() === "received" ? "success" : "secondary"}
        size="xs"
        container
      />
    ),
    Date: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {invoice.date}
      </SoftTypography>
    ),
    Action: (
      <SoftTypography
        component="a"
        variant="button"
        color="info"
        fontWeight="medium"
        onClick={() => downloadInvoice(invoice.invoiceid)}
      >
        Download
      </SoftTypography>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Invoices</SoftTypography>
            <SoftBox display="flex" alignItems="center" gap={2}>
              <SoftInput
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
              />
              <SoftButton variant="gradient" color="info" size="small" onClick={navigateToAddInvoice}>
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;Add Invoice
              </SoftButton>
            </SoftBox>
          </SoftBox>
          <SoftBox>
            <Table columns={columns} rows={rows} />
          </SoftBox>
          {/* Pagination */}
          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" p={3}>
            <SoftTypography variant="caption" color="secondary">
              {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredInvoices.length)} of {filteredInvoices.length}
            </SoftTypography>
            <SoftButton
              variant="text"
              color="info"
              onClick={() => setPage((prev) => Math.max(0, prev - 1))}
              disabled={page === 0}
              iconOnly
            >
              <Icon>keyboard_arrow_left</Icon>
            </SoftButton>
            <SoftButton
              variant="text"
              color="info"
              onClick={() => setPage((prev) => Math.min(Math.ceil(filteredInvoices.length / itemsPerPage) - 1, prev + 1))}
              disabled={page >= Math.ceil(filteredInvoices.length / itemsPerPage) - 1}
              iconOnly
            >
              <Icon>keyboard_arrow_right</Icon>
            </SoftButton>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Invoice;
