import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button, Grid, Typography, Container, Paper, Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import axios from 'axios';
import { Close as CloseIcon, Download as DownloadIcon } from '@mui/icons-material';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import HardWare from './HardWare';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftButton from 'components/SoftButton';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const containerStyle = {
    color: '#333',
    maxWidth: '700px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#fff',
};

const headerStyle = {
    textAlign: 'left',
    marginBottom: '20px',
};

const logoStyle = {
    width: '80px',
    height: '80px',
    marginLeft: '20px',
};

const headerContentStyle = {
    marginLeft: '0px',
};

const titleTextStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const paragraphStyle = {
    margin: '5px 0',
};

const paragraphStyle1 = {
    margin: '5px 0',
    marginBottom: '20px',
};

const contentStyle = {
    lineHeight: '1.6',
    marginBottom: '20px',
};

const nocTitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const signatureStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
};

const signatureBlockStyle = {
    textAlign: 'center',
};

const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const subtitleStyle = {
    fontSize: '16px',
    margin: '3px 0',
};

const sectionTitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '20px 0 10px 0',
};

const textStyle = {
    margin: '10px 0',
};

const signOffStyle = {
    fontWeight: 'bold',
};

const initialState = {

    header1: "",
    header10: "",
    header11: "",
    header12: "",
    header13: "",
    header14: "",
    header15: '',
    header16: "",
    header17: "",
    header18: "",
    header19: "",
    header2: "",
    header20: "",
    header21: "",
    header3: "",
    header4: "",
    header5: "",
    header6: "",
    header7: "",
    header8: "",
    header9: "",
    id: 0,
    org: org,
    para1: "",
    para10: "",
    para11: "",
    para12: "",
    para13: "",
    para14: "",
    para15: "",
    para16: "",
    para17: "",
    para18: "",
    para19: "",
    para2: "",
    para20: "",
    para21: "",
    para22: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    temp_name: "HARDWAREACCPT",
    title: "HardwraeAcceptanceForm"

}

function HardWareAcptEdit() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const templateRef = useRef(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
    }, []);


    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };


    useEffect(() => {
        fetchOrgDetails();
    },);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });

            })
        console.log('Form Data Submitted:', formData);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownloadPDF = () => {
        if (!templateRef.current) {
            Swal.fire({
                icon: 'error',
                title: 'PDF Generation Error',
                text: 'Template content not found.'
            });
            return;
        }
    
        html2canvas(templateRef.current, {
            scale: 2,
            useCORS: true,
            logging: false,
            scrollX: 0,
            scrollY: -window.scrollY,
            width: templateRef.current.scrollWidth,
            height: templateRef.current.scrollHeight
        }).then(canvas => {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = pageWidth - 20;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const totalPages = Math.ceil(imgHeight / pageHeight);

            for (let i = 0; i < totalPages; i++) {
                if (i > 0) {
                    pdf.addPage();
                }

                const yOffset = -i * pageHeight;

                pdf.addImage(
                    imgData, 
                    'PNG', 
                    10, 
                    10 + yOffset, 
                    imgWidth, 
                    imgHeight
                );
            }

            const filename = `NOC_Template_${formData.title || 'Untitled'}_${new Date().toISOString().split('T')[0]}.pdf`;

            pdf.save(filename);
        }).catch(error => {
            console.error('Error generating PDF:', error);
            Swal.fire({
                icon: 'error',
                title: 'PDF Generation Error',
                text: 'Could not generate PDF. Please try again.'
            });
        });
    };

    return (
        <Container>
            <PageLayout>
                <SoftButton onClick={handleBack}>
                    Back
                </SoftButton>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" gutterBottom>
                            Hardware acceptence Form
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header1</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header1"
                                        value={formData.header1}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header2</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header2"
                                        value={formData.header2}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">para1</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para1"
                                        value={formData.para1}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">para2</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para2"
                                        value={formData.para2}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">para3</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para3"
                                        value={formData.para3}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        style={{ marginBottom: '16px', width: '100%' }}
                                        onClick={handleClickOpen}
                                    >
                                        View Template in Full Screen and Download
                                    </Button>

                                    <Button type="submit" variant="contained" color="primary" style={{ width: '100%', marginTop: '5px' }}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} sm={4}>

                        <Typography variant="h5" gutterBottom>
                            Adaptive Template
                        </Typography>

                        <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                            {orgdetails ? (
                                <div style={styles.container}>
                                    <div style={styles.header}>
                                        <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                        <div style={styles.companyDetails}>
                                            <h3>{orgdetails.orgName}</h3>
                                            <p>+91 {orgdetails.orgMob}</p>
                                            <p>{orgdetails.orgEmailId}</p>
                                            <p>{orgdetails.orgUrl}</p>
                                        </div>
                                    </div>

                                    <section style={styles.section}>
                                        <h2 style={styles.sectionTitle}>
                                            {formData.header1}
                                        </h2>
                                        <p style={styles.sectionName}><strong>{formData.header2}: Adhithyan P A</strong></p>
                                        <p>
                                            {formData.para1}
                                        </p>

                                        <ul style={styles.equipmentList}>
                                            <li>Office/Building Key (No _________________________)</li>
                                            <li>Security Access Code (No _________________________)</li>
                                            <li>Cell Phone (Serial # ___________________ Phone # ____________________)</li>
                                            <li>Desktop (Serial # _____________________ Phone # ____________________)</li>
                                            <li>Desktop (Serial # ________________________________)</li>
                                            <li>Other: _________________________________________</li>
                                            <li>Other: _________________________________________</li>
                                            <li>Other: _________________________________________</li>
                                        </ul>

                                        <p>
                                            {formData.para2} <strong>20/08/2024</strong>.
                                            {formData.para3}
                                        </p>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <div style={styles.signatureSection}>
                                                <p>(Adhithyan P A)</p>
                                                <p>Signature</p>
                                                <p>Issuing Manager Signature_____________</p>
                                            </div>
                                            <div style={styles.signatureSection1}>
                                                <p>Date: 20/08/2024</p>
                                            </div>
                                        </div>

                                    </section>
                                </div>
                            ) : (
                                <Typography variant="body1">Loading organization details...</Typography>
                            )}
                        </Paper >
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <HardWare />
                    </Grid>
                </Grid>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        Full Screen View
                        {(permission === 1111 || isSuperAdmin) && (
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleDownloadPDF}
                                aria-label="download"
                                style={{ position: 'absolute', right: 56, top: 16 }}
                            >
                                <DownloadIcon />
                            </IconButton>
                        )}
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            style={{ position: 'absolute', right: 16, top: 16 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {orgdetails ? (
                            <div
                                ref={templateRef}
                                style={{
                                    ...containerStyle,
                                    backgroundColor: 'white',
                                    padding: '20px',
                                    margin: 'auto'
                                }}
                            >
                                <div style={styles.container}>
                                    <div style={styles.header}>
                                        <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                        <div style={styles.companyDetails}>
                                            <h3>{orgdetails.orgName}</h3>
                                            <p>+91 {orgdetails.orgMob}</p>
                                            <p>{orgdetails.orgEmailId}</p>
                                            <p>{orgdetails.orgUrl}</p>
                                        </div>
                                    </div>

                                    <section style={styles.section}>
                                        <h2 style={styles.sectionTitle}>
                                            {formData.header1}
                                        </h2>
                                        <p style={styles.sectionName}><strong>{formData.header2}: Adhithyan P A</strong></p>
                                        <p>
                                            {formData.para1}
                                        </p>

                                        <ul style={styles.equipmentList}>
                                            <li>Office/Building Key (No _________________________)</li>
                                            <li>Security Access Code (No _________________________)</li>
                                            <li>Cell Phone (Serial # ___________________ Phone # ____________________)</li>
                                            <li>Desktop (Serial # _____________________ Phone # ____________________)</li>
                                            <li>Desktop (Serial # ________________________________)</li>
                                            <li>Other: _________________________________________</li>
                                            <li>Other: _________________________________________</li>
                                            <li>Other: _________________________________________</li>
                                        </ul>

                                        <p>
                                            {formData.para2} <strong>20/08/2024</strong>.
                                            {formData.para3}
                                        </p>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <div style={styles.signatureSection}>
                                                <p>(Adhithyan P A)</p>
                                                <p>Signature</p>
                                                <p>Issuing Manager Signature_____________</p>
                                            </div>
                                            <div style={styles.signatureSection1}>
                                                <p>Date: 20/08/2024</p>
                                            </div>
                                        </div>

                                    </section>
                                </div>
                            </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                    </DialogContent>
                </Dialog>
            </PageLayout>
        </Container>

    );
};

const styles = {
    container: {
        fontFamily: "'Poppins', sans-serif",
        maxWidth: '700px',
        margin: 'auto',
        padding: '20px',

        backgroundColor: '#fff',
    },
    header: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },

    companyDetails: {
        textAlign: 'left',
        marginTop: '10px',
        fontSize: '14px',

    },
    sectionTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    sectionName: {
        marginTop: '20px',
        fontSize: '14px',
        marginBottom: '20px',

    },
    equipmentList: {
        marginTop: '20px',
        listStyleType: 'none',
        paddingLeft: '0',
        marginBottom: '20px',
        fontSize: '14px',
        maxWidth: '100%',
        wordWrap: 'break-word',
        lineHeight: '1.5',
    },
    signatureSection: {
        marginTop: '40px',
        fontSize: '14px',
    },
    signatureSection1: {
        marginTop: '80px',
        fontSize: '14px',
    }
};

export default HardWareAcptEdit;
