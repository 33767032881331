import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { APIData, org } from 'authentication/APIData';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import {
    Card,
    Dialog,
    DialogContent,
    CircularProgress,
    Grid,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CardHeader,
    CardContent
} from '@mui/material';

const PayslipPage = () => {
    const [empdata, setEmpdata] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [payslip, setPayslip] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgAddress, setOrgAddress] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth(); // 0-11
    const currentYear = currentDate.getFullYear();

    // Generate month options based on selected year
    const getMonthOptions = (selectedYear) => {
        const allMonths = [
            { value: "January", label: "January" },
            { value: "February", label: "February" },
            { value: "March", label: "March" },
            { value: "April", label: "April" },
            { value: "May", label: "May" },
            { value: "June", label: "June" },
            { value: "July", label: "July" },
            { value: "August", label: "August" },
            { value: "September", label: "September" },
            { value: "October", label: "October" },
            { value: "November", label: "November" },
            { value: "December", label: "December" }
        ];

        // If selected year is current year, only show months up to current month
        if (selectedYear === currentYear) {
            return allMonths.slice(0, currentMonthIndex + 1);
        }

        // For past years, show all months
        return allMonths;
    };

    // Generate year options (current year and past 4 years)
    const yearOptions = Array.from({ length: 5 }, (_, i) => ({
        value: currentYear - i,
        label: (currentYear - i).toString()
    }));

    // Reset month when year changes
    useEffect(() => {
        setMonth("");
    }, [year]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${APIData.api}employee/details?org=${org}`;
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                setEmpdata(data.filter(emp => emp.payslip !== null));
            } catch (err) {
                console.error(err);
            }
        };
        const fetchIcon = async () => {
            try {
                const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers });
                if (response.status === 200) {
                    const { orgName, orgAddress } = response.data;
                    setOrgName(orgName || "");
                    setOrgAddress(orgAddress || "");
                } else {
                    console.error("No data found.");
                }
            } catch (error) {
                console.error("Error fetching icon:", error);
            }
        };
        fetchData();
        fetchIcon();
    }, []);

    const fetchPayslip = async (employeeId) => {
        // Validate month and year selection
        if (!month || !year) {
            Swal.fire({
                icon: 'warning',
                title: 'Selection Required',
                text: 'Please select both month and year first',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        setLoading(true);
        try {
            const url = `${APIData.api}payslips/employee/${employeeId}?month=${month}&year=${year}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    ...APIData.headers,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const payslipData = Array.isArray(data) ? data[0] : data;
            setPayslip(payslipData);
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Failed to fetch payslip: ${error.message}`,
                confirmButtonColor: '#3085d6'
            });
        }
        setLoading(false);
    };

    const handleViewPayslip = (employee) => {
        // Validate month and year selection
        if (!month || !year) {
            Swal.fire({
                icon: 'warning',
                title: 'Selection Required',
                text: 'Please select both month and year first',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        setSelectedEmployee(employee);
        setIsDialogOpen(true);
        fetchPayslip(employee.employee_id);
    };

    const handleDownloadPDF = () => {
        if (!payslip) return;

        const doc = new jsPDF();
        const employee = empdata.find(emp => emp.employee_id === payslip.employeeId);

        // Add company details
        doc.setFontSize(16);
        doc.text(orgName, 105, 20, { align: 'center' });
        doc.setFontSize(12);
        doc.text(orgAddress, 105, 30, { align: 'center' });
        doc.text(`Pay Slip for ${month} ${year}`, 105, 40, { align: 'center' });

        // Prepare table data
        const basicInfoData = [
            ['Name of the Employee', payslip.employeeName, 'UAN', payslip.uan || 'Not Applicable'],
            ['Employee ID', payslip.employeeId, 'ESI No', payslip.esi || 'Not Applicable'],
            ['Designation', employee.designation, 'Bank Name', payslip.bankAccount],
            ['Department', employee.dept, 'Bank A/C No', payslip.bankAccount],
            ['DOJ', employee.year_of_appointment, 'Total Working Days', payslip.totalWorkindDays],
            ['Paid Days', payslip.daysPresent, 'LOP days', payslip.daysAbsent]
        ];

        const earningsData = [
            ['Basic Wage', payslip.totalSalary, 'EPF', payslip.pfAmount || 'Not Applicable'],
            ['HRA', payslip.hra || 'Not Applicable', 'Professional Tax', payslip.professionalTax || 'Not Applicable'],
            ['Conveyance Allowances', payslip.conveyanceAllowance || 'Not Applicable', 'TDS', payslip.tds || 'Not Applicable'],
            ['Medical Allowances', payslip.medicalAllowance || 'Not Applicable', 'ESI/Health Insurance', payslip.esi || 'Not Applicable'],
            ['Other Allowances', payslip.otherAllowance || 'Not Applicable', 'LOP', payslip.lop],
            ['Total Earnings', payslip.totalSalary, 'Total Deductions', payslip.totalDeductions || '0']
        ];

        // Add tables without headers
        doc.autoTable({
            startY: 50,
            body: basicInfoData,
            theme: 'grid'
        });

        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 10,
            body: earningsData,
            theme: 'grid'
        });

        // Add Net Salary without header
        doc.autoTable({
            startY: doc.previousAutoTable.finalY + 10,
            body: [['Net Salary', payslip.payableAmount]],
            theme: 'grid'
        });

        // Add footer note
        doc.setFontSize(10);
        doc.text('**This is a computer-generated Pay slip and does not need any signature.',
            105, doc.previousAutoTable.finalY + 20, { align: 'center' });

        // Save PDF
        doc.save(`Payslip_${payslip.employeeName}_${month}_${year}.pdf`);
    };

    // Filtering and Pagination
    const filteredEmployees = empdata.filter(employee =>
        employee.employee_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedEmployees = filteredEmployees.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "User", align: "left" },
        { name: "Department", align: "center" },
        { name: "Designation", align: "center" },
        { name: "Action", align: "center" },

    ];

    const rows = paginatedEmployees.map((employee) => ({
        "User": (
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                    <SoftAvatar
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(employee.name)}&background=random`}
                        alt={employee.name}
                        size="sm"
                        variant="rounded"
                    />
                </SoftBox>
                <SoftTypography variant="button" fontWeight="medium">
                    {employee.name}
                </SoftTypography>
            </SoftBox>
        ),
        Department: (
            <SoftTypography variant="button" fontWeight="medium">
                {employee.dept}
            </SoftTypography>
        ),
        Designation: (
            <SoftTypography variant="button" fontWeight="medium">
                {employee.designation}
            </SoftTypography>
        ),
        Action: (
            <SoftTypography
                component="a"
                variant="button"
                color="info"
                fontWeight="medium"
                onClick={() => handleViewPayslip(employee)}
                sx={{ cursor: 'pointer' }}
            >
                View
            </SoftTypography>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Payslip Management</SoftTypography>
                            <SoftBox display="flex" gap={2} alignItems="center">
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            setPage(0); // Reset to first page when searching
                                        }}
                                        size="small"
                                    />
                                </SoftBox>
                                <CustomSelect
                                    options={yearOptions}
                                    placeholder="Select Year"
                                    onChange={(selectedOption) => setYear(selectedOption.value)}
                                    value={year}
                                />
                                <CustomSelect
                                    options={getMonthOptions(year)}
                                    placeholder="Select Month"
                                    onChange={(selectedOption) => setMonth(selectedOption.value)}
                                    value={month}
                                    isDisabled={!year}
                                />
                            </SoftBox>
                        </SoftBox>

                        {loading ? (
                            <SoftBox display="flex" justifyContent="center" p={3}>
                                <CircularProgress />
                            </SoftBox>
                        ) : (
                            <SoftBox
                                sx={{
                                    "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                    },
                                }}
                            >
                                <Table columns={columns} rows={rows} />
                                <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    p={3}
                                >
                                    <SoftBox display="flex" gap={1} alignItems="center">
                                        <SoftTypography variant="caption" color="secondary">
                                            {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredEmployees.length)} of {filteredEmployees.length}
                                        </SoftTypography>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                            iconOnly
                                        >
                                            <KeyboardArrowLeft />
                                        </SoftButton>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredEmployees.length / itemsPerPage) - 1, prev + 1))}
                                            disabled={page >= Math.ceil(filteredEmployees.length / itemsPerPage) - 1}
                                            iconOnly
                                        >
                                            <KeyboardArrowRight />
                                        </SoftButton>
                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                        )}
                    </Card>
                </SoftBox>
            </SoftBox>

            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent>
                    {loading ? (
                        <SoftBox display="flex" justifyContent="center" p={3}>
                            <CircularProgress />
                        </SoftBox>
                    ) : payslip ? (
                        <Card>
                            <CardHeader
                                title={orgName}
                                titleTypographyProps={{ variant: 'h4', align: 'center' }}
                            />
                            <CardHeader
                                title={orgAddress}
                                titleTypographyProps={{ variant: 'h5', align: 'center' }}
                            />
                            <CardHeader
                                title={`Pay Slip for ${month} ${year}`}
                                titleTypographyProps={{ variant: 'h5', align: 'center' }}
                            />
                            <CardContent>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <MuiTable>
                                        <TableRow>
                                            <TableCell>Name of the Employee</TableCell>
                                            <TableCell>{payslip.employeeName}</TableCell>
                                            <TableCell>UAN</TableCell>
                                            <TableCell>{payslip.uan || "Not Applicable"}</TableCell>
                                        </TableRow>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Employee ID</TableCell>
                                                <TableCell>{payslip.employeeId}</TableCell>
                                                <TableCell>ESI No</TableCell>
                                                <TableCell>{payslip.esi || "Not Applicable"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Designation</TableCell>
                                                <TableCell>{empdata.find(emp => emp.employee_id === payslip.employeeId).designation}</TableCell>
                                                <TableCell>Bank Name</TableCell>
                                                <TableCell>{payslip.bankAccount}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Department</TableCell>
                                                <TableCell>{empdata.find(emp => emp.employee_id === payslip.employeeId).dept}</TableCell>
                                                <TableCell>Bank A/C No</TableCell>
                                                <TableCell>{payslip.bankAccount}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>DOJ</TableCell>
                                                <TableCell>{empdata.find(emp => emp.employee_id === payslip.employeeId).year_of_appointment}</TableCell>
                                                <TableCell>Total Working Days</TableCell>
                                                <TableCell>{payslip.totalWorkindDays}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Paid Days</TableCell>
                                                <TableCell>{payslip.daysPresent}</TableCell>
                                                <TableCell>LOP days</TableCell>
                                                <TableCell>{payslip.daysAbsent}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableRow>
                                            <TableCell colSpan={4} />
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2} align="center">
                                                <b>Earnings</b>
                                            </TableCell>
                                            <TableCell colSpan={2} align="center">
                                                <b>Deductions</b>
                                            </TableCell>
                                        </TableRow>

                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Basic Wage</TableCell>
                                                <TableCell>{payslip.totalSalary}</TableCell>
                                                <TableCell>EPF</TableCell>
                                                <TableCell>{payslip.pfAmount || "Not Applicable"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>HRA</TableCell>
                                                <TableCell>{payslip.hra || "Not Applicable"}</TableCell>
                                                <TableCell>Professional Tax</TableCell>
                                                <TableCell>{payslip.professionalTax || "Not Applicable"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Conveyance Allowances</TableCell>
                                                <TableCell>{payslip.conveyanceAllowance || "Not Applicable"}</TableCell>
                                                <TableCell>TDS</TableCell>
                                                <TableCell>{payslip.tds || "Not Applicable"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Medical Allowances</TableCell>
                                                <TableCell>{payslip.medicalAllowance || "Not Applicable"}</TableCell>
                                                <TableCell>ESI/Health Insurance</TableCell>
                                                <TableCell>{payslip.esi || "Not Applicable"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Other Allowances</TableCell>
                                                <TableCell>{payslip.otherAllowance || "Not Applicable"}</TableCell>
                                                <TableCell>LOP</TableCell>
                                                <TableCell>{payslip.lop}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Earnings</TableCell>
                                                <TableCell>{payslip.totalSalary}</TableCell>
                                                <TableCell>Total Deductions</TableCell>
                                                <TableCell>{payslip.totalDeductions || "0"}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">Net Salary</TableCell>
                                                <TableCell>{payslip.payableAmount}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </MuiTable>
                                </TableContainer>
                            </CardContent>
                            <SoftBox mt={2} display="flex" justifyContent="center" gap={2}>
                                <SoftButton
                                    variant="gradient"
                                    color="secondary"
                                    onClick={() => setIsDialogOpen(false)}
                                >
                                    Close
                                </SoftButton>
                                <SoftButton
                                    variant="gradient"
                                    color="info"
                                    onClick={handleDownloadPDF}
                                    disabled={!payslip}
                                >
                                    Download PDF
                                </SoftButton>
                            </SoftBox>
                        </Card>
                    ) : (
                        <SoftTypography variant="body2" align="center">
                            No payslip data available
                        </SoftTypography>
                    )}
                </DialogContent>
            </Dialog>
            <Footer />
        </DashboardLayout>
    );
};

export default PayslipPage;