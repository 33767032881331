import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Grid,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { Upload, Image } from "lucide-react";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { APIData, org } from '../authentication/APIData';
import * as AiIcons from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import Card from "@mui/material/Card";

const MAX_CHAR_LENGTH = 500;
const MAX_FILE_SIZE = 1 * 1024 * 1024;
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const questionTypeOptions = [
    { value: 'ONE_MARK', label: '1 Mark' },
    { value: 'TWO_MARKS', label: '2 Marks' },
    { value: 'THREE_MARKS', label: '3 Marks' },
    { value: 'FIVE_MARKS', label: '5 Marks' }
];

const importanceOptions = [
    { value: 'YES', label: 'Yes' },
    { value: 'NO', label: 'No' }
];

const AddQuestions = () => {
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        image: '',
        questions: '',
        grade: '',
        question_type: '',
        imp_question: '',
        subject: '',
        chapter: '',
        created_by: sessiondetails?.user,
        org: org
    });

    const [touchedFields, setTouchedFields] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [courses, setCourses] = useState([]);
    const [gradeOptions, setGradeOptions] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [loadingCourses, setLoadingCourses] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = async () => {
        setLoadingCourses(true);
        try {
            const response = await axios.get(`${APIData.api}courses/?org=${org}`, {
                headers: APIData.headers
            });
            
            setCourses(response.data);
            processGradeOptions(response.data);
        } catch (error) {
            console.error('Error fetching courses:', error);
            toast.error("Failed to fetch courses data");
        } finally {
            setLoadingCourses(false);
        }
    };

    const processGradeOptions = (coursesData) => {
        const gradeOpts = [];

        coursesData.forEach(course => {
            if (course.course_type === "ACADEMICS") {
                gradeOpts.push({
                    value: course.course_id,
                    label: course.courseName,
                    type: "ACADEMICS",
                    subjects: course.gradeSubjects
                });
            } else if (course.course_type === "PROFESSIONAL") {
                if (!gradeOpts.some(opt => opt.value === "Professional")) {
                    gradeOpts.push({
                        value: "Professional",
                        label: "Professional",
                        type: "PROFESSIONAL",
                        subjects: coursesData
                            .filter(c => c.course_type === "PROFESSIONAL")
                            .map(c => ({ value: c.course_id, label: c.courseName }))
                    });
                }
            }
        });

        setGradeOptions(gradeOpts);
    };

    const updateSubjectOptions = (courseId) => {
        const selectedGradeOption = gradeOptions.find(opt => opt.value === courseId);
        
        if (selectedGradeOption) {
            setSelectedCourse(selectedGradeOption);

            if (selectedGradeOption.type === "ACADEMICS") {
                // For academic courses, use the subjects array
                const subjectOpts = selectedGradeOption.subjects.map(subject => ({
                    value: subject,
                    label: subject
                }));
                setSubjectOptions(subjectOpts);
            } else if (selectedGradeOption.type === "PROFESSIONAL") {
                // For professional courses, use the course names
                setSubjectOptions(selectedGradeOption.subjects);
            }

            // Reset the subject selection when grade changes
            setFormData(prev => ({
                ...prev,
                subject: ''
            }));
        } else {
            setSelectedCourse(null);
            setSubjectOptions([]);
        }
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (actionMeta.name === "grade") {
            updateSubjectOptions(selectedOption.value);
        }

        setFormData(prev => ({
            ...prev,
            [actionMeta.name]: selectedOption.value
        }));

        setTouchedFields(prev => ({
            ...prev,
            [actionMeta.name]: true
        }));
    };

    useEffect(() => {
        validateForm();
    }, [formData, touchedFields]);

    const validateForm = () => {
        const newErrors = {};

        // Subject validation
        if (!formData.subject && touchedFields.subject) {
            newErrors.subject = "Subject is required";
        } else if (formData.subject && formData.subject.length > 50) {
            newErrors.subject = "Subject cannot exceed 50 characters";
        }

        // Chapter validation
        if (!formData.chapter && touchedFields.chapter) {
            newErrors.chapter = "Chapter is required";
        } else if (formData.chapter && formData.chapter.length > 100) {
            newErrors.chapter = "Chapter cannot exceed 100 characters";
        }

        // Question validation
        if (!formData.questions && touchedFields.questions) {
            newErrors.questions = "Question is required";
        } else if (formData.questions && formData.questions.length > MAX_CHAR_LENGTH) {
            newErrors.questions = `Question cannot exceed ${MAX_CHAR_LENGTH} characters`;
        }

        // Question type validation
        if (!formData.question_type && touchedFields.question_type) {
            newErrors.question_type = "Question type is required";
        }

        // Important question validation
        if (!formData.imp_question && touchedFields.imp_question) {
            newErrors.imp_question = "Please specify if question is important";
        }

        setErrors(newErrors);

        const isValid = Object.keys(newErrors).length === 0 &&
            formData.grade &&
            formData.subject &&
            formData.chapter &&
            formData.questions &&
            formData.question_type &&
            formData.imp_question;

        setIsFormValid(isValid);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        setTouchedFields(prev => ({
            ...prev,
            [name]: true
        }));
    };

    // const handleSelectChange = (selectedOption, actionMeta) => {
    //     if (selectedOption) {
    //         setFormData(prev => ({
    //             ...prev,
    //             [actionMeta.name]: selectedOption.value
    //         }));

    //         setTouchedFields(prev => ({
    //             ...prev,
    //             [actionMeta.name]: true
    //         }));
    //     }
    // };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                setErrors(prev => ({
                    ...prev,
                    image: "Only JPG, PNG and GIF files are allowed"
                }));
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                setErrors(prev => ({
                    ...prev,
                    image: "File size should not exceed 1MB"
                }));
                return;
            }

            setFormData(prev => ({
                ...prev,
                image: file
            }));
            setImagePreview(URL.createObjectURL(file));
            setErrors(prev => ({
                ...prev,
                image: ""
            }));
        }
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        setTouchedFields({
            grade: true,
            subject: true,
            chapter: true,
            questions: true,
            question_type: true,
            imp_question: true
        });

        if (!validateForm()) {
            toast.error("Please fix all errors before submitting");
            return;
        }

        setLoading(true);

        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key]);
            });

            const response = await axios.post(
                APIData.api + 'questions/',
                formDataToSend,
                { headers: APIData.headers }
            );

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Question Added Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });

                navigate("/add-question");
            }
        } catch (error) {
            console.error('Error details:', error);

            let errorMessage = "Error creating question";
            if (error.response) {
                errorMessage = error.response.data?.message || "An unexpected error occurred";
            }

            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: errorMessage,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/all-questions">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add New Question
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={2} pb={3} px={3}>
                            <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Grade</SoftTypography>
                                            <CustomSelect
                                                options={gradeOptions}
                                                placeholder="Select Grade"
                                                name="grade"
                                                onChange={handleSelectChange}
                                                value={formData.grade}
                                                error={!!errors.grade}
                                                isLoading={loadingCourses}
                                            />
                                            {errors.grade && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.grade}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Subject</SoftTypography>
                                            <CustomSelect
                                                options={subjectOptions}
                                                placeholder="Select Subject"
                                                name="subject"
                                                onChange={handleSelectChange}
                                                value={formData.subject}
                                                error={!!errors.subject}
                                                isLoading={loadingCourses}
                                                isDisabled={!selectedCourse}
                                            />
                                            {errors.subject && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.subject}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Chapter</SoftTypography>
                                            <SoftInput
                                                size="small"
                                                placeholder="Enter Chapter"
                                                name="chapter"
                                                value={formData.chapter}
                                                onChange={handleChange}
                                                error={!!errors.chapter}
                                            />
                                            {errors.chapter && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.chapter}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Question</SoftTypography>
                                            <SoftInput
                                                multiline
                                                rows={4}
                                                placeholder="Enter your question"
                                                name="questions"
                                                value={formData.questions}
                                                onChange={handleChange}
                                                error={!!errors.questions}
                                            />
                                            <SoftTypography variant="caption" color={errors.questions ? "error" : "dark"}>
                                                {errors.questions || `${formData.questions.length}/${MAX_CHAR_LENGTH} characters`}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Question Type</SoftTypography>
                                            <CustomSelect
                                                options={questionTypeOptions}
                                                placeholder="Select Question Type"
                                                name="question_type"
                                                onChange={handleSelectChange}
                                                value={formData.question_type}
                                                error={!!errors.question_type}
                                            />
                                            {errors.question_type && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.question_type}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Important Question</SoftTypography>
                                            <CustomSelect
                                                options={importanceOptions}
                                                placeholder="Is this an important question?"
                                                name="imp_question"
                                                onChange={handleSelectChange}
                                                value={formData.imp_question}
                                                error={!!errors.imp_question}
                                            />
                                            {errors.imp_question && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.imp_question}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mb={2} className="border-2 border-dashed border-gray-300 rounded-lg p-4">
                                            <input
                                                accept="image/*"
                                                id="image-upload"
                                                type="file"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="image-upload">
                                                <SoftButton
                                                    component="span"
                                                    variant="outlined"
                                                    color="info"
                                                    startIcon={imagePreview ? <Image className="w-5 h-5" /> : <Upload className="w-5 h-5" />}
                                                    fullWidth
                                                >
                                                    {imagePreview ? 'Change Image' : 'Upload Image'}
                                                </SoftButton>
                                            </label>
                                            {errors.image && (
                                                <SoftTypography variant="caption" color="error" display="block" textAlign="center" mt={1}>
                                                    {errors.image}
                                                </SoftTypography>
                                            )}
                                            {imagePreview && (
                                                <SoftBox mt={2} display="flex" justifyContent="center">
                                                    <img
                                                        src={imagePreview}
                                                        alt="Preview"
                                                        style={{ maxWidth: '300px', maxHeight: '200px', objectFit: 'contain' }}
                                                        className="rounded-lg shadow-lg"
                                                    />
                                                </SoftBox>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mt={4} mb={1}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={handleSubmit}
                                                disabled={loading || !isFormValid}
                                            >
                                                {loading ? "Adding Question..." : "Add Question"}
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default AddQuestions;