import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@mui/material';
import logo from '../../assets/images/c4e.png';

const containerStyle = {
  fontFamily: 'Arial, sans-serif',
  lineHeight: '1.6',
  color: '#333',
};

const headerStyle = {
 
  marginBottom: '40px',
};

const titleStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '10px 0',
};

const subtitleStyle = {
  fontSize: '18px',
  margin: '5px 0',
};

const sectionTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  margin: '20px 0 10px 0',
};

const textStyle = {
  margin: '10px 0',
};

const signatureStyle = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'space-between',
};

const signOffStyle = {
  fontWeight: 'bold',
};




function OfferLetter() {

  return (
    <>
    <Typography variant="h5" gutterBottom>
    Default Template
          </Typography>
    <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
        
      <div style={containerStyle}>
      <img src={logo} style={{ width: 40, height: 40 }} />
              <div style={headerStyle}>
                <div style={titleStyle}>Care4Edu Solutions Pvt Ltd</div>
                <div style={subtitleStyle}>+91 7259785184 | info@care4edu.com | www.care4edu.com</div>
              </div>
            <div style={{  textAlign: "center", fontSize: '20px', fontWeight: 'bold', }} >EMPLOYMENT OFFER LETTER</div>
           
              <div style={textStyle}>Date: 03/08/2024</div>
              <div style={textStyle}>HI AMULYA,</div>

              <div style={textStyle}>
                Congratulations! We are pleased to offer you the position of <strong>React Developer Intern</strong> at Care4Edu Solutions Pvt Ltd. We are delighted to make you the following job offer.
              </div>
              <div style={textStyle}> The position we are offering you is that of React Developer Intern .  </div>
              <div style={sectionTitleStyle}>Date of Appointment</div>
              <div style={textStyle}>
                Your date of appointment as per company records.
              </div>

              <div style={sectionTitleStyle}>Posting and Transfer</div>
              <div style={textStyle}>
                Your services are liable to be transferred, at the sole discretion of Management, in such other capacity as the company may determine, to any department/section, location, associate, a sister concern, or subsidiary, at any place in India or abroad, whether existing today or which may come up in future. In such a case, you will be governed by the terms and conditions of the service applicable at the new placement location.
              </div>

              <div style={sectionTitleStyle}>Confidentiality</div>
              <div style={textStyle}>
                You will not, at any time, during the internship or after, without the consent of the Management disclose or divulge or make public, except on legal obligations, any information regarding the Company’s affairs or administration or research carried out, whether the same is confided to you or becomes known to you in the course of your service or otherwise.
              </div>

              <div style={sectionTitleStyle}>Intellectual Property</div>
              <div style={textStyle}>
                If you conceive any new or advanced method of improving designs/ processes/ formulae/ systems, etc. in relation to the business/operations of the Company, such developments will be fully communicated to the company and will be, and remain, the sole right/property of the Company.
              </div>

              <div style={sectionTitleStyle}>Responsibilities and Duties</div>
              <div style={textStyle}>
                Your work in the organization will be subject to the rules and regulations of the organization as laid down in relation to conduct, discipline, and other matters. You will always be alive to the responsibilities and duties attached to your office and conduct yourself accordingly. You must effectively perform to ensure results.
              </div>

              <div style={sectionTitleStyle}>Authority</div>
              <div style={textStyle}>
                No authority is vested upon you to make any financial commitment and enter into agreements/contracts/understandings of any nature with any second party and third party without seeking the prior permission/approval of the management.
              </div>

              <div style={sectionTitleStyle}>Stipend</div>
              <div style={textStyle}>
                During the Internship period, you will be paid a consolidated monthly Stipend of <strong>Rs. 10,000</strong> (Rupees Ten Thousand Only) per month. The stipend will be credited to your account on the 15th of every month.
              </div>

              <div style={sectionTitleStyle}>Working Hours</div>
              <div style={textStyle}>
                Your working hours will be 9:30 AM to 6:30 PM (Monday to Friday) as per the current company policy. The company observes a 45 Hours work week. However, based on the project timelines, the intern should be ready for flexible work timings.
              </div>

              <div style={sectionTitleStyle}>Leave</div>
              <div style={textStyle}>
                You will be governed by the current Leave Policy of the company for permanent employees. In any case of extra leaves, the interns should get the required permission from your college attested by your internship coordinator/HOD without which the company will not be accepting the leaves. Also, if there’s any unavoidable circumstance, then you shall compensate on your weekly off.
              </div>

              <div style={textStyle}>
                Please enclose the signed copy of this letter by 05/08/2024 else it would be deferred.
              </div>

              <div style={signatureStyle}>
                <div>
                  <div>Offered by,</div>
                  <div style={signOffStyle}>Care4Edu Solutions Pvt Ltd</div>
                </div>
                <div>
                  <div>Accepted by,</div>
                  <div style={signOffStyle}>AMULYA</div>
                </div>
              </div>

            </div>
         
        </Paper>
        </>
  );
}

export default OfferLetter;
