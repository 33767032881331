import React, { useState, useEffect } from "react";
import { APIData, org } from '../authentication/APIData';
import { CircularProgress, Grid } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import DefaultProjectCard1 from "examples/Cards/ProjectCards/DefaultProjectCard/index1";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const sessiondetails = localStorage.getItem("sessiondetails");

const Courses = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCourses = async () => {
        setLoading(true);
        try {
            const url = `${APIData.api}courses/?org=${org}`;
            const response = await axios.get(url, { headers: APIData.headers });
            setCourses(response.data);
        } catch (err) {
            setError(err.message);
            console.error("Error fetching courses", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    const handleCourseClick = (course_id) => {
        navigate(`/course-details/${course_id}`);
    };

    if (loading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="calc(100vh - 100px)"
                    className="overflow-hidden"
                >
                    <CircularProgress />
                    <SoftTypography variant="h6" ml={2}>
                        Loading...
                    </SoftTypography>
                </SoftBox>
                <Footer />
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox
                display="flex"
                flexDirection="column"
                minHeight="calc(100vh - 100px)"
                className="overflow-y-auto"
            >
                <SoftBox flex={1} mb={3} mt={5}>
                    <Card className="h-full">
                        <SoftBox pt={2} px={2}>
                            <SoftBox mb={1}>
                                <SoftTypography
                                    variant="h4"
                                    fontWeight="regular"
                                    textAlign="center"
                                    style={{ fontFamily: "Georgia, serif" }}
                                >
                                    Explore our Courses
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>

                        <SoftBox p={2}>
                            {error ? (
                                <SoftTypography color="error" align="center">
                                    {error}
                                </SoftTypography>
                            ) : courses && courses.length > 0 ? (
                                <Grid container spacing={3}>
                                    {courses.map((course, index) => (
                                        <Grid item xs={12} md={6} xl={3} key={course.id || index}>
                                            <div
                                                onClick={() => handleCourseClick(course.course_id)} 
                                                style={{ cursor: 'pointer' }}
                                                className="transition-transform duration-300 hover:scale-105"
                                            >
                                                <DefaultProjectCard1
                                                    image={course.image_url}
                                                    title={course.courseName}
                                                    label={`Type: ${course.course_type}`}
                                                    description={
                                                        <>
                                                            <SoftTypography variant="body2" color="text">
                                                                Duration: {course.course_duration}
                                                            </SoftTypography>
                                                            <SoftTypography variant="body2" color="text">
                                                                Fees: {course.course_fees}
                                                            </SoftTypography>
                                                        </>
                                                    }
                                                    action={{
                                                        type: "internal",
                                                        route: "javascript:void(0)",
                                                        color: "info",
                                                        label: "Apply Now"
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <SoftBox display="flex" flexDirection="column" alignItems="center" p={3}>
                                    <SoftTypography
                                        variant="h4"
                                        color="info"
                                        style={{
                                            fontFamily: "Georgia, serif",
                                            fontWeight: "bold",
                                            fontSize: "50px"
                                        }}
                                    >
                                        Currently, we are not offering any courses.
                                    </SoftTypography>
                                    <Link to='/' style={{ textDecoration: 'none', marginTop: '20px' }}>
                                        <SoftButton variant="contained" color="info">
                                            Go Back
                                        </SoftButton>
                                    </Link>
                                </SoftBox>
                            )}
                        </SoftBox>
                    </Card>
                </SoftBox>
                <Footer />
            </SoftBox>
        </DashboardLayout>
    );
};

export default Courses;
