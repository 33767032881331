import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@mui/material';
import logo from '../../assets/images/c4e.png';

const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
};

const headerStyle = {

    marginBottom: '40px',
};

const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const subtitleStyle = {
    fontSize: '18px',
    margin: '5px 0',
};

const sectionTitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '20px 0 10px 0',
};

const textStyle = {
    margin: '10px 0',
};

const signatureStyle = {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
};

const signOffStyle = {
    fontWeight: 'bold',
};




function Nda() {

    return (
        <>
            <Typography variant="h5" gutterBottom>
            Default Template
            </Typography>
            <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                <div style={containerStyle}>
                    <div style={headerStyle}>
                        <img src={logo} style={{ width: 40, height: 40 }} />
                        <div style={titleStyle}>Care4Edu Solutions Pvt Ltd</div>
                        <div style={subtitleStyle}>+91 7259785184|info@care4edu.com |care4edu.com </div>
                    </div>

                    <div style={{ fontFamily: 'Arial', fontSize: '17px' }}>

                        <h3 style={{ textAlign: 'center' }}>
                            NON-DISCLOSURE AGREEMENT
                        </h3>
                        <p>
                            <strong>I. THE PARTIES.</strong> This Software Development Non-Disclosure Agreement, hereinafter known as the
                            “Agreement”, is created on 06/08/2024 by and between Care4Edu Solutions Pvt Ltd , hereinafter known
                            as the “1st Party”, and Adhithyan P A , with reference to the Aadhar number : null of Adhithyan P A
                            hereinafter known as the “2nd Party”, and collectively known as the “Parties”.
                        </p>
                        <p>
                            WHEREAS, this Agreement is created for the purpose of preventing the unauthorized disclosure of the
                            confidential and proprietary information regarding the development of Care4Edu Software Products
                            with its purpose of Online Hiring , hereinafter known as the “Software”. The Parties agree as follows:
                        </p>
                        <h4>II. TYPE OF AGREEMENT. Check One (1)</h4>
                        <p>
                            Mutual – This Agreement shall be Mutual, whereas, the Parties shall be prohibited from disclosing
                            confidential and proprietary information that is to be shared between one another in an effort to
                            develop the Software.
                        </p>
                        <p>
                            Unilateral – This Agreement shall be Unilateral, whereas, the 1st Party shall have sole ownership of
                            the Software with the 2nd Party being prohibited from disclosing confidential and proprietary
                            information that is to be released by the 1st Party in an effort to develop the Software.
                        </p>
                        <h4>III. DEFINITION.</h4>
                        <p>
                            For the purposes of this Agreement, the term “Confidential Information” shall include, but not be
                            limited to, software products, software source code or any related codes in all formats, business plans,
                            financial statements, customers or users, analytical data, documentation, and correspondences that
                            have not otherwise been made publicly available.
                        </p>
                        <p>
                            However, Confidential Information does not include:
                        </p>
                        <ul>
                            <li>information generally available to the public;</li>
                            <li>widely used programming practices or algorithms;</li>
                            <li>information rightfully in the possession of the Parties prior to signing this Agreement; and</li>
                            <li>
                                information independently developed without the use of any of the provided Confidential
                                Information
                            </li>
                        </ul>
                        <h4>IV. OBLIGATIONS</h4>
                        <p>
                            The obligations of the Parties shall be to hold and maintain the Confidential Information in the
                            strictest of confidence at all times and to their agents, employees, representatives, affiliates, and any
                            other individual or entity that is on a “need to know” basis. If any such Confidential Information shall
                            reach a third (3rd) party or becomes public, all liability will be on the Party that is responsible. Neither
                            Party shall, without the written approval of the other Party, publish, copy, or use the Confidential
                            Information for their sole benefit. If requested, either Party shall be bound to return any and all
                            materials to the Requesting Party within 30 days.
                        </p>
                        <p>
                            This Section shall not apply to the 1st Party if this Agreement is Unilateral as marked in Section II.
                        </p>
                        <h4>V. TIME PERIOD.</h4>
                        <p>
                            The bounded Party’s (ies’) duty to hold the Confidential Information in confidence shall remain in
                            effect until such information no longer qualifies as a trade secret or written notice is given releasing
                            such Party from this Agreement.
                        </p>
                        <h4>VI. RELATIONSHIP.</h4>
                        <p>
                            The Parties agree that there is no such statement in this Agreement that suggests any Party is an
                            employee, partner, or that the Software is a joint venture. All ownership interests, if any, shall be
                            stated in a separate agreement.
                        </p>

                        <h4>VII. SEVERABILITY.</h4>
                        <p>
                            If a court finds that any provision of this Agreement is invalid or unenforceable, the remainder of
                            this Agreement shall be interpreted so as best to affect the intent of the Parties.
                        </p>
                        <h4>VIII. INTEGRATION.</h4>
                        <p>
                            This Agreement expresses the complete understanding of the Parties with respect to the subject
                            matter and supersedes all prior proposals, agreements, representations, and understandings. This
                            Agreement may not be amended except in writing with the acknowledgment of the Parties.
                        </p>
                        <h4>IX. ENFORCEMENT.</h4>
                        <p>
                            The Parties acknowledge and agree that due to the unique and sensitive nature of the Confidential
                            Information, any breach of this Agreement would cause irreparable harm for which damages and or
                            equitable relief may be sought. The harmed Party shall be entitled to all remedies available at law.
                        </p>
                        <p>
                            Date: 09/08/2024
                        </p>
                        <p>
                            Date: 09/08/2024
                        </p>
                        <h4>X. GOVERNING LAW.</h4>
                        <p>
                            This Agreement shall be governed under the laws in the State of KARNATAKA.
                        </p>
                        <p>
                            Shape
                        </p>
                        <p>
                            1st Party’s Signature
                        </p>
                        <p>
                            Rishi
                        </p>
                        <p>
                            2nd Party’s Signature
                        </p>
                        <p>
                            Adhithyan P A
                        </p>
                        <p>
                            Vidyaranyapura
                        </p>
                    </div>
                </div>
            </Paper>
        </>
    );
}

export default Nda;
