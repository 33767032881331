import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    CircularProgress,
    Alert,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import {
    Send as SendIcon,
    AttachFile as AttachFileIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Notifications as NotificationsIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import { APIData, org } from 'authentication/APIData';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import PropTypes from 'prop-types';
import { Badge, CircleIcon, MailIcon } from 'lucide-react';

const MentorChat = () => {
    const [messages, setMessages] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [reply, setReply] = useState('');
    const [loading, setLoading] = useState(false);
    const [mentorId, setMentorId] = useState('');
    const [error, setError] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = React.useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const email = sessionDetails?.email;
    const [openMassNotification, setOpenMassNotification] = useState(false);
    const [massNotificationData, setMassNotificationData] = useState({
        grade: '',
        comments: '',
        mentorFile: null
    });
    const [sendingMassNotification, setSendingMassNotification] = useState(false);
    const massNotificationFileRef = React.useRef(null);

    const handleOpenMassNotification = () => {
        setOpenMassNotification(true);
    };

    const handleCloseMassNotification = () => {
        setOpenMassNotification(false);
        setMassNotificationData({
            grade: '',
            comments: '',
            mentorFile: null
        });
    };

    const handleMassNotificationChange = (event) => {
        const { name, value } = event.target;
        setMassNotificationData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleMassNotificationFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                setError('Please select only image files (JPEG, PNG, GIF)');
                return;
            }
            if (file.size > 5 * 1024 * 1024) {
                setError('File size should not exceed 5MB');
                return;
            }
            setMassNotificationData(prev => ({
                ...prev,
                mentorFile: file
            }));
        }
    };

    const handleSendMassNotification = async () => {
        try {
            setSendingMassNotification(true);
            const formData = new FormData();
            formData.append('grade', massNotificationData.grade);
            formData.append('org', org);
            formData.append('comments', massNotificationData.comments);
            formData.append('mentorId', email);
            if (massNotificationData.mentorFile) {
                formData.append('mentorFile', massNotificationData.mentorFile);
            }

            const headers = { ...APIData.headers };
            delete headers['Content-Type']; // Let browser set content type for FormData

            const response = await fetch(`${APIData.api}student/updates/bulk-create`, {
                method: 'POST',
                headers: headers,
                body: formData
            });

            if (!response.ok) {
                throw new Error(`Failed to send mass notification: ${response.status}`);
            }

            // Close dialog and reset form
            handleCloseMassNotification();
            // Optionally refresh messages list
            // fetchMessages();
        } catch (error) {
            console.error('Error sending mass notification:', error);
            setError('Failed to send mass notification. Please try again.');
        } finally {
            setSendingMassNotification(false);
        }
    };

    // Add Mass Notification Dialog
    const MassNotificationDialog = () => (
        <Dialog
            open={openMassNotification}
            onClose={handleCloseMassNotification}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Send Mass Notification</Typography>
                    <IconButton onClick={handleCloseMassNotification}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel>Grade</InputLabel>
                        <Select
                            name="grade"
                            value={massNotificationData.grade}
                            onChange={handleMassNotificationChange}
                            label="Grade"
                        >
                            <MenuItem value="class 10">Class 10</MenuItem>
                            <MenuItem value="class 9">Class 9</MenuItem>
                            <MenuItem value="class 8">Class 8</MenuItem>
                            {/* Add more grades as needed */}
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name="comments"
                        label="Message"
                        value={massNotificationData.comments}
                        onChange={handleMassNotificationChange}
                        variant="outlined"
                    />

                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={massNotificationFileRef}
                        onChange={handleMassNotificationFileSelect}
                    />

                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Button
                            variant="outlined"
                            onClick={() => massNotificationFileRef.current.click()}
                            startIcon={<AttachFileIcon />}
                        >
                            Attach Image
                        </Button>
                        {massNotificationData.mentorFile && (
                            <Typography variant="body2" color="textSecondary">
                                {massNotificationData.mentorFile.name}
                            </Typography>
                        )}
                    </Box>

                    {massNotificationData.mentorFile && (
                        <Box sx={{ mt: 2 }}>
                            <img
                                src={URL.createObjectURL(massNotificationData.mentorFile)}
                                alt="Preview"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '200px',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseMassNotification}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={handleSendMassNotification}
                    disabled={!massNotificationData.grade || !massNotificationData.comments || sendingMassNotification}
                    startIcon={sendingMassNotification ? <CircularProgress size={20} /> : <SendIcon />}
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );

    const updateViewStatus = async (messageId) => {
        try {
            const url = `${APIData.api}student/updates/update-view-status/${messageId}?viewStatus=READ`;
            await fetch(url, {
                method: 'PUT',
                headers: APIData.headers
            });
            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.id === messageId ? { ...msg, viewStatus: 'READ' } : msg
                )
            );
        } catch (error) {
            console.error('Error updating view status:', error);
            setError('Failed to update message status');
        }
    };

    const handleMessageSelect = async (message) => {
        setSelectedChat(message);
        if (message.viewStatus === 'UNREAD') {
            await updateViewStatus(message.id);
        }
    };

    const formatTime = (dateArray) => {
        try {
            const date = new Date(
                dateArray[0],
                dateArray[1] - 1,
                dateArray[2],
                dateArray[3],
                dateArray[4],
                dateArray[5]
            );
            return date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
        } catch (error) {
            console.error('Error formatting time:', error);
            return 'Invalid time';
        }
    };

    const isToday = (dateTimeArray) => {
        if (!Array.isArray(dateTimeArray)) return false;
        const [year, month, day] = dateTimeArray;
        const messageDate = new Date(year, month - 1, day);
        const today = new Date();
        return messageDate.toDateString() === today.toDateString();
    };

    useEffect(() => {
        const fetchMentorId = async () => {
            try {
                const response = await fetch(`${APIData.api}studentadmissiondetails/?org=${org}`, {
                    headers: APIData.headers
                });
                const data = await response.json();
                if (data && data.mentor) {
                    setMentorId(data.mentor);
                    fetchMessages(data.mentor);
                }
            } catch (error) {
                console.error('Error fetching mentor ID:', error);
                setError('Failed to fetch mentor ID');
            }
        };

        const fetchMessages = async () => {
            setLoading(true);
            try {
                const currentDate = new Date();
                const url = `${APIData.api}student/updates/mentor?mentorId=${email}&org=${org}&year=${currentDate.getFullYear()}&month=${currentDate.getMonth() + 1}`;
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                const todayMessages = Array.isArray(data) ?
                    data.filter(msg =>
                        isToday(msg.createdDateTime) &&
                        msg.createdBy !== email
                    ) : [];
                setMessages(todayMessages);
            } catch (error) {
                console.error('Error fetching messages:', error);
                setError('Failed to fetch messages');
            }
            setLoading(false);
        };

        fetchMessages();
        fetchMentorId();
    }, []);

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        console.log('Selected files:', files); // Debug log

        // Validate file types
        const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
        const invalidFiles = files.filter(file => !validTypes.includes(file.type));
        if (invalidFiles.length > 0) {
            setError('Please select only image files (JPEG, PNG, GIF)');
            return;
        }

        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        console.log('Total size:', totalSize); // Debug log

        if (files.length > 4) {
            setError('Maximum 4 images allowed');
            return;
        }

        if (totalSize > 5 * 1024 * 1024) {
            setError('Total file size should not exceed 5MB');
            return;
        }

        setSelectedFiles(files);
        console.log('Files set to state:', files); // Debug log
    };


    const uploadImages = async (messageId) => {
        try {
            const uploadedImages = [];

            for (const file of selectedFiles) {
                const formData = new FormData();
                formData.append('mentorFile', file);

                const headers = { ...APIData.headers };
                delete headers['Content-Type'];

                const response = await fetch(`${APIData.api}student/updates/${messageId}/upload-images`, {
                    method: 'POST',
                    headers: headers,
                    body: formData
                });

                if (!response.ok) {
                    throw new Error(`Upload failed with status: ${response.status}`);
                }

                const data = await response.json();
                uploadedImages.push(data.imageUrl); // Assuming the API returns the image URL
            }

            // Update the selected chat with new images immediately
            setSelectedChat(prevChat => ({
                ...prevChat,
                mentorImages: [...(prevChat.mentorImages || []), ...uploadedImages]
            }));

            setSelectedFiles([]);
            return uploadedImages;
        } catch (error) {
            console.error('Error uploading image:', error);
            setError('Failed to upload image. Please try again.');
            throw error;
        }
    };

    const handleSendReply = async () => {
        if (!selectedFiles.length && !reply.trim()) return;

        try {
            setLoading(true);

            let payload = { ...selectedChat };

            // Only add a new query if there's a text reply
            if (reply.trim()) {
                const newQuery = {
                    comments: reply,
                    updatedBy: email,
                    updatedDateTime: [
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        new Date().getDate(),
                        new Date().getHours(),
                        new Date().getMinutes(),
                        new Date().getSeconds()
                    ]
                };
                payload.queries = [...selectedChat.queries, newQuery];
            }

            // Send the message update first if there's a text reply
            if (reply.trim()) {
                const response = await fetch(`${APIData.api}student/updates`, {
                    method: 'POST',
                    headers: {
                        ...APIData.headers,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    throw new Error(`Reply failed with status: ${response.status}`);
                }

                payload = await response.json();
            }

            // Handle image upload
            if (selectedFiles.length > 0) {
                const uploadedImages = await uploadImages(payload.id || selectedChat.id);
                payload = {
                    ...payload,
                    mentorImages: [...(payload.mentorImages || []), ...uploadedImages]
                };
            }

            // Update local state
            setSelectedChat(payload);
            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.id === selectedChat.id ? payload : msg
                )
            );
            setReply('');

        } catch (error) {
            console.error('Error in send reply:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const ImageGallery = ({ images }) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Uploaded ${index + 1}`}
                    style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                        borderRadius: '4px'
                    }}
                />
            ))}
        </Box>
    );


    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const ImageStrip = ({ images }) => (
        <Box sx={{ display: 'flex', gap: 1, overflowX: 'auto', py: 1 }}>
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    onClick={() => handleImageClick(image)}
                    style={{
                        height: '60px',
                        width: '60px',
                        objectFit: 'cover',
                        cursor: 'pointer',
                        borderRadius: '4px'
                    }}
                />
            ))}
        </Box>
    );

    ImageGallery.propTypes = {
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
    ImageStrip.propTypes = {
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
    return (
        <DashboardLayout>
            <Box sx={{ display: 'flex', height: '95vh', bgcolor: '#f5f5f5' }}>
                <Paper sx={{ width: '280px', overflow: 'hidden', borderRadius: 0 }}>
                    <Box sx={{ p: 2 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<NotificationsIcon />}
                            sx={{ mb: 2 }}
                            onClick={handleOpenMassNotification}
                        >
                            Send Mass Notification
                        </Button>

                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <List>
                                {messages.map((message) => (
                                    <ListItem
                                        key={message.id}
                                        button
                                        selected={selectedChat?.id === message.id}
                                        onClick={() => handleMessageSelect(message)}
                                        sx={{
                                            bgcolor: message.viewStatus === 'UNREAD' ? 'rgba(0, 200, 83, 0.05)' : 'transparent',
                                            '&:hover': {
                                                bgcolor: message.viewStatus === 'UNREAD' ? 'rgba(0, 200, 83, 0.1)' : undefined,
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Typography variant="subtitle1">
                                                            {message.studentName}
                                                        </Typography>
                                                        {message.viewStatus === 'UNREAD' && (
                                                            <CircleIcon
                                                                sx={{
                                                                    color: '#00c853',
                                                                    fontSize: 12,
                                                                    animation: message.viewStatus === 'UNREAD' ? 'pulse 2s infinite' : 'none',
                                                                    '@keyframes pulse': {
                                                                        '0%': {
                                                                            opacity: 1,
                                                                        },
                                                                        '50%': {
                                                                            opacity: 0.5,
                                                                        },
                                                                        '100%': {
                                                                            opacity: 1,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                    {message.viewStatus === 'UNREAD' ? (
                                                        <Badge color="success" variant="dot">
                                                            <MailIcon sx={{ color: '#00c853' }} />
                                                        </Badge>
                                                    ) : (
                                                        <VisibilityIcon color="primary" fontSize="small" />
                                                    )}
                                                </Box>
                                            }
                                            secondary={
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{
                                                        fontWeight: message.viewStatus === 'UNREAD' ? 600 : 400
                                                    }}
                                                >
                                                    {message.grade}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                </Paper>

                {/* Right side - Chat view */}
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {selectedChat ? (
                        <>
                            <Paper sx={{ p: 2, borderRadius: 0 }}>
                                <Typography variant="h6">{selectedChat.studentName}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {selectedChat.grade}
                                </Typography>
                            </Paper>

                            <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                                {selectedChat.queries.map((query, index) => {
                                    const isMentorMessage = query.updatedBy === APIData.sessiondetails.email;
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                maxWidth: '70%',
                                                mb: 2,
                                                ml: isMentorMessage ? 'auto' : 0
                                            }}
                                        >
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    p: 2,
                                                    bgcolor: isMentorMessage ? '#e3f2fd' : '#fff'
                                                }}
                                            >
                                                <Typography>{query.comments}</Typography>
                                                <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
                                                    {formatTime(query.updatedDateTime)}
                                                </Typography>
                                            </Paper>
                                        </Box>
                                    );
                                })}

                                {/* Student Images Strip */}
                                {selectedChat.studentImages && selectedChat.studentImages.length > 0 && (
                                    <Box sx={{ maxWidth: '100%', mb: 2 }}>
                                        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mb: 1 }}>
                                            Student Images
                                        </Typography>
                                        <ImageStrip images={selectedChat.studentImages} />
                                    </Box>
                                )}

                                {/* Mentor Images Strip */}
                                {selectedChat.mentorImages && selectedChat.mentorImages.length > 0 && (
                                    <Box sx={{ maxWidth: '100%', mb: 2 }}>
                                        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mb: 1 }}>
                                            Mentor Images
                                        </Typography>
                                        <ImageStrip images={selectedChat.mentorImages} />
                                    </Box>
                                )}
                            </Box>

                            <Paper sx={{ p: 2, borderRadius: 0 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    {selectedFiles.length > 0 && (
                                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                            {Array.from(selectedFiles).map((file, index) => (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        position: 'relative',
                                                        width: 60,
                                                        height: 60,
                                                        borderRadius: 1,
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt={`Preview ${index + 1}`}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    )}

                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <input
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileSelect}
                                        />
                                        <IconButton
                                            onClick={() => fileInputRef.current.click()}
                                            disabled={loading}
                                        >
                                            <AttachFileIcon />
                                        </IconButton>
                                        <TextField
                                            // fullWidth
                                            multiline
                                            rows={3}
                                            value={reply}
                                            onChange={(e) => setReply(e.target.value)}
                                            placeholder="Type your reply..."
                                            variant="outlined"
                                            disabled={loading}
                                        />
                                        <IconButton
                                            color="primary"
                                            onClick={handleSendReply}
                                            disabled={loading || (!reply.trim() && !selectedFiles.length)}
                                        >
                                            {loading ? <CircularProgress size={24} /> : <SendIcon />}
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Paper>

                            {/* Image Preview Modal */}
                            <Modal
                                open={!!selectedImage}
                                onClose={handleCloseModal}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: '90vw',
                                        maxHeight: '90vh',
                                        outline: 'none',
                                        p: 1,
                                        bgcolor: 'background.paper',
                                        borderRadius: 1
                                    }}
                                >
                                    <img
                                        src={selectedImage}
                                        alt="Preview"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '85vh',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </Box>
                            </Modal>
                        </>
                    ) : (
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Typography color="textSecondary">
                                Select a message to view the conversation
                            </Typography>
                        </Box>
                    )}
                </Box>

                {error && (
                    <Alert
                        severity="error"
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}
                        onClose={() => setError('')}
                    >
                        {error}
                    </Alert>
                )}
                <MassNotificationDialog />

                {error && (
                    <Alert
                        severity="error"
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}
                        onClose={() => setError('')}
                    >
                        {error}
                    </Alert>
                )}
            </Box>
        </DashboardLayout>
    );
};

export default MentorChat;