import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    TableRow, 
    TableCell, 
    Button, 
    Backdrop, 
    CircularProgress, 
    Card, 
    DialogActions, 
    TextField, 
    DialogContent, 
    DialogTitle, 
    Dialog, 
    Typography, 
    Tooltip 
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Table from "examples/Tables/Table";
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftAvatar from 'components/SoftAvatar';
import { APIData, org } from 'authentication/APIData';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import { useNavigate } from 'react-router-dom';

const HolidayList = () => {
    const [tasks, setTasks] = useState([]);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const [fullDescription, setFullDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
    const [selectedDescription, setSelectedDescription] = useState('');

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    const [editForm, setEditForm] = useState({
        holiday_name: '',
        holidayDate: '',
        description: ''
    });
    const [errors, setErrors] = useState({
        holiday_name: '',
        holidayDate: '',
        description: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [org]);

    const fetchData = async () => {
        try {
            const url = `${APIData.api}holidays/?org=${org}`;
            const response = await fetch(url, { headers: APIData.headers });
            if (response.ok) {
                const data = await response.json();
                setTasks(data);
                setLoading(false);
            } else {
                Swal.fire('Error', 'Failed to fetch holidays', 'error');
                setLoading(false);
            }
        } catch (error) {
            Swal.fire('Error', 'Error fetching holidays', 'error');
            setLoading(false);
        }
    };

    const handleSort = (field) => {
        let direction = "asc";
        if (sortField === field && sortDirection === "asc") {
            direction = "desc";
        }
        setSortField(field);
        setSortDirection(direction);
    };

    const handleUpdate = (task) => {
        setIsDialogOpen(true);
        setSelectedTask(task);
        setEditForm({
            holiday_name: task.holiday_name,
            holidayDate: task.holidayDate,
            description: task.description
        });
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;
        if (!editForm.holiday_name.trim()) {
            newErrors.holiday_name = 'Holiday name is required';
            isValid = false;
        }
        if (editForm.holiday_name.length > 50) {
            newErrors.holiday_name = 'Holiday name must be less than 50 characters';
            isValid = false;
        }
        if (!editForm.holidayDate) {
            newErrors.holidayDate = 'Holiday date is required';
            isValid = false;
        }
        if (!editForm.description.trim()) {
            newErrors.description = 'Description is required';
            isValid = false;
        }
        if (editForm.description.length > 250) {
            newErrors.description = 'Description must be less than 250 characters';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleEditFormChange = (e) => {
        const { name, value } = e.target;
        setEditForm((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = () => {
        if (validateForm()) {
            handleUpdateHoliday(editForm);
            setIsDialogOpen(false);
        }
    };

    const handleUpdateHoliday = async () => {
        setIsDialogOpen(false);
        const form = {
            id: selectedTask.id,
            holiday_name: editForm.holiday_name,
            holidayDate: editForm.holidayDate,
            description: editForm.description,
            org: selectedTask.org
        };
        const url = `${APIData.api}holidays/${selectedTask.id}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...APIData.headers
                },
                body: JSON.stringify(form),
            });

            const data = await response.json();

            if (response.status === 200 || response.status === 201) {
                Swal.fire('Success', 'Updated successfully', 'success');
                fetchData();
            } else {
                Swal.fire('Error', 'Failed to update', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred during the update', 'error');
            console.error('Error during the PUT request:', error);
        }
    };

    const handleDelete = (task) => {
        const id = task.id;
        const url = `${APIData.api}holidays/?id=${id}`;
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone once deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(url, {
                    method: 'DELETE',
                    headers: APIData.headers,
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === 'success') {
                            Swal.fire('Success', 'Deleted successfully', 'success');
                            fetchData();
                        } else {
                            Swal.fire('Error', 'Failed to delete', 'error');
                        }
                    })
                    .catch(error => {
                        Swal.fire('Error', "An error occurred", 'error');
                    });
            }
        });
    };

    const handleNewHolidayClick = () => {
        navigate("/holiday-form");
    };

    const filteredTasks = tasks.filter(task =>
        task.holiday_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.holidayDate.includes(searchTerm) ||
        task.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedTasks = [...filteredTasks].sort((a, b) => {
        if (!sortField) return 0;
        const aValue = a[sortField];
        const bValue = b[sortField];
        return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
    });

    const paginatedTasks = sortedTasks.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "Holiday Name", align: "left" },
        { name: "Holiday Date", align: "left" },
        { name: "Description", align: "left" },
        ...(permission==="1110" || permission==="1111" ? [{ name: "Action", align: "center" }] : [])
    ];

    const rows = paginatedTasks.map((task) => ({
        "Holiday Name": (
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                    <SoftAvatar
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(task.holiday_name)}&background=random`}
                        alt={task.holiday_name}
                        size="sm"
                        variant="rounded"
                    />
                </SoftBox>
                <SoftTypography variant="button" fontWeight="medium">
                    {task.holiday_name}
                </SoftTypography>
            </SoftBox>
        ),
        "Holiday Date": task.holidayDate,
        Description: (
            <SoftBox>
                {task.description.length > 50 ? (
                    <>
                        {task.description.slice(0, 50)}...
                        <SoftButton 
                            variant="text" 
                            color="info" 
                            size="small" 
                            onClick={() => {
                                setSelectedDescription(task.description);
                                setDescriptionModalOpen(true);
                            }}
                        >
                            Read More
                        </SoftButton>
                    </>
                ) : (
                    task.description
                )}
            </SoftBox>
        ),
        Action: (
            <div>
                {(permission==="1110" || permission==="1111" || isSuperAdmin) ? <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setErrors({});
                        return handleUpdate(task);
                    }}
                >
                    Edit
                </SoftTypography> : " "}
                {(permission==="1111" || isSuperAdmin) ? <SoftTypography
                    component="span"
                    variant="button"
                    color="error"
                    fontWeight="medium"
                    style={{ cursor: 'pointer' }}
                    sx={{ marginLeft: "10px" }}
                    onClick={() => {
                        return handleDelete(task);
                    }}
                >
                    Delete
                </SoftTypography> : " "}
            </div>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Holiday List</SoftTypography>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        size="small"
                                    />
                                </SoftBox>
                                {(permission==="1100" ||permission==="1110") || (permission==="1111" || isSuperAdmin) ? <Tooltip title="Add New Holiday" arrow>
                                    <SoftButton variant="gradient" color="info" size="small" onClick={handleNewHolidayClick}>
                                        + Add
                                    </SoftButton>
                                </Tooltip> : " " }
                            </SoftBox>
                        </SoftBox>

                        <SoftBox sx={{ overflow: 'auto' }}>
                            {loading ? (
                                <SoftBox display="flex" justifyContent="center" p={3}>
                                    <CircularProgress />
                                </SoftBox>
                            ) : (
                                <>
                                    <Table columns={columns} rows={rows} />
                                    <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        p={3}
                                    >
                                        <SoftBox display="flex" gap={1} alignItems="center">
                                            <SoftTypography variant="caption" color="secondary">
                                                {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, sortedTasks.length)} of {sortedTasks.length}
                                            </SoftTypography>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                                disabled={page === 0}
                                                iconOnly
                                            >
                                                <KeyboardArrowLeft />
                                            </SoftButton>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.min(Math.ceil(sortedTasks.length / itemsPerPage) - 1, prev + 1))}
                                                disabled={page >= Math.ceil(sortedTasks.length / itemsPerPage) - 1}
                                                iconOnly
                                            >
                                                <KeyboardArrowRight />
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </>
                            )}
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>Edit Holiday</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">Holiday Name</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="holiday_name"
                        value={editForm.holiday_name}
                        onChange={handleEditFormChange}
                        margin="normal"
                        error={Boolean(errors.holiday_name)}
                        helperText={errors.holiday_name}
                    />
                    <Typography variant="h6">Holiday Date</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="holidayDate"
                        type="date"
                        value={editForm.holidayDate}
                        onChange={handleEditFormChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.holidayDate)}
                        helperText={errors.holidayDate}
                    />
                    <Typography variant="h6">Description</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="description"
                        value={editForm.description}
                        onChange={handleEditFormChange}
                        multiline
                        rows={4}
                        error={Boolean(errors.description)}
                        helperText={errors.description}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog 
                open={descriptionModalOpen} 
                onClose={() => setDescriptionModalOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Full Description</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {selectedDescription}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setDescriptionModalOpen(false)} 
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

HolidayList.propTypes = {
    org: PropTypes.string.isRequired,
};

export default HolidayList;
