import React from 'react';
import logo from '../../assets/images/c4e.png';
import { Typography, Paper } from '@mui/material';

const TabTemplate = () => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
            Default Template
            </Typography>
            <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                <div style={styles.container}>
                    <div style={styles.header}>
                        <img src={logo} alt="Company Logo" style={styles.logo} />
                        <div style={styles.companyDetails}>
                            <p>Care4Edu Solutions Pvt Ltd</p>
                            <p>+91 7259785184</p>
                            <p>info@care4edu.com</p>
                            <p>www.care4edu.com</p>
                        </div>
                    </div>
                    <h1 style={styles.title}>Technical Advisory Board Agreement</h1>

                    <section>
                        <p style={styles.section}>This Technical Advisory Board Agreement (the Agreement) is entered into on <strong>20/08/2024</strong> (the Effective Date), by and between:</p>

                        <p style={styles.section}><strong>Care4Edu Solutions Pvt Ltd</strong>, a company registered under the laws of Karnataka, with its registered office located at Vidyaranyapura, Near Basva Temple, Bengaluru  a company registered under the laws of
                            Karnataka, with its registered office located at <strong>No. 228, Krishna Temple Road, Doddabommasandra, Vidyaranyapura Post, Bengaluru - 560097</strong> </p>

                        <p style={styles.section}>AND</p>

                        <p style={styles.section}><strong>Adhithyan P A</strong>, an individual residing at Vidyaranyapura (hereinafter referred to as the Technical Advisor). Collectively referred to as the Parties.</p>

                        <h2 style={styles.subTitle}>Background</h2>
                        <p>The Company is engaged in the business of Software Mobile and Web Application, and the Advisor possesses expertise and experience in the field of Software Technical Expertise.</p>

                        <h2 style={styles.subTitle}>Agreement</h2>
                        <h3 style={styles.sectionTitle}>Technical Advisory Board</h3>
                        <p style={styles.section}>1.1 The Company hereby appoints the Advisor as a member of its Technical Advisory Board (the Board) for an initial term of 2 years, commencing on <strong>20/08/2024</strong>.</p>
                        <p style={styles.section}>1.2 The Advisor agrees to serve on the Board and provide technical advice and guidance to the Company during the term of this Agreement.</p>

                        <h3 style={styles.sectionTitle}>Duties and Responsibilities</h3>
                        <p style={styles.section}>2.1 The Advisor shall provide technical advice and guidance to the Company on matters related to technical and organization, as requested by the Company.</p>
                        <p style={styles.section}>2.2 The Advisor agrees to attend 2 technical Board meetings per year, either in person or virtually, as determined by the Company.</p>
                        <p style={styles.section}>2.3 The Advisor shall make reasonable efforts to respond promptly to the Company s requests for advice and assistance.</p>

                        <h3 style={styles.sectionTitle}>Confidentiality</h3>
                        <p style={styles.section}>4.1 The Advisor agrees to keep confidential all information, whether written or oral, received from the Company.</p>
                        <p style={styles.section}>4.2 The confidentiality obligations under this Agreement shall survive the termination of this Agreement.</p>

                        <h3 style={styles.sectionTitle}>Termination</h3>
                        <p style={styles.section}>5.1 Either Party may terminate this Agreement with 30 days written notice to the other Party.</p>
                        <p style={styles.section}>5.2 Upon termination, the Advisor shall promptly return any Company property or information in their possession.</p>

                        <h3 style={styles.sectionTitle}>Governing Law</h3>
                        <p>This Agreement shall be governed by and construed in accordance with the laws of the Government of Karnataka.</p>

                        <h3 style={styles.sectionTitle}>Entire Agreement</h3>
                        <p>This Agreement constitutes the entire agreement between the Parties regarding the subject matter herein and supersedes all prior discussions, negotiations, and agreements.</p>
                    </section>

                    <div style={styles.footer}>
                        <div style={styles.signatureBlock}>
                            <p>By: <strong>Rishi</strong></p>
                            <p>Signature:</p>
                            <p>Title: Superadmin</p>
                            <p>Date: 20/08/2024</p>
                        </div>

                        <div style={styles.signatureBlock}>
                            <p>By: <strong>Adhithyan P A</strong></p>
                            <p>Signature:</p>
                            <p>Title: Board Member</p>
                            <p>Date: 20/08/2024</p>
                        </div>
                    </div>
                </div>
            </Paper >
        </>
    );
};

const styles = {
    container: {
        maxWidth: '700px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    header: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },
    title: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',

    },
    companyDetails: {
        fontSize: '14px',
        lineHeight: '1.6',
    },
    section: {
        marginTop: '10px',
    },

    subTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '20px',
    },
    sectionTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
        marginTop: '15px',
        marginBottom: '5px',

    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '40px',
    },
    signatureBlock: {
        textAlign: 'left',
        fontSize: '14px',
    }
};

export default TabTemplate;
