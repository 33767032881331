// import React, { useState, useEffect, useRef } from 'react';
// import { MessageCircle, Send, X, Copy, Check } from 'lucide-react';
// import axios from 'axios';
// import PropTypes from 'prop-types';
// import './Chat.css';

// const Chat = ({ isOpen, onClose }) => {
//   const [prompt, setPrompt] = useState('');
//   const [messages, setMessages] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [copiedMessageId, setCopiedMessageId] = useState(null);
//   const messageAreaRef = useRef(null);

//   const API_KEY = "AIzaSyBFmfc0bI_R9eTIVPJ_6tzrdUlF9IJ59Mo";
//   const API_URL = "https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent";

//   useEffect(() => {
//     const savedMessages = localStorage.getItem('chatMessages');
//     if (savedMessages) {
//       setMessages(JSON.parse(savedMessages));
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem('chatMessages', JSON.stringify(messages));
//   }, [messages]);

//   useEffect(() => {
//     if (messageAreaRef.current && isOpen) {
//       messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
//     }
//   }, [messages, isOpen]);

//   const handleSubmit = async () => {
//     if (!prompt.trim()) {
//       setError('Please enter a prompt');
//       return;
//     }

//     setIsLoading(true);
//     setError(null);

//     const userMessage = {
//       type: 'user',
//       content: prompt,
//       timestamp: new Date().toISOString(),
//       id: Date.now()
//     };
//     setMessages(prev => [...prev, userMessage]);

//     try {
//       const requestData = {
//         contents: [
//           {
//             parts: [{ text: prompt }],
//           },
//         ],
//       };

//       const response = await axios.post(
//         `${API_URL}?key=${API_KEY}`,
//         requestData,
//         {
//           headers: { 'Content-Type': 'application/json' },
//         }
//       );

//       if (response.data?.candidates?.[0]?.content?.parts?.[0]?.text) {
//         const aiMessage = {
//           type: 'ai',
//           content: response.data.candidates[0].content.parts[0].text,
//           timestamp: new Date().toISOString(),
//           id: Date.now()
//         };
//         setMessages(prev => [...prev, aiMessage]);
//       } else {
//         setError('No content generated. Please try a different prompt.');
//       }
//     } catch (err) {
//       console.error('Error:', err);
//       setError(err.response?.data?.error?.message || 'An error occurred while generating content.');
//     } finally {
//       setIsLoading(false);
//       setPrompt('');
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSubmit();
//     }
//   };

//   const handleClearChat = () => {
//     setMessages([]);
//     setCopiedMessageId(null);
//     localStorage.removeItem('chatMessages');
//   };

//   const formatTimestamp = (timestamp) => {
//     return new Date(timestamp).toLocaleTimeString([], {
//       hour: '2-digit',
//       minute: '2-digit',
//       second: '2-digit'
//     });
//   };

//   const handleCopyToClipboard = async (content, messageId) => {
//     try {
//       await navigator.clipboard.writeText(content);
//       setCopiedMessageId(messageId);
//       setTimeout(() => {
//         setCopiedMessageId(null);
//       }, 2000);
//     } catch (err) {
//       console.error('Failed to copy: ', err);
//     }
//   };

//   Chat.propTypes = {
//     isOpen: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <div className="modal-header">
//           <div className="modal-title">
//             <MessageCircle className="title-icon" />
//             <h1>Drona</h1>
//           </div>
//           <div className="modal-actions">
//             <button 
//               className="icon-button clear-button" 
//               onClick={handleClearChat}
//               title="Clear chat history"
//             >
//               Clear Chat
//             </button>
//             <button className="icon-button close-button" onClick={onClose}>
//               <X size={24} />
//             </button>
//           </div>
//         </div>
        
//         <div className="chat-container">
//           <div className="message-area" ref={messageAreaRef}>
//             {messages.map((message) => (
//               <div 
//                 key={message.id} 
//                 className={`message ${message.type === 'user' ? 'user-message' : 'ai-message'}`}
//               >
//                 <div className="message-content">
//                   {message.content.split('\n').map((line, i) => (
//                     <p key={i}>{line}</p>
//                   ))}
//                 </div>
//                 <span className="message-timestamp">
//                   {formatTimestamp(message.timestamp)}
//                 </span>
//                 {message.type === 'ai' && (
//                   <button 
//                     onClick={() => handleCopyToClipboard(message.content, message.id)} 
//                     className="copy-button"
//                   >
//                     {copiedMessageId === message.id ? (
//                       <>
//                         <Check size={16} /> Copied
//                       </>
//                     ) : (
//                       <>
//                         <Copy size={16} /> Copy
//                       </>
//                     )}
//                   </button>
//                 )}
//               </div>
//             ))}
//             {isLoading && (
//               <div className="message ai-message">
//                 <div className="loading-spinner" />
//               </div>
//             )}
//           </div>
          
//           {error && <div className="error-message">{error}</div>}
          
//           <div className="input-container">
//             <textarea
//               value={prompt}
//               onChange={(e) => setPrompt(e.target.value)}
//               onKeyPress={handleKeyPress}
//               placeholder="Type your message here..."
//               className="message-input"
//               rows="3"
//             />
//             <button
//               onClick={handleSubmit}
//               disabled={isLoading}
//               className="send-button"
//             >
//               {isLoading ? (
//                 <div className="loading-spinner" />
//               ) : (
//                 <Send size={20} />
//               )}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Chat;
// import React, { useState, useEffect, useRef } from 'react';
// import { MessageCircle, Send, X, Copy, Check } from 'lucide-react';
// import axios from 'axios';
// import PropTypes from 'prop-types';
// import './Chat.css';

// const Chat = ({ isOpen, onClose }) => {
//   const [prompt, setPrompt] = useState('');
//   const [messages, setMessages] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [copiedMessageId, setCopiedMessageId] = useState(null);
//   const messageAreaRef = useRef(null);

//   const API_KEY = "AIzaSyBFmfc0bI_R9eTIVPJ_6tzrdUlF9IJ59Mo";
//   const API_URL = "https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent";

//   // Function to process content and make text between ** bold
//   const processContent = (content) => {
//     const regex = /\*\*(.*?)\*\*/g; // Matches text between **
//     return content.split(regex).map((part, index) => 
//       index % 2 === 1 ? <span key={index} className="heading">{part}</span> : part
//     );
//   };

//   // Load saved messages from localStorage when the component is mounted
//   useEffect(() => {
//     const savedMessages = localStorage.getItem('chatMessages');
//     if (savedMessages) {
//       setMessages(JSON.parse(savedMessages));
//     }
//   }, []);

//   // Save messages to localStorage whenever they are updated
//   useEffect(() => {
//     if (messages.length > 0) {
//       localStorage.setItem('chatMessages', JSON.stringify(messages));
//     }
//   }, [messages]);

//   // Scroll to the latest message when modal is opened or messages change
//   useEffect(() => {
//     if (messageAreaRef.current && isOpen) {
//       messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
//     }
//   }, [messages, isOpen]);

//   const handleSubmit = async () => {
//     if (!prompt.trim()) {
//       setError('Please enter a prompt');
//       return;
//     }
  
//     setIsLoading(true);
//     setError(null);
  
//     const userMessage = {
//       type: 'user',
//       content: prompt,
//       timestamp: new Date().toISOString(),
//       id: Date.now()
//     };
//     setMessages(prev => [...prev, userMessage]);
  
//     try {
//       const requestData = {
//         contents: [
//           {
//             parts: [{ text: prompt }],
//           },
//         ],
//       };
  
//       const response = await axios.post(
//         `${API_URL}?key=${API_KEY}`,
//         requestData,
//         {
//           headers: { 'Content-Type': 'application/json' },
//         }
//       );
  
//       if (response.data?.candidates?.[0]?.content?.parts?.[0]?.text) {
//         let fullContent = response.data.candidates[0].content.parts[0].text;
  
//         // Limit the content to 6-7 lines
//         let truncatedContent = fullContent.split('\n').slice(0, 7).join('\n');
  
//         const aiMessage = {
//           type: 'ai',
//           content: truncatedContent, // Use truncated content here
//           timestamp: new Date().toISOString(),
//           id: Date.now()
//         };
//         setMessages(prev => [...prev, aiMessage]);
//       } else {
//         setError('No content generated. Please try a different prompt.');
//       }
//     } catch (err) {
//       console.error('Error:', err);
//       setError(err.response?.data?.error?.message || 'An error occurred while generating content.');
//     } finally {
//       setIsLoading(false);
//       setPrompt('');
//     }
//   };
  

//   // Handle Enter key press to submit the prompt
//   const handleKeyPress = (e) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSubmit();
//     }
//   };

//   // Clear the chat history
//   const handleClearChat = () => {
//     setMessages([]);
//     setCopiedMessageId(null);
//     localStorage.removeItem('chatMessages');
//   };

//   // Format timestamp for displaying
//   const formatTimestamp = (timestamp) => {
//     return new Date(timestamp).toLocaleTimeString([], {
//       hour: '2-digit',
//       minute: '2-digit',
//       second: '2-digit'
//     });
//   };

//   // Handle copying a message to clipboard
//   const handleCopyToClipboard = async (content, messageId) => {
//     try {
//       await navigator.clipboard.writeText(content);
//       setCopiedMessageId(messageId);
//       setTimeout(() => {
//         setCopiedMessageId(null);
//       }, 2000);
//     } catch (err) {
//       console.error('Failed to copy: ', err);
//     }
//   };

//   Chat.propTypes = {
//     isOpen: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <div className="modal-header">
//           <div className="modal-title">
//             <MessageCircle className="title-icon" />
//             <h1>Drona</h1>
//           </div>
//           <div className="modal-actions">
//             <button 
//               className="icon-button clear-button" 
//               onClick={handleClearChat}
//               title="Clear chat history"
//             >
//               Clear Chat
//             </button>
//             <button className="icon-button close-button" onClick={onClose}>
//               <X size={24} />
//             </button>
//           </div>
//         </div>
        
//         <div className="chat-container">
//           <div className="message-area" ref={messageAreaRef}>
//             {messages.map((message) => (
//               <div 
//                 key={message.id} 
//                 className={`message ${message.type === 'user' ? 'user-message' : 'ai-message'}`}
//               >
//                 <div className="message-content">
//                   {/* Process content to make text between ** bold */}
//                   {message.content.split('\n').map((line, i) => (
//                     <p key={i}>{processContent(line)}</p>
//                   ))}
//                 </div>
//                 <span className="message-timestamp">
//                   {formatTimestamp(message.timestamp)}
//                 </span>
//                 {message.type === 'ai' && (
//                   <button 
//                     onClick={() => handleCopyToClipboard(message.content, message.id)} 
//                     className="copy-button"
//                   >
//                     {copiedMessageId === message.id ? (
//                       <>
//                         <Check size={16} /> Copied
//                       </>
//                     ) : (
//                       <>
//                         <Copy size={16} /> Copy
//                       </>
//                     )}
//                   </button>
//                 )}
//               </div>
//             ))}
//             {isLoading && (
//               <div className="message ai-message">
//                 <div className="loading-spinner" />
//               </div>
//             )}
//           </div>
          
//           {error && <div className="error-message">{error}</div>}
          
//           <div className="input-container">
//             <textarea
//               value={prompt}
//               onChange={(e) => setPrompt(e.target.value)}
//               onKeyPress={handleKeyPress}
//               placeholder="Type your message here..."
//               className="message-input"
//               rows="3"
//             />
//             <button
//               onClick={handleSubmit}
//               disabled={isLoading}
//               className="send-button"
//             >
//               {isLoading ? (
//                 <div className="loading-spinner" />
//               ) : (
//                 <Send size={20} />
//               )}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Chat;

import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle, Send, X, Copy, Check } from 'lucide-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import './Chat.css';

const Chat = ({ isOpen, onClose }) => {
  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const messageAreaRef = useRef(null);

  const API_KEY = 'AIzaSyBFmfc0bI_R9eTIVPJ_6tzrdUlF9IJ59Mo';
  const API_URL =
    'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';

  const processContent = (content) => {
    const regex = /\*\*(.*?)\*\*/g;
    return content.split(regex).map((part, index) =>
      index % 2 === 1 ? (
        <span key={index} className="heading">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem('chatMessages', JSON.stringify(messages));
    }
  }, [messages]);

  useEffect(() => {
    if (messageAreaRef.current && isOpen) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  }, [messages, isOpen]);

  const handleSubmit = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }

    setIsLoading(true);
    setError(null);

    const userMessage = {
      type: 'user',
      content: prompt,
      timestamp: new Date().toISOString(),
      id: Date.now(),
    };
    setMessages((prev) => [...prev, userMessage]);

    try {
      const requestData = {
        contents: [
          {
            parts: [{ text: prompt }],
          },
        ],
      };

      const response = await axios.post(`${API_URL}?key=${API_KEY}`, requestData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data?.candidates?.[0]?.content?.parts?.[0]?.text) {
        const aiMessage = {
          type: 'ai',
          content: response.data.candidates[0].content.parts[0].text,
          timestamp: new Date().toISOString(),
          id: Date.now(),
        };
        setMessages((prev) => [...prev, aiMessage]);
      } else {
        setError('No content generated. Please try a different prompt.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.response?.data?.error?.message || 'An error occurred while generating content.');
    } finally {
      setIsLoading(false);
      setPrompt('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleClearChat = () => {
    setMessages([]);
    setCopiedMessageId(null);
    localStorage.removeItem('chatMessages');
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const handleCopyToClipboard = async (content, messageId) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedMessageId(messageId);
      setTimeout(() => {
        setCopiedMessageId(null);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  Chat.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <div className="modal-header">
  <div className="modal-title">
    <MessageCircle className="title-icon" />
    <h1>Drona</h1>
  </div>
  <div className="modal-actions">
    <button
      className="icon-button clear-button"
      onClick={handleClearChat}
      title="Clear chat history"
    >
      Clear Chat
    </button>
    <button className="icon-button close-button" onClick={onClose}>
      <X size={24} />
    </button>
  </div>
</div>


        <div className="chat-container">
          <div className="message-area" ref={messageAreaRef}>
            {messages.map((message) => (
              <div
                key={message.id}
                className={`message ${message.type === 'user' ? 'user-message' : 'ai-message'}`}
              >
                <div className="message-content">
                  {message.content.split('\n').map((line, i) => (
                    <p key={i}>{processContent(line)}</p>
                  ))}
                </div>
                <span className="message-timestamp">{formatTimestamp(message.timestamp)}</span>
                {message.type === 'ai' && (
                  <button
                    onClick={() => handleCopyToClipboard(message.content, message.id)}
                    className="copy-button"
                  >
                    {copiedMessageId === message.id ? (
                      <>
                        <Check size={16} /> Copied
                      </>
                    ) : (
                      <>
                        <Copy size={16} /> Copy
                      </>
                    )}
                  </button>
                )}
              </div>
            ))}
            {isLoading && (
              <div className="message ai-message">
                <div className="loading-spinner" />
              </div>
            )}
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="input-container">
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your message here..."
              className="message-input"
              rows="3"
            />
            <button onClick={handleSubmit} disabled={isLoading} className="send-button">
              {isLoading ? <div className="loading-spinner" /> : <Send size={20} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

